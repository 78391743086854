import { AuthContainer } from '@rabbit/elements/shared-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { useState } from 'react';
import PWDREmailSent from '../components/organisms/password-reset-flow/PWDREmailSent';
import PWDRNewPassword from '../components/organisms/password-reset-flow/PWDRNewPassword';
import i18next from 'i18next';

interface PasswordResetViewProps {
  step?: number;
}

function PasswordResetView({ step }: PasswordResetViewProps) {
  //{ email }: { email: string }
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(
    step ? { step: step, data: null } : { step: 1, data: location?.state?.data }
  );

  const onChangeStep = (step_in: number, data: any = null) => {
    setCurrentStep({
      ...currentStep,
      step: step_in,
      data: location?.state?.data,
    });
  };

  const onGoBack = () => {
    if (currentStep.step !== 1) {
      onChangeStep(currentStep.step - 1, currentStep.data);
    } else {
      navigate(SAGE_ROUTE_NAME.FORGOT_PASSWORD);
    }
  };

  const getTitle = (step: number) => {
    switch (step) {
      case 1:
        return i18next.t('message.checkYourEmail');
      case 2:
        return i18next.t('message.newPassword');
    }
  };

  // if (isLoading) return <LoadingSpinner extraClasses={'mt-2'} size={'lg'} />;

  // const linkHasExpired = true;

  type SwitchProps = {
    currentStep: number;
    data: any; //todo
    onChangeStep: any; //todo
  };
  const Switch: React.FC<SwitchProps> = ({
    currentStep,
    data,
    onChangeStep,
  }) => {
    switch (currentStep) {
      case 1:
        return <PWDREmailSent onChangeStep={onChangeStep} data={data} />;
      case 2:
        return <PWDRNewPassword onChangeStep={onChangeStep} data={data} />;
      default:
        return null;
    }
  };

  //TODO: component to be rendered when link is expired
  // const renderEmailExpired = () => {
  //   return (
  //     <AuthContainer title="This link to reset your password has expired">
  //       <img
  //         src={emailExpired}
  //         alt={'emailExpired'}
  //         className="mx-auto py-5"
  //       ></img>
  //       <p className="font-nunito pt-9 text-base font-semibold">
  //         To continue, request a new link using the button below.
  //       </p>
  //       <div className="py-5">
  //         <Button kind="primary" type="submit" className="w-full">
  //           Request new link
  //         </Button>
  //       </div>
  //       <p className="font-nunito pb-7 text-base font-semibold">
  //         Alternatively, login into your iWarranty account.
  //       </p>
  //       <NavLink
  //         to={ROUTE_NAME.LOGIN}
  //         className="text-primary-600"
  //         reloadDocument
  //       >
  //         Click here login
  //       </NavLink>
  //     </AuthContainer>
  //   );
  // };

  return (
    <div className="font-nunito flex flex-grow flex-col justify-around">
      <AuthContainer
        title={getTitle(currentStep.step)}
        goBack={() => onGoBack()}
      >
        <Switch
          currentStep={currentStep.step}
          data={currentStep.data}
          onChangeStep={onChangeStep}
        />
      </AuthContainer>

      {/* {renderEmailExpired()} */}
    </div>
  );
}

export default PasswordResetView;
