import { VehicleInfo, Warranty, WarrantyStatus } from '@rabbit/data/types';
import { formatDuration } from 'date-fns';
import { parse } from 'tinyduration';

export interface BLIWarranty {
  getLatestWarranty: (
    warranties: Warranty[] | null | undefined
  ) => Warranty | undefined;
  getVehicleRegistrationNo: (productInfo: any) => string;
  getWarrantyTerm: (warranty: Warranty | undefined) => string;
}

export const BL_Warranty: BLIWarranty = {
  getLatestWarranty,
  getVehicleRegistrationNo,
  getWarrantyTerm,
};

function getLatestWarranty(
  warranties: Warranty[] | null | undefined
): Warranty | undefined {
  if (!warranties || warranties.length === 0) return undefined;

  const activeWarranties = warranties.filter(
    (warranty) => warranty.status === WarrantyStatus.ACTIVE
  );

  if (activeWarranties.length === 0) {
    const sortedWarranties = warranties.sort(
      (a, b) => (b.startDate ?? 0) - (a.startDate ?? 0)
    );
    return sortedWarranties[0];
  } else if (activeWarranties.length > 1) {
    const sortedWarranties = activeWarranties.sort(
      (a, b) => (b.startDate ?? 0) - (a.startDate ?? 0)
    );
    return sortedWarranties[0];
  }

  return activeWarranties[0];
}

function getVehicleRegistrationNo(productInfo: any): string {
  // strictly for vehicles
  if (productInfo.cherishedRegistrationNo?.trim()) {
    return productInfo.cherishedRegistrationNo.toUpperCase();
  }
  return productInfo.registrationNo.toUpperCase() || '-';
}

function getWarrantyTerm(warranty: Warranty | undefined): string {
  // Get the latest warranty from the list of warranties
  let value = '-';

  if (warranty) {
    // Check if the duration is specified as an object with a label
    if (
      typeof warranty.decider?.stipulated?.['duration'] === 'object' &&
      warranty.decider.stipulated['duration']?.label
    ) {
      // If so, set the value to the label
      value = warranty.decider.stipulated['duration'].label;
    } else if (
      warranty.appliedOptions?.find((option: any) => option.key === 'duration')
    ) {
      // If the duration is not specified as an object, check if it is specified as an applied option
      const durationOption = warranty.appliedOptions.find(
        (option: any) => option.key === 'duration'
      );
      // If the duration option has a value, format it using the `formatDuration` function and set the value
      value = durationOption?.value
        ? formatDuration(parse(durationOption.value))
        : '-';
    } else if (
      warranty.duration &&
      warranty.duration.amount &&
      warranty.duration.division
    ) {
      // If the duration is not specified as an object or an applied option, check if it is specified as an amount and division
      value = warranty.duration.amount + ' ' + warranty.duration.division;
    }
  }
  return value;
}

