import { useContext } from 'react';
import {
  formatUnixTime,
  LabelledItemShape,
  parseId,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../../../context/CaseflowContext';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';
import { useTranslation } from 'react-i18next';
import { stripTags } from '@rabbit/sage/utils/helpers';
import { AppContext } from '@rabbit/app-context';

export interface ClaimDetailsSectionType2Props {
  renderFns: {
    renderCaseFiles: () => JSX.Element;
    renderShopifyOrderLink: () => JSX.Element;
    renderSerialProof: () => JSX.Element;
    renderConsumerIssueDesc: () => JSX.Element;
    renderClaimDetailItemsLine: (
      claimDetailItems: LabelledItemShape[],
      keys: string[],
      extraClasses?: string
    ) => JSX.Element;
  };
  faultLabels: string[];
}

export function ClaimDetailsSectionType2(props: ClaimDetailsSectionType2Props) {
  const { faultLabels } = props;
  const {
    renderCaseFiles,
    renderShopifyOrderLink,
    renderSerialProof,
    renderClaimDetailItemsLine,
  } = props.renderFns;
  const { caseFacts, holdingData, holdingProxyData, caseId, caseTimeCreated } =
    useContext(CaseflowContext) || {};

  const { config } = useContext(AppContext);

  const { t } = useTranslation();
  // const { config } = useContext(ConfigContext);
  const latestWarranty = BL_Warranty.getLatestWarranty(
    holdingProxyData?.warranties
  );

  const isWithinWarranty = () => {
    if (!holdingProxyData) return '-';

    if (!latestWarranty || !latestWarranty.endDate) return '-';

    return OurDateTime.nowUTCTimestamp() < latestWarranty?.endDate
      ? 'Yes'
      : 'No';
  };

  /* --------------------------- Claim detail items --------------------------- */

  const claimDetailItems: LabelledItemShape[] = [
    {
      key: 'warranty_status',
      label: t('general.warrantyStatus'),
      value: caseFacts?.warranty_status ?? '-',
    },
    {
      key: 'claim_id',
      label: t('general.claimId'),
      value: caseId
        ? `${
            caseFacts?.warranty_status
              ? caseFacts?.warranty_status.toLowerCase() === 'in warranty'
                ? 'WR-'
                : 'OR-'
              : ''
          }${parseId(caseId)}`
        : '-',
    },
    {
      key: 'date_of_claim',
      label: t('general.dateOfClaim'),
      value: caseTimeCreated
        ? formatUnixTime(Number(caseTimeCreated), 'dd/MM/yyyy')
        : '-',
    },
    {
      key: 'purchase_date',
      label: t('general.purchaseDate'),
      value:
        holdingData?.purchase_time && holdingData?.purchase_time > 0
          ? formatUnixTime(holdingData?.purchase_time, 'dd/MM/yyyy')
          : '-',
    },
    {
      key: 'warranty_term',
      label: t('general.warrantyTerm'),
      value: holdingData?.warranty_term?.amount
        ? `${holdingData?.warranty_term?.amount} ${holdingData?.warranty_term?.division}`
        : '-',
    },
    {
      key: 'warranty_expiry',
      label: t('general.warrantyExpiry'),
      value:
        latestWarranty?.endDate && latestWarranty?.endDate > 0
          ? formatUnixTime(latestWarranty?.endDate, 'dd/MM/yyyy')
          : '-',
    },
    {
      key: 'within_warranty_period',
      label: t('general.withinWarrantyPeriod'),
      value: isWithinWarranty(),
    },
    {
      key: 'customer_issue_type',
      label: t('general.customerIssueType'),
      value: caseFacts?.consumer_issue_type ?? '-',
    },
    {
      key: 'customer_issue_description',
      label: t('general.customerIssueDescription'),
      value: stripTags(caseFacts?.consumer_issue_description ?? '') ?? '-',
    },
    {
      key: 'proof_of_purchase',
      label: t('general.proofOfPurchaseSupportingMaterials'),
      value: renderCaseFiles(),
    },
    ...(holdingProxyData?.shopifyLinks?.orderId &&
    holdingProxyData?.shopifyLinks?.shopId
      ? [
          {
            key: 'shopify_order',
            label: t('general.orderDetails'),
            value: renderShopifyOrderLink(),
          },
        ]
      : []),
    ...(config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.MILEAGE
      ? [
          {
            key: 'mileage',
            label: t('general.mileage'),
            value: caseFacts?.mileage
              ? `${Number(caseFacts.mileage.value).toLocaleString('en')} ${
                  caseFacts.mileage.unit
                }`
              : `-`,
          },
        ]
      : []),
    ...(config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.SERIAL_NUMBER
      ? [
          {
            key: 'serial_number',
            label: t('general.serialNumber'),
            value: caseFacts?.consumer_holding_serial_number
              ? caseFacts.consumer_holding_serial_number
              : caseFacts?.serial_number
              ? caseFacts.serial_number
              : '-',
          },
        ]
      : []),
    ...(config.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF
      ? [
          {
            key: 'serial_number_proof',
            label: t('general.serialNumberProof'),
            value: renderSerialProof(),
          },
        ]
      : []),
  ];

  /* -------------------------------- Main TSX -------------------------------- */
  return (
    <div>
      {renderClaimDetailItemsLine(claimDetailItems, [
        'claim_id',
        'warranty_status',
        'date_of_claim',
        'purchase_date',
        'warranty_term',
      ])}
      {renderClaimDetailItemsLine(claimDetailItems, [
        'warranty_expiry',
        'customer_issue_type',
        'customer_issue_description',
        'within_warranty_period',
        'proof_of_purchase',
      ])}
      {renderClaimDetailItemsLine(
        claimDetailItems,
        ['shopify_order', 'serial_number', 'serial_number_proof', 'mileage'],
        'mb-0'
      )}
    </div>
  );
}

export default ClaimDetailsSectionType2;
