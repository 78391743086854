import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, InfoText, Input } from '@rabbit/elements/shared-components';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '@rabbit/sage/context/UserContext';
import { useGetConsumerHoldings } from '@rabbit/bizproc/react';
import {
  DTHoldingProxy,
  FBD_Repairer_Public,
  FBD_Vendable,
} from '@rabbit/data/types';
import { fixCategoryAndSortHoldingsByTCreate } from '@rabbit/sage/utils/helpers';
import { NewClaimModalSteps } from '../../ModalNewClaim/ModalNewClaim';
import { AppContext } from '@rabbit/app-context';
import { CORPORATE, CUSTOMER } from '@rabbit/sage/utils/consts.ts';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { useTranslation } from 'react-i18next';

export const validationSchema = Yup.object().shape({
  consumer_email: Yup.string().trim().required('Select user or add a new one'),
  type: Yup.string(),
});

export type CreateNewSelectUserKinds = 'new-claim' | 'new-registration';
export interface CreateNewSelectUserProps {
  handleClose: () => void;
  onChange: (step: NewClaimModalSteps, data: any) => void;
  data: any;
  setLoading: (loading: boolean) => void;
  kind: CreateNewSelectUserKinds;
}

interface ConsumerDataShape {
  consumer_email: string;
  type: string;
}

export function CreateNewSelectUser(props: CreateNewSelectUserProps) {
  const { onChange, handleClose, setLoading, kind, data } = props;
  const { config } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userContext = useContext(UserContext);
  const formikRef = useRef(null) as any;
  const { consumerPersona } = useGetMySagePersonas();
  const { t, i18n } = useTranslation();
  const [consumer, setConsumer] = useState<any>(
    data?.previewData?.consumer || consumerPersona?.personaId
      ? {
          id: consumerPersona?.consumer_private?.docid,
          label: consumerPersona?.consumer_private?.email,
          value: consumerPersona?.consumer_private?.email,
          item: consumerPersona?.consumer_private,
        }
      : {}
  );
  const [productResponse, setProductResponse] = useState(null) as any;
  const [productLoaded, setProductLoaded] = useState(false);
  const { getAllHoldingProxies } = useGetConsumerHoldings(consumer?.id || '');
  const defaultUserType =
    t('CFG_DEFAULT_USER_TYPE') !== 'CFG_DEFAULT_USER_TYPE'
      ? t('CFG_DEFAULT_USER_TYPE')
      : CUSTOMER;
  const initialValues: ConsumerDataShape = {
    consumer_email: data?.previewData?.consumer?.value || '',
    type: defaultUserType,
  } as any;

  useEffect(() => {
    (async () => {
      if (consumerPersona?.personaId) {
        const consumerData = {
          consumer_email: consumerPersona.consumer_private?.email,
          type: consumerPersona.consumer_private?.consumer_type,
        };
        setIsSubmitting(true);
        setLoading(true);

        let resultHoldings: any[] = [];
        const holdings: DTHoldingProxy[] | Error = await getAllHoldingProxies();
        if (holdings instanceof Error) resultHoldings = [];
        else {
          const finalHoldings = fixCategoryAndSortHoldingsByTCreate(holdings);
          resultHoldings = finalHoldings.filter(
            (i) => i.active_cases?.length === 0
          );
        }
        setLoading(false);
        if (resultHoldings.length > 0) {
          onChange('choose-product', {
            ...consumerData,
            consumer,
            holdings: resultHoldings,
            consumer_persona_id: consumer.id,
            previewData: { ...data?.previewData, consumer },
            productResponse: productResponse,
          });
        } else {
          onChange('add-product', {
            ...consumerData,
            holdings: resultHoldings,
            consumer,
            previewData: { ...data?.previewData, consumer },
            productResponse: productResponse,
          });
        }
      }
    })();
  }, [consumerPersona, consumer, setConsumer]);

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    setLoading(true);
    let resultHoldings: any[] = [];
    const holdings: DTHoldingProxy[] | Error = await getAllHoldingProxies();
    if (holdings instanceof Error) resultHoldings = [];
    else {
      const finalHoldings = fixCategoryAndSortHoldingsByTCreate(holdings);
      resultHoldings = finalHoldings.filter(
        (i) => i.active_cases?.length === 0
      );
    }
    setLoading(false);
    if (resultHoldings.length > 0) {
      onChange('choose-product', {
        ...values,
        consumer,
        holdings: resultHoldings,
        consumer_persona_id: consumer.id,
        previewData: { ...data?.previewData, consumer },
        productResponse: productResponse,
      });
    } else {
      onChange('add-product', {
        ...values,
        holdings: resultHoldings,
        consumer,
        previewData: { ...data?.previewData, consumer },
        productResponse: productResponse,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const sku = window.localStorage.getItem('LRP_Vendablempn');
      if (sku && !productLoaded) {
        const vendableData = await FBD_Vendable.query()
          .where('mpn', '==', sku)
          .getContents();
        setProductLoaded(true);
        if (vendableData && vendableData[0]) {
          setProductResponse({
            id: vendableData[0].docid,
            label: vendableData[0].title,
            value: vendableData[0].docid,
          });
        }
      } else {
        setProductLoaded(true);
      }
    })();
  }, [productLoaded, setProductResponse]);

  function displayAddNewUser() {
    if (
      (kind === 'new-claim' &&
        config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.NEW_USER_BTN) ||
      (kind === 'new-registration' &&
        config.HOLDINGS.NEW_REGISTRATION_FLOW.NEW_USER_BTN)
    ) {
      return (
        <Button
          kind="primary"
          className="w-full"
          onClick={() =>
            onChange('new-consumer', { type: formikRef.current?.values.type })
          }
        >
          {formikRef.current?.values.type === CORPORATE
            ? t('general.addNewBusiness')
            : t('general.addNewCustomer')}
        </Button>
      );
    }
    return null;
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({ values, errors }) => (
        <Form className="flex-col gap-3 p-5">
          <InfoText>
            {' '}
            {kind === 'new-claim'
              ? t('COPY_CREATE_NEW_SELECT_USER_CLAIMS')
              : t('COPY_CREATE_NEW_SELECT_USER_REGISTRATIONS')}{' '}
          </InfoText>
          {config.CUSTOMER.CORPORATE_ACCOUNT && (
            <div className="flex-col-2 mb-4 flex gap-3">
              <Input
                type="select"
                label="User type"
                name="type"
                settings={{
                  options: [
                    {
                      id: '',
                      label: t('general.individual'),
                      value: 'customer',
                    },
                    {
                      id: '',
                      label: t('general.business'),
                      value: CORPORATE,
                    },
                  ],
                  placeholder: 'Please select an option',
                }}
              />
            </div>
          )}
          <div className="mb-4 flex">
            <Input
              type="autocomplete-consumer"
              name="consumer_email"
              label={`${t('general.email')}*`}
              onChange={(item) => setConsumer(item)}
              settings={{
                id: 'consumer_email',
                placeholder: t('general.enterAnEmailToStartSearching'),
                hint: '*required',
                token: userContext?.user?.accessToken,
                customButton: displayAddNewUser(),
                options: data?.previewData?.consumer
                  ? [data?.previewData?.consumer]
                  : undefined,
                value: consumer?.value || '',
                type: values.type === CUSTOMER ? '' : values.type,
              }}
            />
          </div>
          <div className="mt-5 flex w-full gap-4">
            <Button
              kind="primary"
              type="submit"
              loading={isSubmitting}
              disabled={errors && errors.consumer_email ? true : false}
            >
              {t('general.continue')}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              {t('general.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateNewSelectUser;
