/* -------------------------------------------------------------------------- */
/*                                    Enums                                   */
/* -------------------------------------------------------------------------- */
export enum Config_ClaimsHoldingForms {
  BASE = 'BASE',
  TYPE1 = 'TYPE1',
}

export enum Config_ClaimsTableType {
  SIMPLE = 'SIMPLE',
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
}

export enum Config_ClaimDetailViewType {
  DEFAULT = 'DEFAULT',
  CAR_DETAILS = 'CAR_DETAILS',
}

export enum Config_ClaimDetailsSectionType {
  DEFAULT = 'DEFAULT',
  TYPE1 = 'TYPE1',
  TYPE2 = 'TYPE2',
}

export enum Config_UpdateClaimDetails_CustomerFormType {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
}

export enum Config_UpdateClaimDetails_ClaimDetailsFormType {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  TYPE1 = 'TYPE1',
}

export enum Config_UpdateClaimDetails_AttachmentsFormType {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  TYPE1 = 'TYPE1',
}

export enum Config_ClaimActionsType {
  TYPE1 = 'TYPE1',
  TYPE2 = 'TYPE2',
}

export enum Config_RegistrationDetailsView_WarrantyDetailsSectionType {
  DEFAULT = 'DEFAULT',
  CAR_WARRANTY = 'CAR_WARRANTY',
  STONE_WARRANTY = 'STONE_WARRANTY',
}

export enum Config_ClaimCosts_LogType {
  DEFAULT = 'DEFAULT',
  TYPE1 = 'TYPE1',
}

export const enum Config_RegistrationTableType {
  DEFAULT = 'DEFAULT',
  BIKE_TYPE = 'BIKE_TYPE',
}

export const enum Config_CountryTaxLabel {
  GST = 'GST',
  VAT = 'VAT',
}

export enum Config_PartnerTypeOptions {
  DEFAULT = 'DEFAULT',
  REPAIRER = 'REPAIRER',
  STONE = 'STONE',
  INSTALLER = 'INSTALLER',
  ALL = 'ALL',
  RETAILER = 'RETAILER',
}

export const SAGE_ROUTE_NAME = {
  DEFAULT: '/',
  CLAIMS: '/claims',
  DASHBOARD: '/dashboard',
  FORGOT_PASSWORD: '/forgot-password',
  FAULTS: '/faults',
  LOGIN: '/login',
  MANAGE_ACCOUNT: '/manage-account',
  MY_ORGANIZATION: '/my-organization',
  MY_CLAIMS: '/my-claims',
  PLATFORM_SERVICES_AGREEMENT: '/platform-services-agreement',
  PRIVACY_POLICY: '/privacy-policy',
  PRODUCTS: '/products',
  MANAGE: '/manage',
  MANAGE_PRODUCTS: '/manage?tab=products',
  MANAGE_CUSTOMERS: '/manage?tab=customers',
  MANAGE_FAULTS: '/manage?tab=faults',
  PRODUCTS_PARTS: '/products/parts',
  REGISTRATIONS: '/registrations',
  WARRANTY_OVERVIEW: '/warranty-overview',
  SIGNUP: '/signup',
  TERMS_AND_CONDITIONS: '/terms-and-conditions', //todo
  USER_GROUP: '/manage-account/user-group',
  // todo merge these two routes into one
  CONFIRM_EMAIL: '/confirm-email',
  RESET_PASSWORD: '/reset-password',
  CONFIGURATION: '/configuration',
  REGISTER_PRODUCT_INSTALLER: '/register-product',
  PLANS_AND_PRICES: '/plans-and-prices',
  PRICING: '/pricing',
};
/* -------------------------------------------------------------------------- */
/*                                    Types                                   */
/* -------------------------------------------------------------------------- */
export type RestrictedUserPathsType = {
  Warrantor: string[];
  ExternalRepairer: string[];
};

export enum REGISTRATION_FIELDS {
  CHASSIS_NUMBER = 'chassis_number',
  REGISTRATION_NUMBER = 'registration_number',
  PRODUCTION_DATE = 'production_date',
  MILEAGE = 'mileage',
}
