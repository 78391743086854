import { Form, Formik } from 'formik';
import {
  Button,
  InfoText,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { getConsumerURL } from 'apps/sage/src/utils/helpers';
import { updateHoldingOnClose } from 'apps/sage/src/utils/firestore-helpers';
import { toast } from 'react-toastify';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  FBD_Consumer_Private,
} from '@rabbit/data/types';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { CORPORATE } from '@rabbit/sage/utils/consts.ts';
import { AppContext } from '@rabbit/app-context';

export interface CompleteRepairModalProps {
  completeRepairLocal?: boolean;
}
interface FormValuesShape {
  comment_to_customer: string;
  internal_comment: string;
  administrative_time: string;
}

const initialValues = {
  comment_to_customer: '',
  internal_comment: '',
  administrative_time: '',
};

const validationSchema = Yup.object().shape({
  comment_to_customer: Yup.string()
    .trim()
    .required('Please enter a message')
    .test('remove-html-tags', 'Please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    }),
  administrative_time: Yup.string().trim(),
  internal_comment: Yup.string().trim(),
});

export function CompleteRepairAndCloseModal({
  completeRepairLocal,
}: CompleteRepairModalProps) {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;
  const consumer_persona_id = caseFacts?.consumer_persona_id;
  const onSubmit = async (values: FormValuesShape) => {
    const { comment_to_customer, administrative_time, internal_comment } =
      values;
    setIsSubmitting(true);
    const consumerData = await FBD_Consumer_Private.get(
      consumer_persona_id ?? ''
    );
    await moveSpotlight(PersonaTypeSingleLetter.Consumer);
    executeAction('complete_local_repair_and_close_case');

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        comment_to_customer: comment_to_customer,
        administrative_time: administrative_time,
      };

      const to = caseFacts?.consumer_email ?? '',
        from = tenantInfo?.emailInfo?.emailSender ?? '',
        template = MANDRILL_TEMPLATES.BODY_REPAIR_COMPLETE_V2,
        business_name = tenantInfo?.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        subject = t('email.subject.claimCompleted', {
          business_name,
          product_name,
        }),
        link_to_claim =
          consumerData?.consumer_type === CORPORATE
            ? `${window.location.origin}/claims/${caseId}`
            : `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'generic_close_case',
          to,
          from,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            comment_to_customer,
            product_name,
            link_to_claim,
          },
          shallBeSentViaBackend: true,
        });
      }
      await updateHoldingOnClose(caseId, caseFacts?.consumer_holding ?? '');
      setShowModal(false);
      toast.success('Claim updated successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: t('Complete repair and close case'),
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="font-nunito mt-5 flex flex-col px-4">
            <InfoText containerStyles="mb-2 flex gap-1 text-gray-500">
              {t(
                'Please confirm that all the time spent and all the parts used have been logged for this repair before closing it.'
              )}
            </InfoText>
            <div className="font-nunito">
              <Input
                type="time"
                name="administrative_time"
                label={t('Administrative time used')}
                settings={{
                  id: 'administrative_time',
                  placeholder: '',
                }}
              />
            </div>
            <div className="mt-3">
              <Input
                type="rich-text"
                label={t('Comments to customer' + '*')}
                name="comment_to_customer"
                settings={{
                  id: 'comment_to_customer',
                  hint: '*required',
                }}
              />
            </div>
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  {t('Add internal comment')}
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div className="mt-3">
                <Input
                  type="rich-text"
                  label={t('Internal comment')}
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                {t('Complete repair and close case')}
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                {t('Cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default CompleteRepairAndCloseModal;
