import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import {
  Button,
  Heading,
  Input,
  LoadingSpinner,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import {
  PrincipalsFieldName,
  PersonaTypeSingleLetter,
  DTWarranty_Template,
  DeciderOutput,
  DTWarranty_Offer,
  DeciderApprovedOptionInfo,
  SingleApprovedOptionInfo,
} from '@rabbit/data/types';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { generateSelectStyles } from '@rabbit/elements/shared-components';
import {
  getPricingForSelectedOptions,
  optionDescription,
} from '@rabbit/sage/utils/helpers';
import { Formik, Form } from 'formik';
import { useSageAPI } from '@rabbit/bizproc/react';
import { format, formatDuration } from 'date-fns';
import { parse } from 'tinyduration';
import { DeciderPerformDecision } from '@rabbit/bizproc/core';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { RegistrationsWarrantyOptionShape } from '@rabbit/elements/shared-types';
import { AppContext } from '@rabbit/app-context';

export interface CreateNewStoneCoverageOptionsProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: {
    surfaceArea: number;
    typeOfStone: string;
    sealing: boolean;
    availableOptions?: any;
  };
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export interface CoverageOptions {
  warranty_type: string;
  warranty_duration: string;
  warranty_template: DTWarranty_Template;
  warranty_price: string;
  warranty_start_date?: string;
  warranty_offer?: DTWarranty_Offer;
  decider: DeciderOutput;
}

const validationSchema = Yup.object().shape({
  warranty_start_date: Yup.string().nullable(),
});

export function CreateNewStoneCoverageOptions({
  handleClose,
  onChange,
  data,
  setIsLoading,
}: CreateNewStoneCoverageOptionsProps) {

  const { getApprovedWarrantyOffers, isReady } = useSageAPI();
  const [selectedOption, setSelectedOption] = useState<CoverageOptions>();
  const [activeOption, setActiveOption] = useState<string>();
  const [offers, setOffers] = useState<RegistrationsWarrantyOptionShape[]>();
  const [loading, setLoading] = useState(false);
  const [deciderOutput, setDeciderOutput] = useState<DeciderOutput>();
  const { warrantyDealerPersona, warrantorPersona } = useGetMySagePersonas();
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);

  const activeTenantId =
    warrantyDealerPersona?.warrantyDealer_private?.tenantLink ||
    warrantorPersona?.warrantor_private?.tenantLink;

  const handleOptionSelected = (
    template: DTWarranty_Template,
    duration: string,
    price: string,
    offer: DTWarranty_Offer
  ) => {
    console.log(offer.title);
    const stipulated = {
      duration: {
        label: formatDuration(parse(duration)),
        value: duration,
      },
      surfaceAreaValue: data.surfaceArea || 0,
      surfaceAreaClass: offer.title.match('Standard')
        ? { label: 'Standard', value: 'C' }
        : data.surfaceArea >= 15
        ? { label: '>= 15m2', value: 'B' }
        : { label: '< 15m2', value: 'A' },
      upsellSealing: data.sealing,
    };
    try {
      const decider = DeciderPerformDecision(stipulated, template, offer);
      setDeciderOutput(decider as any);
      setSelectedOption({
        warranty_duration: duration || '',
        warranty_type: template.docid,
        warranty_template: template,
        warranty_price: decider.decided.totalPrice as string,
        warranty_offer: offer,
        decider: decider,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isReady) {
      setLoading(true);
      void (async () => {
        if (!activeTenantId) return;
        try {
          const filteredOffers: RegistrationsWarrantyOptionShape[] = [];
          const params = {
            surfaceAreaClass:
              data.surfaceArea >= 15
                ? { label: '>= 15m2', value: 'B' }
                : { label: '< 15m2', value: 'A' },
            surfaceAreaValue: Number(data.surfaceArea),
            upsellSealing: data.sealing,
          };

          const { offers } =
            data.surfaceArea === 1
              ? { offers: [] as RegistrationsWarrantyOptionShape[] }
              : await getApprovedWarrantyOffers({
                  params,
                  dimensions: ['duration', 'surfaceAreaClass'],
                  partnerTenantLink: activeTenantId,
                  warrantorLink: getRootPersonaFromLexicon(
                    t(PrincipalsFieldName),
                    PersonaTypeSingleLetter.Warrantor
                  ),
                });

          setLoading(false);

          if (offers) {
            offers.forEach((offer) => {
              // find the duration option, remove all others
              offer.approvedOptions = offer.approvedOptions.filter(
                (option) => option.label === 'Duration'
              );

              const approvedOptions = [] as any;
              offer.approvedOptions[0].optionInfo.forEach((option) => {
                // perform a check with the params to see if it's approved
                // if not, remove it from the array
                const decision = DeciderPerformDecision(
                  {
                    ...params,
                    duration: {
                      label: option.option.label,
                      value: option.option.value,
                    },
                  },
                  offer.template,
                  offer.offer
                );
                if (!decision.decided.approval) {
                  return;
                } else {
                  approvedOptions.push(option);
                }
              });

              if (approvedOptions.length > 0) {
                offer.approvedOptions[0].optionInfo = approvedOptions;
              } else {
                return;
              }

              filteredOffers.push(offer);
            });
          }

          const { offers: standardOffers } = await getApprovedWarrantyOffers({
            params: {
              surfaceAreaClass: { label: 'Standard', value: 'C' },
              surfaceAreaValue: Number(data.surfaceArea),
              upsellSealing: data.sealing,
            },
            dimensions: ['duration', 'surfaceAreaClass'],
            partnerTenantLink: activeTenantId,
            warrantorLink: getRootPersonaFromLexicon(
              t(PrincipalsFieldName),
              PersonaTypeSingleLetter.Warrantor
            ),
          });

          if (standardOffers) {
            standardOffers.forEach((standardOffer) => {
              // find the duration option, remove all others
              standardOffer.approvedOptions =
                standardOffer.approvedOptions.filter(
                  (option) => option.label === 'Duration'
                );

              const approvedOptions = [] as any;
              standardOffer.approvedOptions[0].optionInfo.forEach((option) => {
                // perform a check with the params to see if it's approved
                // if not, remove it from the array
                const decision = DeciderPerformDecision(
                  {
                    surfaceAreaClass: { label: 'Standard', value: 'C' },
                    surfaceAreaValue: Number(data.surfaceArea),
                    upsellSealing: data.sealing,
                    duration: {
                      label: option.option.label,
                      value: option.option.value,
                    },
                  },
                  standardOffer.template,
                  standardOffer.offer
                );
                if (!decision.decided.approval) {
                  return;
                } else {
                  approvedOptions.push(option);
                }
              });
              standardOffer.title = standardOffer.title + ' - Standard';
              standardOffer.offer
                ? (standardOffer.offer.title =
                    standardOffer.offer.title + ' - Standard')
                : '';
              standardOffer.template.docid =
                standardOffer.template.docid + '-standard';

              if (approvedOptions.length > 0) {
                standardOffer.approvedOptions[0].optionInfo = approvedOptions;
              } else {
                return;
              }

              filteredOffers.push(standardOffer);
            });
          }
          filteredOffers.sort((a, b) => {
            const orderA = a.offer?.orderNo ?? Number.MAX_VALUE;
            const orderB = b.offer?.orderNo ?? Number.MAX_VALUE;
            return orderA - orderB;
          });
          setOffers(filteredOffers);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [activeTenantId, isReady]);

  const handleSubmit = (values: any) => {
    onChange(2, { ...selectedOption, ...values });
  };

  if (loading)
    return (
      <div className="py-10">
        <LoadingSpinner size="md" />
      </div>
    );

  return (
    <div className="px-5 pt-4">
      {offers && offers.length === 0 ? (
        <>
          <div className="font-nunito mb-4 flex flex-col gap-2 rounded-md bg-red-100 p-4 text-red-700">
            <span className="flex items-center gap-2 font-semibold">
              <ExclamationTriangleIcon className="h-4 w-4 text-red-700" />
              Does not qualify
            </span>
            <div className="text-red-700">
              This does not meet the minimum requirements for any of the
              available options.
            </div>
          </div>
          <div className="flex w-full gap-4 py-4">
            <Button kind="primary" type="submit" onClick={handleClose}>
              Ok
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <Heading kind="h4" className="mb-2">
            Available options
          </Heading>
          <div>
            <Formik
              initialValues={{
                warranty_start_date: (data as any).warranty_start_date,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={true}
              validateOnBlur={false}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="flex flex-col gap-4">
                    {offers?.map((offer) => (
                      <>
                        <SelectableOption
                          key={offer.template.docid}
                          title={offer.title}
                          description={optionDescription(offer)}
                          name={offer.template.docid}
                          selected={
                            selectedOption?.warranty_type ===
                            offer.template.docid
                          }
                          offer={offer.offer ?? ({} as DTWarranty_Offer)}
                          approvedOptions={offer.approvedOptions}
                          template={offer.template}
                          onChange={handleOptionSelected}
                          active={activeOption === offer.template.docid}
                          onOpen={(active) => {
                            setActiveOption(offer.template.docid);
                            active && setSelectedOption(undefined);
                          }}
                        />
                      </>
                    ))}
                    <Input
                      type="datepicker"
                      name="warranty_start_date"
                      label="Warranty start date"
                      settings={{
                        id: 'warranty_start_date',
                        placeholder: 'DD/MM/YYYY',
                        onChange: (value: any) => {
                          if (value) {
                            const formattedDate = format(value, 'yyyy-MM-dd');
                            setFieldValue('warranty_start_date', formattedDate);
                          }
                        },
                      }}
                    />
                  </div>

                  {deciderOutput && (
                    <div className="font-nunito my-2 flex text-lg font-bold text-black">
                      Total:{' '}
                      {deciderOutput.decided.approval
                        ? (deciderOutput.decided.warrantyPrice as number) +
                            (deciderOutput.decided.sealingPrice as number) >
                          0
                          ? getCurrencyFormat(
                              deciderOutput.decided.totalPrice as string,
                              tenantInfo?.currency ?? 'GBP'
                            ) +
                            ' + GST' +
                            (deciderOutput.stipulated.upsellSealing
                              ? ' (sealing included)'
                              : '')
                          : 'Free'
                        : '-'}
                    </div>
                  )}

                  <div className="sticky bottom-0 flex w-full gap-4 bg-white pt-4">
                    <Button
                      kind="primary"
                      type="submit"
                      disabled={!selectedOption}
                    >
                      Continue
                    </Button>
                    <Button kind="red" type="button" onClick={handleClose}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
}

function SelectableOption({
  title,
  description,
  selected: picked,
  active,
  name,
  template,
  offer,
  approvedOptions,
  onChange,
  onOpen,
}: {
  title: string;
  description: string;
  selected: boolean;
  active: boolean;
  name: string;
  template: DTWarranty_Template;
  offer: DTWarranty_Offer;
  approvedOptions: DeciderApprovedOptionInfo[];
  onChange: (
    template: DTWarranty_Template,
    duration: string,
    price: string,
    offer: DTWarranty_Offer
  ) => void;
  onOpen: (open: boolean) => void;
}) {
  const [option, setOption] = useState<string>();
  const durationOptions = approvedOptions.find(
    (i: any) => i.label === 'Duration'
  );

  const options = durationOptions?.optionInfo?.map(
    (obj: SingleApprovedOptionInfo, index: number) => ({
      id: index + '',
      label: obj.option.label,
      value: obj.option.value,
    })
  );

  const onSelectChange = (v: any) => {
    const optionPrice =
      getPricingForSelectedOptions(template, [
        { key: 'duration', value: v.value },
      ]) ?? 0;
    setOption(v.value);
    onChange(template, v?.value, optionPrice.toString(), offer);
  };

  return (
    <div
      className={
        'font-nunito hover:border-primary-900 w-full cursor-pointer rounded-md border border-gray-300 px-4 pt-4' +
        (picked ? ' border-primary-900' : '') +
        (active ? ' overflow-visible' : ' overflow-hidden')
      }
      onClick={() => {
        onOpen(!active);
      }}
    >
      <div className="font-medium">{title}</div>
      <div className="mt-[10px] text-gray-700">{description}</div>
      <div
        className={
          'mt-4 transition-all duration-200' +
          (active ? ' h-[96px]' : ' h-[0px]')
        }
      >
        <div className="pb-4">
          <div className="font-nunito mb-2 text-base text-gray-900 ">Term</div>
          <Select
            classNames={generateSelectStyles()}
            options={options}
            value={options?.find((i) => i.value === option)}
            onChange={onSelectChange}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateNewStoneCoverageOptions;
