import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { SageFileUploader } from '../upload-wrapper/SageFileUploader';
import { Button, LoadingSpinner } from '@rabbit/elements/shared-components';
import { useContext, useEffect } from 'react';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import {
  CaseFlow_Utils_RegisterFileUpload,
  FileStorageContext,
} from '@rabbit/bizproc/react';
import { AppContext } from '@rabbit/app-context';
import { t } from 'i18next';

interface ClaimAttachmentsUploadProps {
  handleClose: () => void;
  shouldCleanupOnUnmount?: boolean;
}

function ClaimAttachmentsUpload({
  handleClose,
  shouldCleanupOnUnmount,
}: ClaimAttachmentsUploadProps) {
  const { config } = useContext(AppContext);
  const context = useContext(CaseflowContext);
  const { caseFlowCase, caseFacts, caseActors, alterCaseFacts } = context || {};

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    updateHoldingWithFiles,
    unusedCompletedCleanup,
    setShouldRefetch,
  } = useContext(FileStorageContext) || {};

  // Remove uploads that haven't been saved (IF told to do so)
  useEffect(() => {
    return () => {
      if (shouldCleanupOnUnmount) unusedCompletedCleanup?.();
    };
  }, []);

  if (
    !caseFlowCase ||
    !caseFacts ||
    !caseActors ||
    !alterCaseFacts ||
    !caseFacts?.consumer_holding ||
    !caseFacts.consumer_claim_evidence ||
    !caseFacts.consumer_proof_of_purchase ||
    !uploadQueueState ||
    !moveCompletedUploadsToAttached ||
    !updateHoldingWithFiles ||
    !setShouldRefetch
  ) {
    return <LoadingSpinner size="md" />;
  }

  return (
    <>
      <SageFileUploader
        label={t('general.proofOfPurchase')}
        identifiers={{
          category: UploadedFileCategories.ConsumerProofPurchase,
          docType: {
            docid: caseFacts?.consumer_holding,
            type: DocTypeShapeTypes.Case,
          },
          personaId: caseActors?.consumer ?? '',
        }}
        alterCaseFacts={alterCaseFacts}
        accepts={['image/*', '.pdf']}
        shouldAutoUpdateDocs={false}
        currentFiles={caseFacts?.consumer_proof_of_purchase ?? []}
      />
      <SageFileUploader
        label={t('general.supportingPhotos')}
        identifiers={{
          category: UploadedFileCategories.ConsumerCaseEvidence,
          docType: {
            docid: caseFacts?.consumer_holding,
            type: DocTypeShapeTypes.Case,
          },
          personaId: caseActors?.consumer ?? '',
        }}
        alterCaseFacts={alterCaseFacts}
        accepts={['image/*', 'video/*', '.pdf']}
        currentFiles={caseFacts?.consumer_claim_evidence ?? []}
      />
      {config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF && (
        <SageFileUploader
          label={t('general.serialNumberProof')}
          identifiers={{
            category: UploadedFileCategories.SerialNumberProof,
            docType: {
              docid: caseFacts.consumer_holding || '',
              type: DocTypeShapeTypes.Case,
            },
            personaId: caseActors?.consumer ?? '',
          }}
          alterCaseFacts={alterCaseFacts}
          accepts={['image/*', '.pdf']}
          shouldAutoUpdateDocs={false}
          maxFiles={1}
          currentFiles={caseFacts?.serial_number_proof ?? []}
        />
      )}
      <div className="mt-4 flex w-full gap-2">
        <Button
          kind="primary"
          onClick={async () => {
            await CaseFlow_Utils_RegisterFileUpload(caseFlowCase, {
              uploadQueueState,
              moveCompletedUploadsToAttached,
              setShouldRefetch,
              updateHoldingWithFiles,
            });
            handleClose();
          }}
        >
          {t('general.save')}
        </Button>
        <Button kind="red" onClick={handleClose}>
          {t('general.cancel')}
        </Button>
      </div>
    </>
  );
}

export default ClaimAttachmentsUpload;
