import { useIdentity } from '@rabbit/data/portal';
import { useRef, useState } from 'react';
import {
  FBD_Holding_Public,
  FBD_Holding_Manufacturer,
  FBD_Holding_Private,
  UserUploadedDocument,
  isValidPersonaId,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { v4 as uuidv4 } from 'uuid';
import {
  UploadTask,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
  deleteObject,
} from 'firebase/storage';
import { firebaseStorage } from '@rabbit/firebase/adapter-react';
import { UploadedFileCategoryShape } from '@rabbit/elements/shared-types';
import { DocTypeShape } from '@rabbit/elements/shared-types';

/**
 * THIS IS DEPCRECATED. USE THE FILESTORAGE CONTEXT
 * AND SAGE/OLIVEFILEUPLOADER COMPONENTS INSTEAD
 */

export interface UploadedFilesStateShape {
  filesArr: UserUploadedDocument[];
  cat: UploadedFileCategoryShape;
}

type PersonaTypeShape =
  | 'Consumer'
  | 'Manufacturer'
  | 'Repairer'
  | 'Retailer'
  | 'Installer'
  | 'Admin';

// TODO: try out structuredClone for deep copying instead of JSON.parse/stringify - DC
// TODO: some refactoring might be in order in general as this is getting a bit too messy - DC
export function useFileStorage() {
  //TODO: It would be better to combine the two below above into one, but let's just call that a TODO for now.
  const [uploadedFiles, setUploadedFiles] =
    useState<UploadedFilesStateShape | null>(null);
  // The difference here is that uploadedFiles are processed (i.e added to existing documents such as holdings)\
  // right away, while TempFiles have to be added to the documents later on and are cleaned up automatically if not used
  // This should still be just one state and one process imo, but I'll do a proper refactor when I have the chance - dc
  const [uploadedTempFiles, setUploadedTempFiles] =
    useState<UploadedFilesStateShape | null>(null);

  const [uploadError, setUploadError] = useState<null | string>(null);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [uploadTaskCat, setUploadTaskCat] =
    useState<UploadedFileCategoryShape | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const uploadedTempFilesRef = useRef<UploadedFilesStateShape | null>(null);
  uploadedTempFilesRef.current = JSON.parse(JSON.stringify(uploadedTempFiles));
  const identity = useIdentity();

  /* -------------------------------------------------------------------------- */
  /*                                File uploader                               */
  /* -------------------------------------------------------------------------- */

  const uploadFiles = async (
    filesToUpload: File[],
    personaId: string = 'M:Admin',
    fileCategory: UploadedFileCategoryShape,
    docType?: DocTypeShape
  ) => {
    // First let's check if we have everything we need
    if (!identity.uid) throw new Error('A valid identity is required');
    if (filesToUpload.length === 0) throw new Error('No files to upload');
    if (!isValidPersonaId(personaId)) throw new Error('Invalid persona ID');

    const personaType: PersonaTypeShape =
      personaId[0] === PersonaTypeSingleLetter.Consumer
        ? 'Consumer'
        : personaId[0] === PersonaTypeSingleLetter.Manufacturer
        ? 'Manufacturer'
        : personaId[0] === PersonaTypeSingleLetter.Repairer
        ? 'Repairer'
        : personaId[0] === PersonaTypeSingleLetter.Installer
        ? 'Installer'
        : personaId[0] === PersonaTypeSingleLetter.Retailer
        ? 'Retailer'
        : 'Admin';

    if (docType && personaType) compareDocTypeWithPersona(docType, personaType);

    // Now we setup the upload tasks
    const promises: UploadTask[] = [];

    console.log('##', personaType, identity.uid, fileCategory, docType?.docid);
    const storagePath = buildStoragePath(
      personaType,
      identity.uid,
      fileCategory,
      docType?.docid
    );

    if (!storagePath) throw new Error('Could not build storage path');

    setUploadTaskCat(fileCategory);
    filesToUpload.map((file) => {
      const storageRef = ref(firebaseStorage, storagePath);
      const uploadTask = uploadBytesResumable(storageRef, file);
      promises.push(uploadTask);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(prog);
        },
        (err) => {
          setUploadError(err.message);
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          const metadata = await getMetadata(uploadTask.snapshot.ref);

          if (file.type.includes('image')) {
            const img = new Image();
            img.src = url;
            img.onload = () => {
              const dimensions = {
                width: img.width,
                height: img.height,
              };
              const newFile: UserUploadedDocument = {
                ogFilename: file.name,
                url,
                metadata,
                dimensions,
                version: 1,
              };

              // TODO: I HATE THIS. Will refactor later - DC
              if (
                fileCategory === 'consumer_claim_evidence' ||
                fileCategory === 'postage_receipts' ||
                fileCategory === 'receipts' ||
                fileCategory === 'internal_case_files' ||
                fileCategory === 'srv_images' ||
                fileCategory === 'tenant_logo' ||
                fileCategory === 'repairer_profile_image'
              ) {
                //Deep copy to preserve all nested fields
                const clonedFilesArr = uploadedTempFiles?.filesArr
                  ? JSON.parse(JSON.stringify(uploadedTempFiles?.filesArr))
                  : [];
                clonedFilesArr.push(newFile);
                setUploadedTempFiles({
                  cat: fileCategory,
                  filesArr: clonedFilesArr,
                });
              } else {
                //Deep copy to preserve all nested fields
                const clonedFilesArr = uploadedFiles?.filesArr
                  ? JSON.parse(JSON.stringify(uploadedFiles?.filesArr))
                  : [];
                clonedFilesArr.push(newFile);

                setUploadedFiles({
                  cat: fileCategory,
                  filesArr: clonedFilesArr,
                });
              }
            };
          } else {
            const newFile: UserUploadedDocument = {
              ogFilename: file.name,
              url,
              metadata,
              version: 1,
            };

            // I laugh in the face of DRY, then I cry. Get it working now, refactor later - DC
            if (
              fileCategory === 'consumer_claim_evidence' ||
              fileCategory === 'postage_receipts' ||
              fileCategory === 'receipts' ||
              fileCategory === 'internal_case_files' ||
              fileCategory === 'srv_images' ||
              fileCategory === 'tenant_logo' ||
              fileCategory === 'repairer_profile_image'
            ) {
              const clonedFilesArr = uploadedTempFiles?.filesArr
                ? JSON.parse(JSON.stringify(uploadedTempFiles?.filesArr))
                : [];
              clonedFilesArr.push(newFile);

              setUploadedTempFiles({
                cat: fileCategory,
                filesArr: clonedFilesArr,
              });
            } else {
              const clonedFilesArr = uploadedFiles?.filesArr
                ? JSON.parse(JSON.stringify(uploadedFiles?.filesArr))
                : [];
              clonedFilesArr.push(newFile);

              setUploadedFiles({
                cat: fileCategory,
                filesArr: clonedFilesArr,
              });
            }
          }
        }
      );
    });
    setIsUpdating(true);
    try {
      await Promise.all(promises);
      console.log('All files uploaded');
    } catch (err) {
      throw new Error(`Something went wrong while uploading the files: ${err}`);
    } finally {
      setIsUpdating(false);
      setUploadTaskCat(null);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                 Updating documents with the uploaded files                 */
  /* -------------------------------------------------------------------------- */

  /** Updates a holding document with the uploaded files. Uses the uploadedFiles state if called immediately
   * after an upload, but can also be triggered at a later date by providing an optional filesArr */
  const updateHoldingWithFiles = async (
    holdingId: string,
    filesArr?: UserUploadedDocument[]
  ) => {
    if (!identity.uid) throw new Error('A valid identity is required');

    const filesToUpload = filesArr ?? uploadedFiles?.filesArr;
    if (!filesToUpload) throw new Error('No files available for the update');

    const [holding, holding_manufacturer, holding_private] = await Promise.all([
      FBD_Holding_Public.get(holdingId),
      FBD_Holding_Manufacturer.get(holdingId),
      FBD_Holding_Private.get(holdingId),
    ]);

    if (!holding || !holding_manufacturer || !holding_private)
      throw new Error('Unable to fetch holding documents');

    setIsUpdating(true);

    try {
      const receipts = holding_private?.receipt || [];
      const purchaseProofs = holding_manufacturer?.purchase_proof || [];

      // Deep copy to preserve all nested fields
      const clonedFilesArr = filesToUpload
        ? JSON.parse(JSON.stringify(filesToUpload))
        : [];

      holding_private.receipt = receipts.concat(clonedFilesArr);
      holding_manufacturer.purchase_proof =
        purchaseProofs.concat(clonedFilesArr);

      //We also update the public holding so its tupdate value stays in sync with the others
      await Promise.all([
        FBD_Holding_Public.set(holding),
        FBD_Holding_Private.set(holding_private),
        FBD_Holding_Manufacturer.set(holding_manufacturer),
      ]);

      // Clear uploaded files
      setUploadedFiles(null);

      console.log('Updated holding successfully');
    } catch (err) {
      throw new Error(
        `Something went wrong while updating the holding: ${err}`
      );
    } finally {
      setIsUpdating(false);
    }
  };

  /** Updates the document for a self registered vendable holding (SRV) with the uploaded files. Uses the uploadedTempFiles state if called immediately
   * after an upload, but can also be triggered at a later date by providing an optional filesArr */
  const updateSRVHoldingWithFiles = async (
    holdingId: string,
    filesArr?: UserUploadedDocument[]
  ) => {
    if (!identity.uid) throw new Error('A valid identity is required');

    const filesToAdd =
      filesArr ?? uploadedTempFilesRef?.current?.filesArr ?? [];
    if (!filesToAdd) throw new Error('No files available for the update');
    console.log('filesToAdd', filesToAdd);

    const [holding, holding_manufacturer, holding_private] = await Promise.all([
      FBD_Holding_Public.get(holdingId),
      FBD_Holding_Manufacturer.get(holdingId),
      FBD_Holding_Private.get(holdingId),
    ]);

    if (!holding || !holding_manufacturer || !holding_private)
      throw new Error('Unable to fetch holding documents');

    setIsUpdating(true);

    try {
      // Until a proper image management system is implemented, we will only support one image for SRV holdings. Uncomment the code below when that is the case - dc
      // const srv_holding_img = holding?.self_registration?.img || [];
      // const srv_holding_images = holding?.self_registration?.images || [];
      // const new_holding_img_urls = filesToAdd.map((item) => item.url);
      // srv_holding_img.push(...new_holding_img_urls);
      // srv_holding_images.push(...filesToAdd);

      const srv_holding_img = filesToAdd[0]?.url ? [filesToAdd[0]?.url] : [];
      const srv_holding_images = filesToAdd[0] ? [filesToAdd[0]] : [];

      if (holding.self_registration) {
        holding.self_registration.img = srv_holding_img ?? [];
        holding.self_registration.images = srv_holding_images ?? [];
      }

      //We also update the other holdings so their tupdate value stays in sync with the others
      await Promise.all([
        FBD_Holding_Public.set(holding),
        FBD_Holding_Private.set(holding_private),
        FBD_Holding_Manufacturer.set(holding_manufacturer),
      ]);

      setUploadedTempFiles(null);
      console.log('Updated holding successfully');
    } catch (err) {
      throw new Error(
        `Something went wrong while updating the holding: ${err}`
      );
    } finally {
      setIsUpdating(false);
    }
  };

  //todo
  // const updateProfileWithFiles = async (personaId: string) => {};

  /* -------------------------------------------------------------------------- */
  /*                    Deleting and clearing files from docs                   */
  /* -------------------------------------------------------------------------- */

  /** Takes in a fullPath or url and deletes a file from Firebase storage */
  const deleteFile = async (urlOrPath: string) => {
    if (!identity.uid) throw new Error('A valid identity is required');

    setIsUpdating(true);

    try {
      const fileRef = ref(firebaseStorage, urlOrPath);
      await deleteObject(fileRef);
      console.log('Deleted file succesfully!');
    } catch (err) {
      throw new Error(`Something went wrong while deleting the file: ${err}`);
    } finally {
      setIsUpdating(false);
    }
  };

  /**  Unlinks a proof of purchase file from a holding document. Doesn't delete the file from storage. */
  const clearFileFromHolding = async (holdingId: string, filePath: string) => {
    if (!identity.uid) throw new Error('A valid identity is required');
    if (!holdingId) throw new Error('A valid holding ID is required');

    const [holding, holding_manufacturer, holding_private] = await Promise.all([
      FBD_Holding_Public.get(holdingId),
      FBD_Holding_Manufacturer.get(holdingId),
      FBD_Holding_Private.get(holdingId),
    ]);

    if (!holding || !holding_manufacturer || !holding_private)
      throw new Error('Unable to fetch holding documents');

    setIsUpdating(true);

    const receipts = holding_private?.receipt || [];
    const purchaseProofs = holding_manufacturer?.purchase_proof || [];

    try {
      if (
        !receipts.some((item) => item.metadata.fullPath === filePath) &&
        !purchaseProofs.some((item) => item.metadata.fullPath === filePath)
      )
        throw new Error('File not found in the holding');

      const updatedReceipts = receipts.filter(
        (item) => item.metadata.fullPath !== filePath
      );

      const updatedPurchaseProofs = purchaseProofs.filter(
        (item) => item.metadata.fullPath !== filePath
      );

      holding_private.receipt = updatedReceipts;
      holding_manufacturer.purchase_proof = updatedPurchaseProofs;

      //We also update the public holding so its tupdate value stays in sync with the others
      await Promise.all([
        FBD_Holding_Public.set(holding),
        FBD_Holding_Private.set(holding_private),
        FBD_Holding_Manufacturer.set(holding_manufacturer),
      ]);
      console.log("Cleared holding's proof of purchase successfully!");
    } catch (err) {
      console.log('err', err);
      throw new Error(`Something went wrong while clearing the file: ${err}`);
    } finally {
      setIsUpdating(false);
    }
  };

  // TODO
  // const clearFilesFromProfile = async () => {};

  /** Deletes a file and clears it from the uploadedFiles state.
   * Used in some specific circumstances, e.g: uploading supporting case evidence on Olive
   * Only takes in a file path, not a url - might add in url support later
   * */
  const clearFileFromState = async (filePath: string) => {
    if (!uploadedTempFiles) return;
    //await deleteFile(filePath);
    const updatedFileArr = uploadedTempFiles.filesArr.filter(
      (item) => item.metadata.fullPath !== filePath
    );
    setUploadedTempFiles({
      cat: uploadedTempFiles.cat,
      filesArr: updatedFileArr,
    });
  };

  /** Clears the whole uploadedFiles state. Used when uploading files during chat,
   * so there is always only a single file to be processed at once.
   */
  const clearAllFilesFromState = () => {
    setUploadedFiles(null);
  };

  /** Clears the whole uploadedTempFiles state. */
  const clearAllTempFilesFromState = () => {
    setUploadedTempFiles(null);
    uploadedTempFilesRef.current = null;
  };

  /* -------------------------------------------------------------------------- */
  /*                        File cleanup on unmount                             */
  /* -------------------------------------------------------------------------- */

  /** Deletes all files in the uploadedTempFiles state that haven't been used. Also accepts an optional filesArr */
  const deleteUnusedTempFiles = async (filesArr?: UserUploadedDocument[]) => {
    const filesToDelete = filesArr ?? uploadedTempFilesRef?.current?.filesArr;
    if (!filesToDelete) return;

    filesToDelete.forEach(async (file) => {
      await deleteFile(file.url);
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                Misc helpers                                */
  /* -------------------------------------------------------------------------- */

  const getFileMetadata = async (url: string) => {
    const fileRef = ref(firebaseStorage, url);

    try {
      const metadata = await getMetadata(fileRef);
      return metadata;
    } catch (err) {
      throw new Error(
        `Something went wrong while fetching the file metadata: ${err}`
      );
    }
  };

  return {
    uploadFiles,
    uploadedFiles,
    uploadedTempFiles: uploadedTempFilesRef.current,
    setUploadedTempFiles,
    deleteFile,
    deleteUnusedTempFiles,
    uploadError,
    uploadProgress,
    uploadTaskCat,
    isUpdating,
    setIsUpdating,
    clearFileFromHolding,
    clearFileFromState,
    clearAllFilesFromState,
    clearAllTempFilesFromState,
    updateHoldingWithFiles,
    updateSRVHoldingWithFiles,
    getFileMetadata,
  };
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */
const compareDocTypeWithPersona = (
  docType: DocTypeShape,
  personaType: PersonaTypeShape
) => {
  if (docType.type === 'holding' && personaType !== 'Consumer') {
    throw new Error(
      'Only consumers can upload files to their holdings. Please check your persona type.'
    );
  }
  if (docType.type === 'vendable' && personaType !== 'Manufacturer') {
    throw new Error(
      'Only manufacturers can upload files to their vendables. Please check your persona type.'
    );
  }
};

const buildStoragePath = (
  personaType: PersonaTypeShape,
  identityId: string,
  fileCategory: UploadedFileCategoryShape,
  docid?: string | null
) => {
  if (!personaType || !identityId || !fileCategory) return null;

  const basePath = `${personaType}/${identityId}`;
  if (docid) {
    return `${basePath}/${fileCategory}/${docid}/${uuidv4()}`;
  }
  return `${basePath}/${fileCategory}/Profile/${uuidv4()}`;
};
