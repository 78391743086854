import {
  usePortalDocumentEditor,
  DR_Consumer_Private,
} from '@rabbit/data/portal';
import {
  FBD_Identity,
  FBD_Identity_Private,
  FBD_Consumer_Private,
  DTConsumer_Private,
  Address,
} from '@rabbit/data/types';
import { FBDTKeygenGeneric } from '@rabbit/firebase/doctype';
import { DRDocType } from '@rabbit/firebase/react';

export interface Sage_CreateConsumerFormDataShape {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  addresses: Address[];
  preferred_contact: string[];
  secondary_first_name?: string;
  secondary_last_name?: string;
  secondary_phone_number?: string;
}

//TODO: We need to check if an email already exists in the system before creating a new identity
export function useConsumerProfile(consumerdocid?: string) {
  const { body, update, commit, isReady, error } = usePortalDocumentEditor(
    DR_Consumer_Private as DRDocType<DTConsumer_Private>,
    consumerdocid || ''
  );

  // const [identityData, setIdentityData] = useState({});

  // const getIdentity = async () => {
  //   if (!body?.identity)
  //     throw new Error('No valid identity associated to persona!');
  //   const identity_public = await FBD_Identity.get(body?.identity);
  //   const identity_private = await FBD_Identity_Private.get(body?.identity);

  //   return { identity_public, identity_private };
  // };

  // useEffect(() => {
  //   (async () => {
  //     const idData = await getIdentity();
  //     setIdentityData(idData);
  //   })().catch((err) => console.log(err));
  // }, [body?.identity]);

  const createNewIdentityAndConsumerPersona = async (
    formData: Sage_CreateConsumerFormDataShape
  ) => {
    // Due to the security rules update, we now have to create both identity and persona at the same time
    // As they can't be updated after creation except by their owner

    // Create a new identity
    try {
      const newIdentity = FBD_Identity.empty();
      const newIdentity_Private = FBD_Identity_Private.empty();

      const newIdentityData = {
        displayname: `${formData.first_name} ${formData.last_name}`,
        email: formData.email,
        photoUrl: '',
        docid: FBDTKeygenGeneric(newIdentity_Private, FBD_Identity_Private),
      };

      newIdentity.docid = newIdentityData.docid;
      newIdentity_Private.docid = newIdentityData.docid;
      newIdentity_Private.displayname =
        newIdentityData.displayname || newIdentityData.email || 'Anonymous';
      newIdentity_Private.email = newIdentityData.email || '';
      newIdentity_Private.photoUrl = newIdentityData.photoUrl || '';

      // Then create a new consumer persona for the identity

      const newConsumerPrivate = FBD_Consumer_Private.empty();
      newConsumerPrivate.fullname = newIdentity_Private.displayname;
      newConsumerPrivate.email = newIdentity_Private.email;
      newConsumerPrivate.photoUrl = newIdentity_Private.photoUrl;
      newConsumerPrivate.identity = newIdentity_Private.docid;
      newConsumerPrivate.fullname = `${formData.first_name} ${formData.last_name}`;
      newConsumerPrivate.address = formData.addresses.map((address, index) => {
        address.id = String(index + 1);
        return address;
      });

      newConsumerPrivate.splitname = {
        first: formData.first_name,
        last: formData.last_name ?? '',
      };

      // Finally, set and return all the relevant documents
      const personaId = await FBD_Consumer_Private.set(newConsumerPrivate);
      console.log('New consumer persona created successfully');
      newIdentity_Private.persona.consumer = personaId;

      await FBD_Identity.set(newIdentity); // TODO: IDENTITY SET ON CLIENT SIDE WILL BE BANNED SOON -- DEV-64
      await FBD_Identity_Private.set(newIdentity_Private); // TODO: IDENTITY SET ON CLIENT SIDE WILL BE BANNED SOON -- DEV-64
      console.log('New identity created successfully');

      return { newIdentity, newIdentity_Private, newConsumerPrivate };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const createConsumerProfile = async (
    formData: Sage_CreateConsumerFormDataShape
  ) => {
    const { newIdentity_Private, newConsumerPrivate, newIdentity } =
      (await createNewIdentityAndConsumerPersona(formData)) || {};

    if (!newConsumerPrivate || !newIdentity_Private || !newIdentity)
      throw new Error('Identity and/or persona were not generated');

    return { newIdentity_Private, newConsumerPrivate, newIdentity };
  };

  const editConsumerProfile = async (
    formData: Sage_CreateConsumerFormDataShape
  ) => {
    if (!consumerdocid) throw new Error('Cannot edit non-existent consumer!');
    if (!body || !isReady) throw new Error('Not ready to edit yet!');
    try {
      update({
        ...body,
        fullname: `${formData.first_name} ${formData.last_name}`,
        email: formData.email,
        phone: formData.phone_number,
        address: formData.addresses,
        secondary_first_name: formData.secondary_first_name,
        secondary_last_name: formData.secondary_last_name,
        secondary_phone_number: formData.secondary_phone_number,
        splitname: {
          first: formData.first_name,
          last: formData.last_name ?? '',
        },
        preferred_contact: formData.preferred_contact,
      });
      await commit();
    } catch (err) {
      console.log(err);
    }
  };

  return {
    consumerPersonaData: body,
    error,
    //identityData,
    createConsumerProfile,
    editConsumerProfile,
  };
}
