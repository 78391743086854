import { useState } from 'react';
import { Modal, ModalSettingsShape } from '@rabbit/elements/shared-components';
import { ModalNewEditCustomerDefault } from './ModalNewEditCustomerDefault';
import { ModalNewEditCustomerAddress } from './ModalNewEditCustomerAddress';
import { Address, DTConsumer_Private } from '@rabbit/data/types';
import { Sage_CreateConsumerFormDataShape } from '@rabbit/bizproc/react';

export interface ModalNewEditCustomerProps {
  handleClose: () => void;
  handleDelete: () => void;
  customerDetails: DTConsumer_Private | null;
  modalSettings: ModalSettingsShape;
  customerType?: string;
}

export function ModalNewEditCustomer({
  handleClose,
  handleDelete,
  customerDetails,
  modalSettings,
  customerType = '',
}: ModalNewEditCustomerProps) {
  const [step, setStep] = useState<'default' | 'address'>('default');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const customerAddress = customerDetails?.address ?? [];
  const [addresses, setAddresses] = useState<Address[]>(customerAddress);
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(
    customerAddress.find((i) => i.isDefault) || customerAddress[0]
  );
  const [customerValues, setCustomerValues] =
    useState<Sage_CreateConsumerFormDataShape>();

  const addressModalSettings: ModalSettingsShape = {
    title: 'Address',
    handleBackPress: () => setStep('default'),
    handleClose: () => setStep('default'),
  };

  return (
    <Modal
      kind="generic"
      settings={step === 'default' ? modalSettings : addressModalSettings}
      className="m-auto w-[724px] rounded-md border bg-white"
      isLoading={isSubmitting}
    >
      {step === 'default' && (
        <ModalNewEditCustomerDefault
          customerDetails={customerDetails}
          handleClose={handleClose}
          handleDelete={handleDelete}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          setStep={setStep}
          customerType={customerType}
          addresses={addresses}
          selectedAddress={selectedAddress}
          values={customerValues}
          onValuesChange={(values) => {
            values.addresses = addresses;
            setCustomerValues(values);
          }}
        />
      )}
      {step === 'address' && (
        <ModalNewEditCustomerAddress
          customerDetails={customerDetails}
          addresses={addresses || []}
          selected={selectedAddress}
          onSelected={(addresses, selected) => {
            setAddresses(
              addresses.map((i) => {
                if (i.line1 === selected.line1) i.isDefault = true;
                else i.isDefault = false;
                return i;
              })
            );
            setSelectedAddress(selected);
          }}
          handleClose={() => setStep('default')}
        />
      )}
    </Modal>
  );
}
