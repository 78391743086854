import {
  DTConsumer_Private,
  DTConsumer_Public,
  DTHoldingProxy,
} from '@rabbit/data/types';
import { NewClaimModalSteps } from '../../ModalNewClaim/ModalNewClaim';
import { ButtonIcon, CardGeneral } from '@rabbit/elements/shared-components';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useContext } from 'react';
import { AppContext } from '@rabbit/app-context';

interface CreateNewChooseProductProps {
  handleClose: () => void;
  changeStep: (step: NewClaimModalSteps, data?: any) => void;
  data: { holdings: DTHoldingProxy[]; consumer: { item: DTConsumer_Public } };
  setLoading: (loading: boolean) => void;
  onHoldingCreated: (result: any) => void;
}

export function CreateNewChooseProduct({
  changeStep,
  data,
}: CreateNewChooseProductProps) {
  const { holdings } = data;
  const { config, tenantInfo } = useContext(AppContext);

  const handleSelection = async (holding: DTHoldingProxy) => {
    const consumer = data.consumer.item;
    changeStep('add-product', { holding, consumer });
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      {holdings.length > 0 &&
        holdings.map((holding) => {
          if (holding?.deleted) return null;
          return (
            <CardGeneral
              key={holding.docid.split('_')[0]}
              onClick={() => handleSelection(holding)}
              holdingData={holding}
              imageUrl={
                holding.img?.[0] || holding.self_registration?.img?.[0] || ''
              }
              tenantInfo={tenantInfo}
              className="cursor-pointer hover:bg-gray-200"
            />
          );
        })}
      {holdings.length > 0 &&
        config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.NEW_PRODUCT_BTN && (
          <ButtonIcon
            Icon={PlusIcon}
            label="Add"
            onClick={() => changeStep('add-product', data)}
          />
        )}
    </div>
  );
}
