import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { getConsumerURL } from 'apps/sage/src/utils/helpers';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { MANDRILL_TEMPLATES, useGetPersonaData } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { DTRepairer_Public, DTWarrantor_Public } from '@rabbit/data/types';
import { AppContext } from '@rabbit/app-context';

// modal is extenral repairer specific for now, but named generally for external partners so it can be extended when necessary - DC
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContactExternalPartnerModalProps {}

interface FormValueShape {
  comment_to_external_partner: string;
  internal_comment: string;
}

const initialValues: FormValueShape = {
  comment_to_external_partner: '',
  internal_comment: '',
};

const validationSchema = Yup.object().shape({
  comment_to_external_partner: Yup.string()
    .trim()
    .required(t('Please enter a message'))
    .test('remove-html-tags', 'Please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    }),
  internal_comment: Yup.string().trim(),
});

export function ContactExternalPartnerModal({}: ContactExternalPartnerModalProps) {
  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    setShowModal,
    operatingPersona,
    caseActors,
    caseId,
  } = useContext(CaseflowContext) || {};
  const { personaPublic: repairerPublic } = useGetPersonaData(
    caseFacts?.delegate_repairer_id ?? '',
    ['public']
  ) as { personaPublic: DTRepairer_Public | null };
  const { personaPublic: warrantorPublic } = useGetPersonaData(
    caseActors.warrantor ?? '',
    ['public']
  ) as { personaPublic: DTWarrantor_Public | null };
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !operatingPersona ||
    !caseFacts ||
    !caseId ||
    !repairerPublic ||
    !warrantorPublic
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValueShape) => {
    const { internal_comment, comment_to_external_partner } = values;
    setIsSubmitting(true);

    if (!repairerPublic.email) {
      toast.error(
        t('Repairer does not have an email address! Please contact support.')
      );
      setIsSubmitting(false);
      return;
    }

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        comment_to_external_repairer: comment_to_external_partner,
      };
      const to = repairerPublic.email,
        from = tenantInfo?.emailInfo?.emailSender ?? '',
        template = MANDRILL_TEMPLATES.BODY_AUTHORISED_REPAIRER_BLANK,
        business_name = repairerPublic.name ?? '',
        claim_id = caseId,
        premium_tenant_name = warrantorPublic.name ?? '',
        subject = t('email.subject.messageToExternalPartner', {
          premium_tenant_name,
        }),
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;
      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'message_external_partner',
          from,
          to,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            premium_tenant_name,
            comment_to_external_partner,
            link_to_claim,
          },
          shallBeSentViaBackend: true,
        });
      }
      setShowModal(false);
      toast.success(t('Email successfully sent to user.'));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong, please try again'));
    }
  };

  return (
    <Modal
      settings={{
        title: t('Contact authorised repairer'),
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors }) => {
          return (
            <Form className="mt-5 flex flex-col px-4">
              <Input
                type="rich-text"
                label={t('Comments to repairer*')}
                name="comment_to_external_partner"
                settings={{
                  id: 'comment_to_external_partner',
                  hint: '*required',
                }}
              />
              {!showInternalComment && (
                <div className="mt-4">
                  <Button
                    kind="outline"
                    type="button"
                    className="w-full"
                    onClick={() => setShowInternalComment(true)}
                  >
                    {t('Add internal comment')}
                  </Button>
                </div>
              )}
              {showInternalComment && (
                <div className="mt-3">
                  <Input
                    type="rich-text"
                    label={t('Internal comment')}
                    name="internal_comment"
                    settings={{
                      id: 'internal_comment',
                      placeholder: '',
                      allowSpecialCharacter: true,
                    }}
                  />
                </div>
              )}
              <div className="mt-8 flex gap-8">
                <Button kind="primary" type="submit" loading={isSubmitting}>
                  {t('Notify repairer')}
                </Button>
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={() => setShowModal(false)}
                >
                  {t('Cancel')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ContactExternalPartnerModal;
