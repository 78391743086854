import { FBF_CloudCallable } from '@rabbit/firebase/adapter';
import {
  GenerateBusinessUserInviteRequestDto,
  GenerateBusinessUserInviteResponseDto,
  DocIdParamDto,
  DecodeBusinessUserInviteLinkRequestDto,
  DecodeBusinessUserInviteLinkResponseDto,
  BusinessUserSignupResponseDto,
  BusinessUserSignupRequestDto
} from '@rabbit/nest/api';

export const nestApiGenerateBusinessUserInvite = FBF_CloudCallable<
  GenerateBusinessUserInviteRequestDto,
  any
>('api/business-invite/generate|POST');

export const nestApiResendBusinessUserInvite = FBF_CloudCallable<
  DocIdParamDto & GenerateBusinessUserInviteRequestDto,
  any
>('api/business-invite/resend|POST');


export const nestApiBusinessUserSignup = FBF_CloudCallable<
BusinessUserSignupRequestDto,
any
>('api/public-business-invite/signup|POST');


export const nestApiDecodeBusinessUserInviteLink = FBF_CloudCallable<
  DecodeBusinessUserInviteLinkRequestDto,
  any
>('api/public-business-invite/decode|POST');