import {
  DTConsumer_Private,
  DTHoldingProxy,
  FBD_Category_Public,
  FBD_HoldingProxy,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  FBD_Vendable,
  FBD_CaseFlowPrivateProxy,
  DTCaseFlowPrivateProxy,
} from '@rabbit/data/types';
import { useEffect, useState } from 'react';
import { CFC_FetchConsumerPrivate_WithId } from '@rabbit/bizproc/core';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { t } from 'i18next';

export interface SageConsumerDataShape {
  consumer_private?: DTConsumer_Private | null;
  holding_proxy?: DTHoldingProxy | null;
  allHoldings: DTHoldingProxy[] | null;
  claims: DTCaseFlowPrivateProxy[];
}
/**
 * Fetches a consumer's data from a holding or consumer ID. If a holding ID is used,
 * it will also return the holding proxy for that holding.
 *
 * NOTE: There are other hooks that also do consumer data fetching, but they might become deprecated in the future
 * It's recommended to use this one unless you have a specific reason not to.
 * @param holdingId
 * @param consumerId
 * @returns
 */
export function useGetConsumerData(
  holdingId?: string | null,
  consumerId?: string
) {
  const [consumerData, setConsumerData] =
    useState<SageConsumerDataShape | null>(null);

  if (!holdingId && !consumerId)
    throw new Error('Must provide a holding or consumer ID');

  const getData = async (): Promise<SageConsumerDataShape> => {
    let holding_proxy: DTHoldingProxy | null | undefined;
    let consumer_private: DTConsumer_Private | null | undefined;
    let claims: DTCaseFlowPrivateProxy[] = [];

    if (holdingId) {
      holding_proxy = await FBD_HoldingProxy.get(holdingId);
      if (!holding_proxy) throw new Error('Holding proxy not found');

      const result = await CFC_FetchConsumerPrivate_WithId.call({
        consumerId: holding_proxy.consumer,
      });
      if (!result.ok) throw new Error(result.error);
      consumer_private = result.data.consumer_private;

      if (!consumer_private) throw new Error('Consumer not found');
    }

    if (consumerId && !holdingId) {
      const result = await CFC_FetchConsumerPrivate_WithId.call({
        consumerId,
      });
      if (!result.ok) throw new Error(result.error);
      consumer_private = result.data.consumer_private;
      if (!consumer_private) throw new Error('Consumer not found');
    }

    if (consumerId) {
      claims = await FBD_CaseFlowPrivateProxy.query()
        .where(consumerId, '!=', '')
        .getContents();
    }

    let categoryTitle: string | null = null;
    if (holding_proxy?.category) {
      const vendable = await FBD_Vendable.get(holding_proxy.vendable);
      if (vendable) {
        const category = vendable.category?.length
          ? vendable.category[vendable.category.length - 1]
          : '-';
        const categoryPublicData = await FBD_Category_Public.query()
          .where('key', '==', category)
          .limit(1)
          .getContents();
        categoryTitle =
          categoryPublicData[0]?.titles?.en || formatCategory(category); // By default, we pass the Vendable Category.
      }
      holding_proxy.category = categoryTitle ? [categoryTitle] : [];
    }

    const allHoldings = consumer_private
      ? (await getAllHoldingProxies(consumer_private.docid)) ?? []
      : null;

    return {
      consumer_private,
      holding_proxy,
      allHoldings,
      claims,
    };
  };

  useEffect(() => {
    (async () => {
      const data = await getData();
      setConsumerData(data);
    })().catch((err) => console.error(err));
  }, [holdingId]);

  return consumerData;
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const getAllHoldingProxies = async (
  consumerId: string
): Promise<DTHoldingProxy[]> => {
  const proxyQuery = FBD_HoldingProxy.query().where(
    'consumer',
    '==',
    consumerId
  );

  const manufacturer = getRootPersonaFromLexicon(
    t(PrincipalsFieldName),
    PersonaTypeSingleLetter.Manufacturer
  );

  if (manufacturer) {
    proxyQuery.where('mfr', '==', manufacturer);
  }

  const holdingProxies = await proxyQuery.getContents();
  return holdingProxies;
};

const formatCategory = (categories: string) => {
  return categories
    .replace(/-/g, ' ')
    .replace(/\b\w/g, (char: string) => char.toUpperCase());
};
