import { onSnapshot } from '@firebase/firestore';
import { CaseAlterability, ClientCaseFlowCase } from '@rabbit/bizproc/client';
import { ActorCaseCollection } from '@rabbit/bizproc/core';
import { doc } from 'firebase/firestore';
import { firebaseStore } from '@rabbit/firebase/adapter-react';
import { FBD_CaseFlowActorPrivate, FBD_CaseFlowCase } from '@rabbit/data/types';

let SerialNumber = 0;
const ExecutionNumber = new Date().getTime();

type FirebaseSubscriptionHolder = {
  [key: string]: {
    unsubscribe: () => void;
  };
};

type CheckFunc = (doc: any) => boolean;

export type CaseFlowCaseEditingID = {
  type: 'cfc';
  case: string | undefined; // Undefined is necessary for hook fallback objects
  operating_persona: string;
  principal_persona: string;
  isNewCase: boolean;
  isAdmin?: boolean;
};

export function MakeCaseEditingID(
  operating_persona_id: string,
  principal_persona_id: string,
  case_id: string
): CaseFlowCaseEditingID {
  return {
    type: 'cfc',
    case: case_id,
    operating_persona: operating_persona_id,
    principal_persona: principal_persona_id,
    isNewCase: false
  };
}

export class ReactCaseFlowCase extends ClientCaseFlowCase {
  private _serialNumber = SerialNumber++;
  private _executionNumber = ExecutionNumber;

  constructor(
    operatingPersona: string,
    principalPersona: string,
    case_id?: string,
    isAdmin?: boolean
  ) {
    super(operatingPersona, principalPersona, case_id, isAdmin);
  }

  GetEditingID(): CaseFlowCaseEditingID {
    return {
      type: 'cfc',
      case:
        this._case_id !== ''
          ? this._case_id
          : this.operatingPersona +
            '_' +
            this._serialNumber +
            '_' +
            this._executionNumber,
      operating_persona: this.operatingPersona || '',
      principal_persona: this.principalPersona || '',
      isNewCase: this._case_id === '',
      isAdmin: this.isAdmin,
    };
  }

  protected FirebaseSubscriptions: FirebaseSubscriptionHolder = {};

  SubToDocumentIfNeeded(
    collection: string,
    docid: string,
    hasChanged: CheckFunc
  ) {
    const subkey = collection + '/' + docid;
    if (this.FirebaseSubscriptions[subkey]) {
      // console.log('Subscription already exists - ' + subkey);
    } else {
      // console.log('Subscribe for updates - ' + subkey);
      const unsub = onSnapshot(
        doc(firebaseStore, collection, docid),
        async (doc) => {
          if (!doc.exists()) {
            console.log('Doc doesnt exist: ' + collection + '/' + docid);
            return;
          }
          // console.log('Something changed in doc ' + collection + '/' + docid);
          if (hasChanged(doc.data())) {
            // console.log('Worth updating for');
            // Don't update if we are mid-commit and awaiting an update.
            // Since we subscribe to the document the database may trigger an update whilst the commit is still in progress.
            // We ignore this because at the end of the commit the document will be reloaded anyway.
            if (this.alterability !== CaseAlterability.AwaitingUpdate) {
              await this.LoadCase(this._case_id);
            }
            // } else {
            // console.log('NOT Worth updating for');
          }
        }
      );
      this.FirebaseSubscriptions[subkey] = {
        unsubscribe: unsub,
      };
    }
  }

  override async LoadCase(case_docid: string) {
    // Load the case first
    // Always undefined? - dc
    const case_doc = await super.LoadCase(case_docid);
    // Slightly dirty subscription hack:
    // Ensure we are subscribed to all the case documents that might be updated.
    // If they are indeed different, reload the case. According to Firebase this should just hit local cache so no real cost.

    this.SubToDocumentIfNeeded(
      FBD_CaseFlowCase.collectionName,
      case_docid,
      (doc) => {
        return JSON.stringify(doc) !== JSON.stringify(this._case);
      }
    );

    // Temporarily disabling these subscriptions as they are not currently used and generating a lot of console logs - dc

    for (const key in this._actorCases) {
      const actor = this._actorCases[key as keyof ActorCaseCollection];
      if (actor?.docid) {
        this.SubToDocumentIfNeeded(
          FBD_CaseFlowActorPrivate.collectionName,
          actor.docid,
          (doc) => {
            return JSON.stringify(doc) !== JSON.stringify(actor);
          }
        );
      }
    }

    // TODO: All the other documents we need to subscribe to (eg actor)

    this._informSubscribers();

    return case_doc;
  }

  Shutdown() {
    // Unsubscribe from cases we no longer care about
  }

  async Commit() {
    const docid = await this.CommitToServer();
    if (this._case_id !== docid) {
      this._case_id = docid;
    }
    if (this._case.docid !== docid) {
      this._case.docid = docid;
      this._informSubscribers();
    }
  }
}
