import {
  forceUpperCaseFirstLetter,
  handleKeyPress,
} from '@rabbit/elements/shared-components';
import classNames from 'classnames';
import { Field, useField } from 'formik';
import { InputSettingsShape } from '@rabbit/elements/shared-types';

type InputTypeTextProps = {
  name: string;
  settings: InputSettingsShape;
};

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-900 border-[1px] rounded-lg border-gray-300 focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  withAppend:
    'font-nunito border-[1px] border-gray-300 rounded-none rounded-tr-lg rounded-br-lg',
  withPrepend:
    'font-nunito border-[1px] border-gray-300 rounded-none rounded-tl-lg rounded-bl-lg',
  error: 'bg-red-50 border-red-500 text-red-700 rounded-lg',
  disabled: 'border-gray-300 text-gray-400 opacity-60 rounded-lg',
  chat: '!bg-white h-[42px] rounded-lg',
};

function StyledInput({ field, ...props }: any) {
  const { append, prepend } = props;
  const inputValue = field.value ?? '';

  return (
    <div className="flex">
      {append && (
        <span className="rounded-tl-lg rounded-bl-lg border border-r-0 border-gray-300 bg-gray-100 px-4 py-3">
          {append}
        </span>
      )}
      <input
        type="text"
        id={props.id}
        placeholder={props.placeholder}
        autoComplete="off"
        value={inputValue}
        {...props}
        {...field}
      />
      {prepend && (
        <span className="rounded-tr-lg rounded-br-lg border border-l-0 border-gray-300 bg-gray-100 px-4 py-3">
          {prepend}
        </span>
      )}
    </div>
  );
}

//TODO: investigate Errors prop in formik - does not change styles
export function InputTypeText({ name, settings }: InputTypeTextProps) {
  const {
    placeholder,
    disabled,
    id,
    allowSpecialCharacter = true,
    blockLetters = false,
    onFocus,
    forceUpperCaseFirstLetter: forceUCFirst,
    errors,
    append,
    prepend,
    allowedSpecialCharacters,
    ...rest
  } = settings;

  const [, meta] = useField(name);

  const blockLettersHandler = (e: React.KeyboardEvent) => {
    const { key } = e;
    if (key === 'Backspace' || key === 'Delete') return;
    const regex = /[0-9\.]/;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { key } = e;

    if (blockLetters) {
      blockLettersHandler(e);
      return;
    }

    if (!allowSpecialCharacter) {
      handleKeyPress(e);
      return;
    }

    // Allow navigation keys
    const navigationKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Enter',
    ];
    if (navigationKeys.includes(key)) return;

    if (allowSpecialCharacter && allowedSpecialCharacters) {
      if (
        !allowedSpecialCharacters.includes(key) &&
        !/^[a-zA-Z0-9\s]$/.test(key) // Added \s to allow spaces
      ) {
        e.preventDefault();
      }
      return;
    }
  };

  return (
    <Field
      name={name}
      component={StyledInput}
      className={classNames(dynamicStyles.base, {
        [`${dynamicStyles.chat}`]: id === 'chat_input',
        [`${dynamicStyles.withPrepend}`]: prepend,
        [`${dynamicStyles.withAppend}`]: append,
        [`${dynamicStyles.error}`]: meta.error,
        [`${dynamicStyles.disabled}`]: disabled,
      })}
      placeholder={placeholder}
      type={'text'}
      id={id}
      disabled={disabled}
      onKeyDown={handleKeyDown}
      onKeyUp={forceUCFirst ? forceUpperCaseFirstLetter : undefined}
      onFocus={onFocus}
      append={append}
      prepend={prepend}
      {...rest}
    />
  );
}
