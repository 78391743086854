import { TrashIcon } from '@heroicons/react/24/outline';
import { Input } from '@rabbit/elements/shared-components';
import { Form, Formik, FormikErrors } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { RepairWorkRegistry_PartValuesShape } from '@rabbit/bizproc/core';
import { AppContext } from '@rabbit/app-context';

export interface PartItemProps {
  handleDelete: () => void;
  onChange?: (
    data: RepairWorkRegistry_PartValuesShape,
    formErrors?: FormikErrors<RepairWorkRegistry_PartValuesShape>
  ) => void;
  id: string;
  setPartsValues: React.Dispatch<
    React.SetStateAction<RepairWorkRegistry_PartValuesShape[]>
  >;
  data: RepairWorkRegistry_PartValuesShape;
}

const validationSchema = Yup.object().shape({
  part_name: Yup.string().required('Please enter a part name').trim(),
  model_id: Yup.string().required('Please enter a model id').trim(),
  parts_quantity: Yup.string().required('Please enter part quantity').trim(),
  parts_cost: Yup.object()
    .shape({
      amount: Yup.string().required('Please enter a part cost').trim(),
      currency: Yup.string(),
    })
    .required('Please enter a part cost'),
});

export function PartItem({
  id,
  data,
  handleDelete,
  onChange,
  setPartsValues,
}: PartItemProps) {
  const { tenantInfo } = useContext(AppContext);

  const initialValues: RepairWorkRegistry_PartValuesShape = {
    id: data.id ?? '',
    part_name: data.part_name ?? '',
    model_id: data.model_id ?? '',
    parts_quantity: data.parts_quantity ?? '',
    parts_cost: {
      amount: data.parts_cost.amount ?? '',
      currency: data.parts_cost.currency ?? tenantInfo?.currency ?? '',
    },
  };

  const [formValues, setFormValues] =
    useState<RepairWorkRegistry_PartValuesShape>(initialValues);
  const [formErrors, setFormErrors] = useState<
    FormikErrors<RepairWorkRegistry_PartValuesShape> | undefined
  >(undefined);

  useEffect(() => {
    onChange && onChange(formValues, formErrors);
  }, [formValues, formErrors]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => void 0}
      validationSchema={validationSchema}
      innerRef={(formikActions) =>
        formikActions
          ? setFormValues(formikActions.values)
          : setFormValues({
              id: '',
              part_name: '',
              model_id: '',
              parts_quantity: '',
              parts_cost: {
                amount: '',
                currency: tenantInfo?.currency ?? '',
              },
            })
      }
    >
      {({ errors, values, touched }) => {
        Object.keys(touched).length > 0 && setFormErrors(errors);
        return (
          <Form className="flex flex-col gap-3" id={id}>
            <div className="flex items-start gap-4">
              <div className="w-[300px]">
                <Input
                  type="text"
                  name="part_name"
                  settings={{
                    id: 'part_name',
                    placeholder: 'Part name',
                    hint: '*required',
                  }}
                />
              </div>
              <div className="grow">
                <Input
                  type="text"
                  label=""
                  name="model_id"
                  settings={{
                    id: 'model_id',
                    placeholder: 'Model ID',
                    hint: '*required',
                  }}
                />
              </div>
              <div className="grow">
                <Input
                  type="text"
                  name="parts_quantity"
                  settings={{
                    id: 'parts_quantity',
                    placeholder: 'Quantity',
                    hint: '*required',
                  }}
                />
              </div>
              <div className="max-w-[200px] grow">
                <Input
                  type="currency"
                  name="parts_cost"
                  settings={{
                    placeholder: 'Per unit',
                    currency: tenantInfo?.currency,
                    hint: '*required',
                  }}
                />
              </div>
              <div
                className="bg-primary-900 cursor-pointer rounded-md px-3 py-3"
                onClick={handleDelete}
              >
                <TrashIcon className="h-5 w-5 stroke-white" />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PartItem;
