import { query, orderBy, where } from 'firebase/firestore';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { DR_Holding_Proxy } from '@rabbit/data/portal';
import { useEffect, useState } from 'react';
import { ExtendedDecodedIdToken } from '@rabbit/bizproc/server-logic';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  TenantLink,
} from '@rabbit/data/types';
import { t } from 'i18next';

export interface HoldingListFilters {
  showVoided?: string;
  beginDate?: Date | null;
  endDate?: Date | null;
  retailers?: string[];
  installer?: string;
  warrantyStatus?: string;
}

/** For use in Sage. Fetches a list of all holdings registered by users on Olive and returns their data
 * @param premiumTenant - The premium tenant linked to the holdings we want to fetch
 * @param accessToken - The user's access token
 * @param activePersonaIds - The user's persona IDs
 * @param dateRange - The date range for which to fetch holdings
 * @returns An object containing the holding list and a boolean indicating whether the list is still loading
 */
export function useGetHoldingList(
  // pageSize: number,
  premiumTenant: TenantLink,
  accessToken: ExtendedDecodedIdToken,
  activePersonaIds: { [key: string]: string },
  holdingListFilters?: HoldingListFilters
) {
  const [queryEnabled, setQueryEnabled] = useState(false);

  // TODO: DEV-462
  const dateFrom = holdingListFilters?.beginDate
    ? holdingListFilters?.beginDate.getTime()
    : 0;
  const dateTo = holdingListFilters?.endDate
    ? holdingListFilters?.endDate.getTime()
    : 999999999999999;

  /* -------------------------------------------------------------------------- */
  /*                              Query Constraints                             */
  /* -------------------------------------------------------------------------- */

  /* ---------------------------- Base constraints ---------------------------- */
  const queryConstraints = [
    where('tcreate', '>=', dateFrom),
    where('tcreate', '<=', dateTo),
    where('deleted', '==', false),
    where(
      'mfr',
      '==',
      PersonaTypeSingleLetter.Manufacturer +
        PersonaIdTypeSplitter +
        premiumTenant
    ),
    orderBy('tcreate'),
  ];

  /* ------------------------------ Voiding filter -----------------------------*/
  // If show voided filter / hide voided filter is Set

  if (holdingListFilters?.showVoided !== undefined) {
    queryConstraints.push(
      where(
        'hasWarranty',
        '==',
        holdingListFilters?.showVoided !== 'Show only voided'
      )
    );
  }

  /* ------------------------------ Retailer data ------------------------------ */
  //If querying for a single retailer or a group of retailers

  if (holdingListFilters?.retailers) {
    queryConstraints.push(
      where(
        'warrantyRetailerLinks',
        'array-contains-any',
        holdingListFilters?.retailers
      )
    );
  }
  if (holdingListFilters?.installer) {
    queryConstraints.push(
      where('installer', '==', holdingListFilters?.installer)
    );
  }

  if (activePersonaIds?.installer) {
    queryConstraints.push(
      where('installer', '==', activePersonaIds?.installer)
    );
  }
  if (activePersonaIds?.consumer) {
    queryConstraints.push(where('consumer', '==', activePersonaIds?.consumer));
  }

  /* ----------------------------- WIRE & NUCOVER ----------------------------- */
  // Partner retailers for WIRE and NUCOVER can only see their own registrations
  const ownRegistrationsView =
    t('CFG_RETAILERS_RESTRICT_TO_OWN_REGISTRATIONS') ?? 'false';

  if (
    ownRegistrationsView &&
    accessToken?.[
      PersonaTypeSingleLetter.Retailer + PersonaIdTypeSplitter + premiumTenant
    ]?.includes('I') &&
    activePersonaIds?.retailer &&
    !accessToken?.admin
  ) {
    queryConstraints.push(
      where(
        'warrantyRetailerLinks',
        'array-contains',
        `${activePersonaIds?.retailer}`
      )
    );
  }

  /* ------------------------------ Create query ------------------------------ */
  const holdingQuery = query(
    DR_Holding_Proxy.collection(),
    ...queryConstraints
  );

  const holdingList = useFirestoreQueryData(
    [
      'holdingList',
      `${dateFrom + '-' + dateTo}`,
      premiumTenant,
      activePersonaIds.retailer,
      activePersonaIds.repairer,
      activePersonaIds.installer,
      activePersonaIds.consumer,
      holdingListFilters?.retailers,
      holdingListFilters?.showVoided,
      holdingListFilters?.installer,
    ],
    holdingQuery,
    { subscribe: true },
    // only run if the user's access token includes permissions to view tenant's data
    {
      enabled: queryEnabled,
      refetchOnWindowFocus: true,
      retry: true,
      onError: (err) => {
        console.log('err', err);
      },
    }
  );

  useEffect(() => {
    const canRunQuery = () => {
      if (!premiumTenant || !accessToken) return false;
      if (!accessToken?.admin && !accessToken?.T?.includes(premiumTenant))
        return false;
      if (
        premiumTenant === 'WARRANTYIRELAND' ||
        premiumTenant === 'NUCOVER' ||
        premiumTenant === 'PINNACLEWARRANTIES'
      ) {
        if (!activePersonaIds?.retailer) return false;
      }
      return true;
    };
    setQueryEnabled(canRunQuery());
  }, [premiumTenant, accessToken, activePersonaIds]);

  return {
    holdingList,
    isLoading: holdingList.isLoading || queryEnabled === false,
  };
}
