import { useContext, useMemo, useState } from 'react';
import {
  ButtonIcon,
  formatPhoneNumber,
  formatUnixTime,
  Modal,
  Table,
} from '@rabbit/elements/shared-components';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { useGetSagePartners } from '@rabbit/bizproc/react';
import {
  BusinessUserInviteStatus,
  DTBusiness_User_Invite,
  DTTenant_Public,
  PersonaTypeFullKeyValues,
} from '@rabbit/data/types';
import {
  formatAddress,
  getDefaultAddress,
  getPartnerLabelByValue,
  getUserTypeFromPrincipals,
} from '../../../utils/helpers';
import { ModalNewEditPartner } from '../../organisms/ModalNewEditPartner/ModalNewEditPartner';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import { AddCircle } from '@mui/icons-material';
import { MRT_ColumnDef } from 'material-react-table';
import { Capitalise } from '../../../../../../libs/data/debug/src/generators/elements.ts';

export interface FormValues {
  beginDate: Date | null;
  endDate: Date | null;
}

export function PartnersTab() {
  const { config, tenantInfo } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState<boolean>(false);
  const [partnerDetails, setPartnerDetails] = useState<
    DTBusiness_User_Invite | DTTenant_Public
  >();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>();

  const { partnersList } = useGetSagePartners(t('tenantLink')) || [];

  const handleEditModal = (
    title: string,
    partnerDetails?: DTBusiness_User_Invite | DTTenant_Public
  ) => {
    setModalTitle(title);
    setPartnerDetails(partnerDetails);
    setIsNewEditModalOpen(true);
  };

  const addEditModalSettings = {
    title: modalTitle,
    handleClose: () => setIsNewEditModalOpen(false),
  };

  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this partner. You will lose all information. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const sortData = (data: DTBusiness_User_Invite[] | DTTenant_Public[]) => {
    return data.sort(
      (
        a: DTBusiness_User_Invite | DTTenant_Public,
        b: DTBusiness_User_Invite | DTTenant_Public
      ) => {
        return a.orgName && b.orgName && a.orgName > b.orgName ? 1 : -1;
      }
    );
  };

  const tableheaders = (t: any) =>
    useMemo<MRT_ColumnDef<any>[]>(
      () => [
        {
          header: t('general.name'),
          accessorKey: 'name',
          size: 150,
          filterVariant: 'text',
        },
        {
          header: t('general.email'),
          accessorKey: 'email',
          size: 15,
        },
        {
          header: t('general.phone'),
          accessorKey: 'phone',
          size: 150,
          filterVariant: 'text',
        },
        {
          header: t('general.address'),
          accessorKey: `address`,
          size: 150,
          Cell: ({ cell }: any) => {
            if (
              cell.getValue() &&
              Array.isArray(cell.getValue()) &&
              Array(cell.getValue().length)
            ) {
              return (
                <div className="text-sm">
                  {formatAddress(cell.getValue()[0])}
                </div>
              );
            } else return '-';
          },
          // Cell: ({
          //   row,
          // }: {
          //   row: {
          //     original: {
          //       shippingAddress: {
          //         street: string;
          //         city: string;
          //         postcode: string;
          //         country: string;
          //       };
          //     };
          //   };
          // }) => (
          //   <>
          //     <p>{row.original.shippingAddress.street}</p>
          //     <p>{row.original.shippingAddress.city}</p>
          //     <p>
          //       {row.original.shippingAddress.postcode}
          //       {row.original.shippingAddress.country}
          //     </p>
          //   </>
          // ),
        },
        {
          header: t('general.type'),
          accessorKey: 'type',
          size: 100,
          filterVariant: 'select',
          filterSelectOptions: [
            Capitalise(PersonaTypeFullKeyValues.installer),
            Capitalise(PersonaTypeFullKeyValues.repairer),
            Capitalise(PersonaTypeFullKeyValues.warrantor),
            Capitalise(PersonaTypeFullKeyValues.warrantyDealer),
          ],
        },
        {
          header: t('general.status'),
          accessorKey: 'status',
          size: 20,
          filterVariant: 'select',
          filterSelectOptions: [
            'Active',
            Capitalise(BusinessUserInviteStatus.Pending),
            t('general.requested'),
            Capitalise(BusinessUserInviteStatus.Cancelled),
          ],
        },
        {
          header: t('general.dateModified'),
          accessorKey: 'date_modified',
          size: 20,
        },
        {
          header: '',
          title: 'actions',
          accessorKey: 'edit',
          size: 20,
          enableColumnFilter: false,
          enableColumnActions: false,
          enableSorting: false,
        },
      ],
      []
    );

  const formatResultsForPartnersTable = () => {
    let dataList = partnersList ? sortData(partnersList) : [];

    if (import.meta.env.VITE_FIREBASE_MODE === 'LIVE') {
      dataList =
        partnersList?.filter(
          (result: DTBusiness_User_Invite | DTTenant_Public) =>
            ((result as DTBusiness_User_Invite)?.email &&
              !(result as DTBusiness_User_Invite).email?.includes(
                '@iwarranty.co'
              ) &&
              !(result as DTBusiness_User_Invite)?.email?.includes(
                '@studiographene.com'
              )) ||
            ((result as DTTenant_Public)?.info?.email &&
              (result as DTTenant_Public)?.info?.email?.includes(
                '@iwarranty.co'
              ) &&
              (result as DTTenant_Public)?.info?.email?.includes(
                '@studiographene.com'
              ))
        ) ?? [];
    }

    if (partnersList?.length)
      return dataList.map(
        (result: DTBusiness_User_Invite | DTTenant_Public) => {
          return {
            id: result?.docid,
            name: result.orgName ?? '-',
            email:
              (result as DTBusiness_User_Invite)?.email ||
              (result as DTTenant_Public)?.info?.email,
            phone: formatPhoneNumber(
              (result as DTBusiness_User_Invite)?.phone ||
                (result as DTTenant_Public)?.info?.phone
            ),
            address: getDefaultAddress(
              (result as DTBusiness_User_Invite)?.addresses ??
                (result as DTTenant_Public)?.info?.address ??
                []
            ),
            type: (result as DTBusiness_User_Invite)?.personas
              ? getPartnerLabelByValue(
                  (result as DTBusiness_User_Invite).personas[0],
                  config
                )
              : getPartnerLabelByValue(
                  getUserTypeFromPrincipals(
                    (result as DTTenant_Public)?.principals
                  ),
                  config
                ) ?? '-',
            status: (result as DTBusiness_User_Invite)?.status
              ? t(
                  `general.${(
                    result as DTBusiness_User_Invite
                  ).status.toLowerCase()}`
                )
              : t('general.active'),
            date_modified: result.tupdate
              ? result.tcreate !== result.tupdate
                ? formatUnixTime(result.tupdate, 'dd/MM/yyyy')
                : ''
              : '',
            // Only enabling editing for partners with status 'Active' for now
            edit: (result as DTBusiness_User_Invite)?.status ? (
              (result as DTBusiness_User_Invite)?.status ===
              BusinessUserInviteStatus.requested ? (
                <div className="flex justify-end gap-2 px-2 text-right">
                  <AddCircle
                    className="text-primary-900 h-[20px] w-[20px]"
                    onClick={() => {
                      handleEditModal(t('general.approvePartner'), {
                        ...result,
                        docid: '',
                      });
                    }}
                  />
                </div>
              ) : (
                <div className="flex justify-end gap-2 px-2 text-right">
                  <PencilSquareIcon
                    className="text-primary-900 h-[20px] w-[20px] opacity-50"
                    // onClick={() => {
                    //   handleEditModal('Edit partner', result);
                    // }}
                  />
                </div>
              )
            ) : (
              <div className="flex justify-end gap-2 px-2 text-right">
                <PencilSquareIcon
                  className="text-primary-900 h-[20px] w-[20px]"
                  onClick={() => {
                    handleEditModal('Edit partner', result);
                  }}
                />
              </div>
            ),
          };
        }
      );
    return dataList;
  };

  return (
    <>
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div className="absolute top-[60px] right-5">
          <div>
            <ButtonIcon
              label={t('general.addPartner')}
              iconLeft={true}
              onClick={() => handleEditModal(t('general.addPartner'))}
            />
          </div>
        </div>
      </div>
      <Table
        columns={tableheaders(t)}
        data={formatResultsForPartnersTable()}
        initialState={{
          columnPinning: { right: ['actions'] },
          showCustomFilter: false,
          showGlobalFilter: true,
        }}
        columnFilterDisplayMode={'popover'}
        enableColumnActions={true}
        muiSearchTextFieldProps={{
          placeholder: t('message.searchEmailAddressOrPartnerName'),
          sx: {
            minWidth: '600px',
            borderRadius: '20px',
            backgroundColor: '#fff',
            margin: '5px 0',
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderRadius: '10px' },
            },
          },
          variant: 'outlined',
          margin: 'none',
        }}
        useCallbackClickRow={(data: {
          shippingAddress: {
            street: string;
            city: string;
            postcode: string;
          };
          productsRegistered: string;
          phone: string;
          email: string;
          name: string;
          id: string;
        }) =>
          navigate(SAGE_ROUTE_NAME.MANAGE + '/partner/' + data.id, {
            state: { docid: data.id, productName: data.name },
          })
        }
        state={{
          isLoading: !partnersList,
        }}
      />
      {isNewEditModalOpen && (
        <div>
          <ModalNewEditPartner
            handleClose={() => setIsNewEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            partner={partnerDetails}
            modalSettings={addEditModalSettings}
          />
          {isConfirmationModalOpen && (
            <Modal
              className="h-fit w-[350px] rounded-md bg-white"
              kind={confirmationModalSettings.kind}
              settings={confirmationModalSettings.settings}
            />
          )}
        </div>
      )}
    </>
  );
}

export default PartnersTab;
