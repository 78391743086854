import {
  ServiceTemplateLink,
  Z_ServiceTemplateLink,
} from './service-template';
import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { z, schema } from '@rabbit/utils/ts';
import { TenantLink, Z_TenantLink } from './tenant';
import { WarrantorLink } from '../persona';
import { DeciderWarrantyOffer_Stringified } from '../decider/types';
import { Money, Z_Money } from '../base/basic';

export interface DTService_Offer extends NoSqlDoc {
  /** The name of the service offer */
  title: string;

  /** The entity which owns this warranty offer and handles claims related to it */
  warrantorLink: WarrantorLink;

  /** The service template this offer is based on */
  templateLink: ServiceTemplateLink;

  /** If decisions can be made with this offer, the schedule defines them */
  decider?: DeciderWarrantyOffer_Stringified;

  /** The partner-tenant which distributes this offer */
  tenantLink: TenantLink;
  
  enabled: boolean;

  /** The price of this service offer */
  price: Money;

  // /** The add-on services that can be included with this offer */
}

export const FBD_Service_Offer = MakeFBDocType<DTService_Offer>({
  name: 'Service Offer',
  collection: 'service_offer',
  empty: () => ({
    ...Empty_NoSqlDoc(),
    title: '',
    templateLink: '',
    warrantorLink: '',
    price: { currency: '', amount: 0 },
    enabled: true,
    tenantLink: '',
  }),
  keygen: FBDTKeygenGeneric,
});

export const Z_DTService_Offer = schema<DTService_Offer>()(
  z
    .object({
      title: z.string(),
      templateLink: Z_ServiceTemplateLink,
      price: Z_Money,
      enabled: z.boolean(),
      tenantLink: Z_TenantLink,
      addOnServices: z.array(z.string()).optional(),
    })
    .merge(Z_NoSqlDoc)
);

export type ServiceOfferLink = string;
export const Z_ServiceOfferLink = z.string();
