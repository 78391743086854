import {
  formatAddress,
  formatPhoneNumber,
  formatUnixTime,
  getCurrencyFormat,
  LabelledItemList,
  LabelledItemShape,
  renderCountryFormattedAddress,
  renderFormattedAddress,
  renderShortFormattedAddress,
  renderStateFormattedAddress,
} from '@rabbit/elements/shared-components';
import {
  Address,
  DTConsumer_Private,
  DTHoldingProxy,
  SELF_REGISTERED_VENDABLE,
} from '@rabbit/data/types';
import { calculateTotalSpent } from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';
import {
  getFirstName,
  getLastName,
} from 'libs/bizproc/react/src/shared/helpers';
import { useContext } from 'react';
import Divider from '@mui/material/Divider';
import { AppContext } from '@rabbit/app-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BusinessDetailsContentSectionProps {
  consumer_private: DTConsumer_Private;
  holdingsList: DTHoldingProxy[];
  favouriteStore?: string;
}

export function BusinessDetailsContentSection(
  props: BusinessDetailsContentSectionProps
) {
  const { t } = useTranslation();
  const { consumer_private, holdingsList, favouriteStore } = props || {};
  const { config } = useContext(AppContext);

  const firstName =
    consumer_private.splitname?.first ??
    (consumer_private.fullname && consumer_private.fullname.indexOf('@') === -1)
      ? getFirstName(consumer_private.fullname)
      : '';

  const lastName =
    consumer_private.splitname?.last ??
    (consumer_private.fullname && consumer_private.fullname.indexOf('@') === -1)
      ? getLastName(consumer_private.fullname)
      : '';

  //if (!consumer_private || isLoading) return <LoadingSpinner size="xs" />;

  const mostRecentPurchaseDate = getMostRecentPurchaseDate(holdingsList);
  const carTenants =
    t('tenantLink') === 'WARRANTYIRELAND' ||
    t('tenantLink') === 'PINNACLEWARRANTIES' ||
    t('tenantLink') === 'NUCOVER';

  const totalSpent = carTenants ? 0 : calculateTotalSpent(holdingsList);
  const preferredMethodOfCommunication = !consumer_private
    ?.preferred_contact?.[0]
    ? '-'
    : consumer_private?.preferred_contact.join(', ');

  const numberOfPurchases = holdingsList
    ?.map((holding: DTHoldingProxy) => holding?.vendable)
    .filter((holding) => holding !== SELF_REGISTERED_VENDABLE).length;

  const businessItems: LabelledItemShape[] = [];

  if (consumer_private.consumer_type === 'corporate') {
    businessItems.push(
      {
        label: t('general.businessName'),
        value: `${consumer_private.business_name ?? '-'}`,
      },
      {
        label: t('general.taxId'),
        value: `${consumer_private.tax_id ?? '-'}`,
      },
      {
        label: t('general.address'),
        value: renderShortFormattedAddress(
          (consumer_private?.address as Address[]).find((i) => i.isDefault) ||
            (consumer_private?.address || [])[0]
        ),
      },
      {
        label: t('general.cityAndState'),
        value: renderStateFormattedAddress(
          (consumer_private?.address as Address[]).find((i) => i.isDefault) ||
            (consumer_private?.address || [])[0]
        ),
      },
      {
        label: t('general.country'),
        value: renderCountryFormattedAddress(
          (consumer_private?.address as Address[]).find((i) => i.isDefault) ||
            (consumer_private?.address || [])[0]
        ),
      }
    );
  }

  const items: LabelledItemShape[] = [
    {
      label: t('general.firstName'),
      value: `${firstName}`,
    },
    {
      label: t('general.lastName'),
      value: `${lastName}`,
    },
    {
      label: t('general.email'),
      value: consumer_private?.email,
    },
    {
      label: t('general.phoneNumber'),
      value: formatPhoneNumber(consumer_private?.phone),
    },
  ];

  const secondaryCustomerDetails = [];
  if (consumer_private?.consumer_type === 'corporate') {
    secondaryCustomerDetails.push(
      {
        label: t('general.firstName'),
        value: consumer_private?.secondary_first_name || '-',
      },
      {
        label: t('general.lastName'),
        value: consumer_private?.secondary_last_name || '-',
      },
      {
        label: t('general.email'),
        value: consumer_private?.secondary_email || '-',
      },
      {
        label: t('general.phoneNumber'),
        value: formatPhoneNumber(consumer_private?.secondary_phone_number),
      }
    );
  }

  const generalItems = [];

  if (!carTenants) {
    generalItems.push(
      ...[
        {
          label: t('general.numberOfPurchases'),
          value: `${numberOfPurchases}`,
        },
        {
          label: t('general.mostRecentPurchaseDate'),
          value: `${mostRecentPurchaseDate}`,
        },
        {
          label: t('general.preferredMethodOfCommunication'),
          value: `${preferredMethodOfCommunication}`,
        },
        {
          label: t('general.totalSpent'),
          value: totalSpent,
        },
      ]
    );
  }

  return (
    <>
      <div className="font-nunito">
        {/*
        <div className="mb-5 text-lg">Business details</div>
*/}
        {consumer_private && <LabelledItemList items={businessItems} />}
      </div>

      <div className="font-nunito mt-5 mb-5">
        <LabelledItemList items={generalItems} />
      </div>
      <Divider />
      <div
        className={`font-nunito mb-5 ${
          consumer_private?.consumer_type === 'corporate' ? 'mt-5' : ''
        }`}
      >
        <div className="text-md mb-5  font-light">
          {t('general.primaryContactDetails')}
        </div>
        {consumer_private && <LabelledItemList items={items} />}
      </div>
      <Divider />
      <div className="font-nunito mt-5">
        <div className="text-md mb-5 font-light">
          {t('general.secondaryContactDetails')}
        </div>
        <LabelledItemList items={secondaryCustomerDetails} />
      </div>
    </>
  );
}

export default BusinessDetailsContentSection;

const getMostRecentPurchaseDate = (holdingsList: DTHoldingProxy[]) => {
  let mostRecentDate = 0;
  holdingsList.forEach((holding: DTHoldingProxy) => {
    if (holding?.purchase_time > mostRecentDate)
      mostRecentDate = holding?.purchase_time;
  });
  if (mostRecentDate <= 0) return '-';
  return formatUnixTime(mostRecentDate, 'dd/MM/yyyy');
};
