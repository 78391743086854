import { AppContext } from '@rabbit/app-context';
import { Warranty } from '@rabbit/data/types';
import {
  getClaimLimit,
  getFormattedDate,
  getIncreasedLabourRate,
  Heading,
} from '@rabbit/elements/shared-components';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface NewClaimVehicleChooseWarrantyProps {
  handleClose: () => void;
  onChange: any; //TODO
  setIsLoading: (loading: boolean) => void;
  data: any;
}

export function NewClaimVehicleChooseWarranty(
  props: NewClaimVehicleChooseWarrantyProps
) {
  const { onChange, data } = props;
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';

  return (
    <div className="flex flex-col gap-4 px-5 pt-4">
      <Heading kind="h4">{t('Choose warranty')}</Heading>
      <div className="flex flex-col gap-2">
        {data.warranties
          .filter((i: Warranty) => i.status !== 'voided')
          .map((warranty: Warranty) => {
            const labourRate = getIncreasedLabourRate(
              warranty,
              tenantInfo?.currency ?? 'GBP'
            );
            let expiredWarning = false;
            if (warranty && warranty?.endDate) {
              const expiryDate = new Date(warranty?.endDate);
              const today = new Date();
              if (expiryDate < today) {
                expiredWarning = true;
              }
            }
            return (
              <div
                className="font-nunito flex cursor-pointer flex-col gap-4 rounded-md border border-gray-300 p-4 hover:bg-gray-100"
                key={warranty.warrantyLink}
                onClick={() => {
                  onChange(1, { ...data, selectedWarranty: warranty });
                }}
              >
                <div className="flex gap-2">
                  <span className="text-lg font-semibold text-black">
                    {warranty?.templateTitle}
                  </span>
                  {expiredWarning && (
                    <span className="flex items-center rounded bg-red-100 py-1 px-2 text-xs capitalize text-red-700">
                      Expired
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <div>
                    <span className="text-gray-500">{t('Start date')}:</span>{' '}
                    {getFormattedDate(warranty?.startDate)}
                  </div>
                  <div>
                    <span className="text-gray-500">{t('Term')}:</span>{' '}
                    {(warranty?.decider?.stipulated?.duration as any).label}
                  </div>
                  <div>
                    <span className="text-gray-500">{t('Claim limit')}:</span>{' '}
                    {getClaimLimit(
                      warranty,
                      tenantInfo?.currency,
                      CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT
                    )}
                  </div>
                  {labourRate?.length ? (
                    <div>
                      <span className="text-gray-500">{t('Labour rate')}:</span>{' '}
                      {labourRate}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
