import { useContext } from 'react';
import {
  CardWrapperWithHeader,
  Chat,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { CaseFlowCaseEditingID } from '@rabbit/bizproc/react';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { UserContext } from '@rabbit/sage/context/UserContext';
import { t } from 'i18next';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
} from '@rabbit/data/types';

import {
  CaseFlowStations_Fatbikes,
  CaseflowActorTypes,
  CaseflowCaseTypes,
} from '@rabbit/bizproc/core';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { AppContext } from '@rabbit/app-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimChatLogProps {}

export function ClaimChatLog(props: ClaimChatLogProps) {
  const context = useContext(CaseflowContext);
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const { config, tenantInfo } = useContext(AppContext) || {};
  const operatingPersonas = config.CLAIMS.OPERATING_PERSONAS;

  const rootTenantRepairer = getRootPersonaFromLexicon(
    t(PrincipalsFieldName),
    PersonaTypeSingleLetter.Repairer
  );
  // @TEST_REMOVE_LATER, as the principals field name wasn't updated locally even after running mixmaster...
  const rootTenantWarrantor =
    PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + tenantLink;
  // const rootTenantWarrantor = getRootPersonaFromLexicon(
  //   t(PrincipalsFieldName),
  //   PersonaTypeSingleLetter.Warrantor
  // );

  const {
    caseFlowCase,
    chatLog,
    caseId,
    operatingPersona,
    operatingPersonaSingleKey,
    sendChatMessage,
    getMyChatLastSeen,
    updateChatLastSeen,
    caseState,
    caseFacts,
    caseActorContext,
  } = context || {};

  if (
    !rootTenantRepairer ||
    !context ||
    !caseFlowCase ||
    !chatLog ||
    !caseId ||
    !operatingPersona ||
    !sendChatMessage ||
    !getMyChatLastSeen ||
    !updateChatLastSeen ||
    !caseState ||
    !caseActorContext
  )
    return <LoadingSpinner size="xs" />;

  // TODO: try to this move to new user config, somehow
  const getCasePrincipalPersona = (tenantLink: string) => {
    const isAdmin = userContext?.accessToken?.admin;
    const { warrantorPersona, repairerPersona } = useGetMySagePersonas();

    if (tenantLink === 'FATBIKES') {
      if (isAdmin) {
        return `${PersonaTypeSingleLetter.Warrantor}${PersonaIdTypeSplitter}${tenantLink}`;
      } else if (
        operatingPersonas?.includes(PersonaTypeSingleLetter.Warrantor) &&
        warrantorPersona?.personaId
      ) {
        // Use rootTenantWarrantor if the user is a Fatbikes admin
        return rootTenantWarrantor;
      } else if (
        operatingPersonas?.includes(PersonaTypeSingleLetter.Repairer) &&
        repairerPersona?.personaId
      ) {
        // Use Repairer persona for authorized repairer and if not a Admin
        return repairerPersona.personaId;
      }
    } else if (tenantLink === 'SHELTA' && repairerPersona?.personaId) {
      if (isAdmin) {
        return `${PersonaTypeSingleLetter.Repairer}${PersonaIdTypeSplitter}${tenantLink}`;
      }
      return repairerPersona.personaId;
    }
    return rootTenantWarrantor;
  };

  const id: CaseFlowCaseEditingID = {
    type: 'cfc' as const,
    case: caseId,
    operating_persona: operatingPersona,
    principal_persona: getCasePrincipalPersona(tenantLink),
    isNewCase: false,
    isAdmin: userContext?.accessToken?.admin ?? false,
  };

  // Yes this is awful - todo improve
  const currentPersonaType =
    operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer
      ? CaseflowActorTypes.REPAIRER
      : operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor
      ? CaseflowActorTypes.WARRANTOR
      : null;

  // Determine if chat should be read-only for warrantor,
  // Chat becomes readonly once repairer is assigned OR case state is `Closed`
  const isChatReadOnly =
    (config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES &&
      currentPersonaType === CaseflowActorTypes.WARRANTOR &&
      ![
        CaseFlowStations_Fatbikes.BIRTH,
        CaseFlowStations_Fatbikes.PRELIMINARY_ASSESSMENT,
      ].includes(caseState)) ||
    caseState === CaseFlowStations_Fatbikes.CLOSED;

  let finalOperatingPersona = operatingPersona;
  if (tenantLink === 'SHELTA') {
    finalOperatingPersona = operatingPersona.replace(
      PersonaTypeSingleLetter.Warrantor,
      PersonaTypeSingleLetter.Repairer
    );
  }
  const actors = caseFlowCase.GetActors();
  const isChatActor = Object.values(actors).includes(finalOperatingPersona);

  return (
    isChatActor && (
      <CardWrapperWithHeader extraStyles="!grow flex flex-col" noPadding>
        <Chat
          activePersona={operatingPersona}
          id={id}
          tenantInfo={tenantInfo}
          isReadOnly={isChatReadOnly}
          caseFlowContext={{
            caseFacts,
            caseFlowCase,
            caseId,
          }}
        />
      </CardWrapperWithHeader>
    )
  );
}

export default ClaimChatLog;
