import { ClockIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { AppContext } from '@rabbit/app-context';
import {
  DTHoldingProxy,
  SELF_REGISTERED_VENDABLE,
  TenantInfo,
  WarrantyStatus,
} from '@rabbit/data/types';
import {
  ButtonIcon,
  FiltersModal,
  PillTime,
  Table,
  getClaimLimit,
  getCurrencyFormat,
  getWarrantyTerm,
  timestampToTimeAgo,
} from '@rabbit/elements/shared-components';
import { CreateTable } from '@rabbit/sage/utils/CreateTable';
import { RegistrationTableColumns } from '@rabbit/sage/utils/RegistrationTableColumns';
import {
  getProductTitleFromHoldingData,
  getWarrantyRemainingByDate,
  isValidDate,
} from '@rabbit/sage/utils/helpers';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { Config_RegistrationTableType } from 'libs/configs/src/types/config-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetMySagePersonas } from '@rabbit/data/portal';

export interface TableRegistrationsProps {
  columns?: any;
  isLoading?: boolean;
  tenantType?: string;
  holdingList?: any;
  filters: FormValues;
  onFilter?: (value: FormValues) => void;
  isRegularTenant?: boolean;
}

export interface FormValues {
  voided?: boolean;
  dateType?: string;
  beginDate?: Date | undefined;
  endDate?: Date | undefined;
}

function TableRegistrations({
  isLoading,
  tenantType,
  holdingList,
  filters,
  onFilter,
  isRegularTenant = false,
}: TableRegistrationsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const { config, tenantInfo } = useContext(AppContext);
  const { warrantorPersona } = useGetMySagePersonas();
  const tenantLink = t('tenantLink');

  const isWarrantor = warrantorPersona !== null;

  const columns = useMemo(() => RegistrationTableColumns.Default(t), [t]);
  const carColumns = useMemo(() => RegistrationTableColumns.CarColumns, []);
  const tableTopColumns = useMemo(() => RegistrationTableColumns.TableTop(t, isWarrantor), [t, isWarrantor]);


  const getColumns = () => {
    switch (tenantType) {
      case 'carTenants':
        {
          /* // Price has been temporarily removed per DEV-1128 request -VP */
        }
        if (tenantLink === 'WARRANTYIRELAND') {
          return isRegularTenant
            ? carColumns.filter(
              (column: any) =>
                column.accessorKey !== 'partner' &&
                column.accessorKey !== 'price'
            )
            : carColumns.filter(
              (column: any) =>
                column.accessorKey !== 'salesPerson' &&
                column.accessorKey !== 'price'
            );
        } else {
          return isRegularTenant
            ? carColumns.filter(
              (column: any) => column.accessorKey !== 'partner'
            )
            : carColumns.filter(
              (column: any) => column.accessorKey !== 'salesPerson'
            );
        }
      case 'tableTenants':
        return isRegularTenant
          ? tableTopColumns.filter(
            (column: any) => column.accessorKey !== 'partner'
          )
          : tableTopColumns;
      default:
        return isRegularTenant
          ? columns.filter((column: any) => column.accessorKey !== 'partner')
          : columns;
    }
  };

  const onChangeFilters = (value: {
    beginDate?: Date | undefined;
    endDate?: Date | undefined;
  }) => {
    setIsFilterModalOpen(false);
    if (onFilter) {
      onFilter(value);
    }
  };

  const BikeRegistrationTable = useMemo(
    () =>
      CreateTable<DTHoldingProxy>(holdingList.data || [], [
        {
          header: 'Timestamp',
          Header: <ClockIcon className="h-4 w-4" />,
          Cell: ({ cell }: any) => {
            return (
              <PillTime
                label={
                  cell.getValue() ? timestampToTimeAgo(cell.getValue()) : ''
                }
                className={'bg-gray-300 text-gray-900'}
              />
            );
          },
          value: (holding) => holding.tcreate,
        },
        ...(config.HOLDINGS.REGISTRATION_DETAILS_VIEW.COMPANY_NAME
          ? [
            {
              header: t('general.businessName'),
              value: (holding: any) => holding.business_name ?? '-',
            },
          ]
          : []),
        {
          header: t('general.id'),
          value: (holding) => holding.docid?.slice(0, 5).toUpperCase(),
        },
        {
          header: t('general.customerName'),
          value: (holding) => holding.consumer_name,
        },
        {
          header: t('general.product'),
          primary: true,
          value: (holding) => getProductTitleFromHoldingData(holding),
        },
        {
          header: t('general.modelNumber'),
          value: (holding) =>
            holding?.mpn && holding?.mpn !== 'n/a' ? holding.mpn : '-',
        },
        {
          header: t('general.purchaseDate'),
          type: 'Date',
          value: (holding) => holding.purchase_time || '-',
        },
        {
          header: t('general.registered'),
          type: 'Date',
          value: (holding) => holding.register_time || '-',
        },
        ...(config.HOLDINGS.REGISTRATION_DETAILS_VIEW.PURCHASE_PRICE
          ? [
            {
              header: t('general.purchasePrice'),
              value: (holding: any) =>
                holding.decider?.decided?.totalPrice
                  ? getCurrencyFormat(
                    holding.decider?.decided?.totalPrice as number,
                    tenantInfo?.currency as string
                  )
                  : '-',
            },
          ]
          : []),
        {
          header: t('general.expiring'),
          type: 'Date',
          value: (holding) =>
            BL_Warranty.getLatestWarranty(holding.warranties)?.endDate || '-',
        },
        {
          header: t('general.termLeft'),
          value: (holding) => {
            const warranty = BL_Warranty.getLatestWarranty(holding.warranties);
            return warranty?.endDate && warranty.startDate
              ? getWarrantyRemainingByDate(
                warranty?.endDate,
                warranty?.startDate
              )
              : '-';
          },
        },
      ]),
    [holdingList.data, t]
  );

  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';

  useEffect(() => {
    if (!isLoading && holdingList.data) {
      const getTableData = () => {
        switch (tenantType) {
          case 'carTenants':
            return formatCarResults(
              holdingList?.data,
              CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT,
              tenantInfo
            ).filter((i) => i !== null);
          case 'tableTenants':
            return formatTableCoverResults(holdingList?.data, tenantInfo).filter(
              (i) => i !== null
            );
          default:
            return formatResults(holdingList?.data).filter((i) => i !== null);
        }
      };
      setTableData(getTableData());
    }
  }, [holdingList.data, isLoading, t]);

  return (
    <div className="w-full">
      <div className="relative z-10 flex w-full items-center justify-between">
        <div className="absolute top-[25px] right-5">
          {!isLoading && (
            <ButtonIcon
              label={t('general.filters')}
              iconLeft={true}
              Icon={FunnelIcon}
              onClick={() => setIsFilterModalOpen(true)}
              kind={'bgLightGreen'}
              count={
                (filters && filters.beginDate) || (filters && filters.endDate)
                  ? 1
                  : 0
              }
            />
          )}
        </div>
        {isFilterModalOpen && (
          <div className="absolute right-0 top-16 z-10">
            <FiltersModal
              page={'registrations'}
              handleClose={() => setIsFilterModalOpen(false)}
              data={filters}
              onChange={onChangeFilters}
            />
          </div>
        )}
      </div>
      {config.HOLDINGS.REGISTRATION.TABLE_TYPE ===
        Config_RegistrationTableType.BIKE_TYPE && (
          <div className="relative z-0">
            <BikeRegistrationTable
              initialState={{
                columnPinning: { right: ['actions'] },
                showGlobalFilter: true,
                sorting: [
                  {
                    id: 'timestamp',
                    desc: true,
                  },
                ],
              }}
              muiSearchTextFieldProps={{
                placeholder: t('general.searchByRegistrationID'),
              }}
              state={{
                isLoading: isLoading,
              }}
              onClickRow={(holding) => {
                navigate(SAGE_ROUTE_NAME.REGISTRATIONS + '/' + holding.docid, {
                  state: {
                    warrantyId: BL_Warranty.getLatestWarranty(holding.warranties)
                      ?.warrantyLink,
                    title: getProductTitleFromHoldingData(holding),
                    link: holding.docid,
                  },
                });
              }}
            />
          </div>
        )}
      {config.HOLDINGS.REGISTRATION.TABLE_TYPE ===
        Config_RegistrationTableType.DEFAULT && (
          <div className="relative z-0">
            <Table
              columns={getColumns()}
              data={tableData}
              initialState={{
                columnPinning: { right: ['actions'] },
                showGlobalFilter: true,
                sorting: [
                  {
                    id: 'timeStamp',
                    desc: false,
                  },
                ],
              }}
              muiSearchTextFieldProps={{
                placeholder: t('general.searchByRegistrationID'),
              }}
              useCallbackClickRow={(data: {
                warrantyId: string;
                product: string;
                registrationPlate: string;
                make: string;
                link: string;
                id: string;
              }) =>
                navigate(SAGE_ROUTE_NAME.REGISTRATIONS + '/' + data.link, {
                  state: {
                    warrantyId:
                      tenantType === 'tableTenants' ? data.id : data.warrantyId,
                    title:
                      tenantType === 'carTenants'
                        ? data.make + ' ' + data.registrationPlate
                        : data.product,
                    link: data.link,
                  },
                })
              }
              state={{
                isLoading: isLoading,
              }}
            />
          </div>
        )}
    </div>
  );
}

export default TableRegistrations;

/** HELPERS **/

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const formatResults = (results: any[]) => {
  const formattedResults = results.map((result, i) => {
    if (result.vendable === SELF_REGISTERED_VENDABLE) return null;
    const warrantyInfo = BL_Warranty.getLatestWarranty(result.warranties);
    return {
      timeStamp: (
        <PillTime
          label={
            result.register_time
              ? timestampToTimeAgo(result?.register_time)
              : ''
          }
          className={'bg-gray-300 text-gray-900'}
        />
      ),
      warrantyId: result.docid.slice(0, 5).toUpperCase(),
      registered: isValidDate(new Date(result?.register_time))
        ? result.register_time
        : undefined,
      expiring: warrantyInfo?.endDate,
      term: getWarrantyTerm(result),
      product: getProductTitleFromHoldingData(result),
      modelNum: result?.mpn && result?.mpn !== 'n/a' ? result.mpn : '-',
      serialNum: result.serial ? result.serial : '-',
      purchaseDate:
        result?.purchase_time && result?.purchase_time > 0
          ? result?.purchase_time
          : undefined,
      extended: 'No', //TODO
      // actions: (
      //   <div className="flex items-center justify-center">
      //     <TrashIcon
      //       className="w-5 h-5 cursor-pointer"
      //       onClick={() => {
      //         setdeleteRegistration(!deleteRegistration);
      //         // onDeleteRegistration(result.docid);
      //       }}
      //     />
      //   </div>
      // ),
      link: result.docid,
    };
  });
  return formattedResults;
};

const formatCarResults = (
  results: any[],
  CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT: boolean,
  tenantInfo?: TenantInfo
) => {
  return results.map((result, i) => {
    const vehicleInfo =
      result.self_registration?.srvInfo?.productInfo ??
      result.self_registration?.vehicleInfo; // TODO: make it more flexible for other tenants as part of DEV-528
    const warrantyInfo = BL_Warranty.getLatestWarranty(result.warranties);
    const voided = warrantyInfo?.status === WarrantyStatus.VOIDED;
    return {
      timeStamp: (
        <PillTime
          label={
            result.tcreate
              ? timestampToTimeAgo(new Date(result?.tcreate).getTime())
              : ''
          }
          className={'bg-gray-300 text-gray-900'}
        />
      ),
      warrantyId: result.docid.slice(0, 5).toUpperCase(),
      registrationPlate: BL_Warranty.getVehicleRegistrationNo(vehicleInfo),
      make: vehicleInfo.brand || '-',
      registered: result.tcreate,
      start: warrantyInfo?.startDate,
      expiring: warrantyInfo?.endDate,
      warrantyPlan: voided ? 'voided' : warrantyInfo?.templateTitle || '-',
      term: voided ? '-' : getWarrantyTerm(result),
      claimLimit: voided
        ? '-'
        : getClaimLimit(
          warrantyInfo,
          tenantInfo?.currency,
          CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT
        ) || '-',
      partner: warrantyInfo?.retailerName,
      salesPerson: warrantyInfo?.salesRep ?? '',
      price:
        result.purchase_price && warrantyInfo?.price
          ? getCurrencyFormat(
            warrantyInfo?.price?.amount,
            warrantyInfo?.price?.currency
          ) +
          ` (${getCurrencyFormat(
            warrantyInfo?.price?.amount * 1.2,
            warrantyInfo?.price?.currency
          )} incl. VAT)`
          : '-',
      link: result.docid,
      customerName: result.consumer_name || '-',
    };
  });
};

const formatTableCoverResults = (results: any[], tenantInfo: any) =>
  results.map((result, i) => {
    const warrantyInfo = BL_Warranty.getLatestWarranty(result.warranties);
    const productInfo = result.self_registration.srvInfo.productInfo;
    const voided = warrantyInfo?.status === WarrantyStatus.VOIDED;

    const purchasePrice = warrantyInfo?.decider?.decided?.totalPrice ?? 0;

    return {
      timeStamp: (
        <PillTime
          label={
            result.created_time
              ? timestampToTimeAgo(new Date(result?.created_time).getTime())
              : ''
          }
          className={'bg-gray-300 text-gray-900'}
        />
      ),
      id: result.docid.slice(0, 5).toUpperCase(),
      product: productInfo.typeofStone,
      area: productInfo.surfaceAreaValue
        ? productInfo.surfaceAreaValue + 'm²'
        : 'N/A',
      registered: result.tcreate,
      start: warrantyInfo?.startDate,
      expiring: warrantyInfo?.endDate,
      warrantyPlan: voided
        ? 'voided'
        : warrantyInfo?.templateTitle?.replace(' warranty', '') || '-',
      term: voided ? '-' : getWarrantyTerm(result),
      partner: warrantyInfo?.retailerName,
      salesperson: warrantyInfo?.salesRep ?? '-',
      purchasePrice: purchasePrice
        ? getCurrencyFormat(
          purchasePrice as number,
          tenantInfo?.currency
        ) : '0',
      link: result.docid,
      customerName: result.consumer_name || '-',
      sealing: productInfo.upsellSealing ? 'Yes' : 'No',
    };
  });
