import { TrashIcon } from '@heroicons/react/24/solid';
import { useManageFaults } from '@rabbit/bizproc/react';
import { Button, Input } from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export interface ModalAddEditFaultProps {
  handleClose: () => void;
  // Note: HandleSubmit is probably not necessary here - review
  handleSubmit?: () => void;
  // Note: same with handleDelete?
  handleDelete?: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  itemDetails?: any; // todo type
  mode: 'edit' | 'new';
}

interface FormDataShape {
  fault: string;
  //fault_category: string[];
  details?: string;
}

const schema = Yup.object({
  fault: Yup.string()
    .trim()
    .required(i18next.t('message.faultNameCantBeEmpty')),
  // fault_category: Yup.string().trim().required('Required'),
  details: Yup.string().trim(),
});

export function ModalAddEditFault({
  handleClose,
  itemDetails,
  mode,
  setLoading,
}: ModalAddEditFaultProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createFault, editFault, deleteFault } = useManageFaults(
    itemDetails?.docid
  );

  const { t } = useTranslation();

  const initialValues: FormDataShape = {
    fault: itemDetails ? itemDetails.title : '',
    // fault_category: todo
    details: itemDetails ? itemDetails.description : '',
  };

  const handleSubmit = async (values: FormDataShape) => {
    setIsSubmitting(true);
    setLoading(true);
    try {
      if (mode === 'edit') {
        await editFault({
          title: values.fault ?? '',
          // category: values.fault_category ?? [], // todo fix the type on this key later
          description: values.details ?? '',
        });
      } else if (mode === 'new') {
        await createFault({
          title: values.fault ?? '',
          // category: values.fault_category ?? [], // todo fix the type on this key later
          description: values.details ?? '',
        });
      } else {
        throw new Error(t('message.invalidMode'));
      }
      toast.success(t('message.faultSavedSuccessfully'));
    } catch (error) {
      console.log(error);
      toast.error(t('message.errorSavingFault'));
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      handleClose();
    }
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    setLoading(true);
    try {
      await deleteFault(itemDetails?.docid);
      toast.success(t('message.faultDeletedSuccessfully'));
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error(t('message.errorDeletingFault'));
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      handleClose();
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => (
          <Form>
            <div className="mt-4 flex gap-4 px-5 pb-2">
              <div className="flex-1">
                <Input
                  type="text"
                  label={`${t('general.fault')}*`}
                  name="fault"
                  settings={{
                    placeholder: t('general.faultName'),
                    hint: '*required',
                  }}
                />
              </div>
              {/* <div className="flex-1">
                <Input
                  type="select"
                  label="Fault Category*"
                  name="fault_category"
                  settings={{
                    options: FAULT_TYPE,
                    placeholder: 'Please select an option',
                    id: 'fault_category',
                    errors: props.errors,
                  }}
                />
              </div> */}
            </div>
            {/* <div className="py-2 px-5">
              <Input
                type="select"
                label="Product(s)*"
                name="products"
                settings={{
                  id: 'products',
                  options: options,
                  placeholder: 'Select',
                  isMulti: true,
                  errors: props.errors,
                }}
              />
            </div>
            <div className="py-2 px-5">
              <Input
                type="select"
                label="Parts*"
                name="parts"
                settings={{
                  options: options,
                  placeholder: 'Select',
                  id: 'parts',
                  isMulti: true,
                  errors: props.errors,
                }}
              />
            </div> */}
            <div className="py-2 px-5">
              <Input
                type="textarea"
                label="Details*"
                name="details"
                settings={{ placeholder: `${t('message.writeTextHere')}...` }}
              />
            </div>
            <div className="flex gap-5 py-4 px-5">
              <Button
                kind="primary"
                size="md"
                children={
                  mode === 'edit' ? t('general.saveAll') : t('general.save')
                }
                disabled={isSubmitting}
                type="submit"
              />
              {mode === 'edit' && (
                <Button
                  kind="red"
                  size="md"
                  icon={<TrashIcon />}
                  children={t('general.deleteFault')}
                  disabled={isSubmitting}
                  onClick={handleDelete}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
