import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { DateTime, Money, Z_Money } from '../base/basic';
import { TenantLink, Z_TenantLink } from './tenant';
import { z, schema, OurDateTime } from '@rabbit/utils/ts';
import { HoldingLink, SelfRegistration, Z_HoldingLink } from './holding';
import {
  ConsumerLink,
  RetailerLink,
  WarrantorLink,
  Z_ConsumerLink,
} from '../persona';
import { ServiceOfferLink, Z_ServiceOfferLink } from './service-offer';
import {
  DTService_Template,
  ServiceTemplateLink,
  ServiceTypes,
  Z_ServiceTemplateLink,
} from './service-template';
import { DeciderOutput } from '../decider/types';

export enum ServiceStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  SUSPENDED = 'suspended',
  CANCELLED = 'cancelled',
  VOIDED = 'voided',
  ARCHIVED = 'archived',
}

export type ServiceLink = string;

/** Represents a specific instance of a service selected by the user */
export interface DTService extends NoSqlDoc {
  // The type of service
  type: ServiceTypes;

  /** The warranty template for this warranty instance */
  templateLink: ServiceTemplateLink;

  /** The name of the template (or offer) used to generate this warranty instance */
  templateName: string;

  /** The warranty offer used to generate this warranty instance, if applicable */
  offerLink?: ServiceOfferLink | null;

  /** The holding this warranty instance is associated with */
  holdingLink: HoldingLink;

  /** The self-registration data for the holding associated with this warranty, as recorded when the holding and warranty were created */
  SRVSnapshot?: SelfRegistration | null;

  /** The consumer this warranty instance is associated with */
  consumerLink: ConsumerLink;

  /** The warrantor responsible for handling this warranty and any associated claims */
  warrantorLink: WarrantorLink;

  /** The retailer who sold this warranty and registered it */
  retailerLink?: RetailerLink;

  /** The (usually premium) tenant this warranty instance is associated with */
  tenantLink: TenantLink;

  /** A copy of warranty template for this warranty instance, as it was when this warranty instance was first created */
  snapshot: DTService_Template;

  /** The start date of the warranty instance */
  startDate: number;

  /** The end date of the warranty instance */
  endDate: number;

  /** The duration of the service, stored in ISO 8601 format */
  duration: string;

  /** The current status of the warranty */
  status: ServiceStatus;

  /** The price paid for this warranty, if applicable */
  price?: Money;

  /** When using Decider to determine a warranty, put the output here for future reference */
  decider?: DeciderOutput;

  /** Who last updated this vendable, TenantLink or PersonaLink */
  updatedBy: string;

  /** Timestamp when the warranty instance was registered. */
  registeredTime: DateTime;
}

export const FBD_Service = MakeFBDocType<DTService>({
  name: 'Service',
  collection: 'service',
  empty: () => {
    const result: DTService = {
      ...Empty_NoSqlDoc(),
      type:ServiceTypes.ROAD_SIDE_ASSISTANCE,
      templateLink: '',
      templateName: '',
      holdingLink: '',
      consumerLink: '',
      warrantorLink: '',
      tenantLink: '',
      snapshot: {} as DTService_Template, // A snapshot should be provided for the instance
      startDate: OurDateTime.nowUTCTimestamp(),
      endDate: OurDateTime.nowUTCTimestamp(),
      duration: '',
      status: ServiceStatus.ACTIVE, // Default status could be ACTIVE
      updatedBy: '',
      registeredTime: OurDateTime.nowUTCTimestamp(),
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

// Zod schema for ServiceTypes (assuming it's a string enum or similar)
export const Z_ServiceTypes = z.nativeEnum(ServiceTypes);

// Zod schema for ServiceStatus (assuming it's a string enum or similar)
export const Z_ServiceStatus = z.nativeEnum(ServiceStatus);


export const Z_DTService = schema<DTService>()(
  z
    .object({
      type: Z_ServiceTypes,
      templateLink: Z_ServiceTemplateLink,
      templateName: z.string(),
      offerLink: Z_ServiceOfferLink.optional(),
      holdingLink: Z_HoldingLink,
      SRVSnapshot: z.union([z.object({}), z.null()]).optional(),
      consumerLink: Z_ConsumerLink,
      warrantorLink: z.object({}).optional(), // Example for optional fields
      retailerLink: z.object({}).optional(),
      tenantLink: Z_TenantLink,
      snapshot: z.object({}), // Assuming `snapshot` is the complete template as it was when created
      startDate: z.number().default(OurDateTime.nowUTCTimestamp()),
      endDate: z.number().optional(),
      duration: z.string(),
      status: Z_ServiceStatus,
      price: Z_Money.optional(),
      decider: z.object({}).optional(),
      updatedBy: z.string(),
      registeredTime: z.string().default(new Date().toISOString()),
    })
    .merge(Z_NoSqlDoc) // This adds the NoSqlDoc fields (e.g., `_id`, `createdAt`, etc.)
);
