import { OptionShape, useGetRepairerPeers } from '@rabbit/bizproc/react';
import {
  Button,
  Modal,
  Input,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import {
  CaseflowContext,
  CaseflowInterface,
} from '@rabbit/sage/context/CaseflowContext';
import { titleCase } from '@rabbit/sage/utils/helpers';
import { Formik, Form } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { LogForms } from '../ClaimCostModal/ClaimCostModal';
import AdministrativeTimeForm from '../ClaimCostModal/forms/AdministrativeTimeForm';
import OtherForm from '../ClaimCostModal/forms/OtherForm';
import PartUsedForm from '../ClaimCostModal/forms/PartUsedForm';
import RepairTimeForm from '../ClaimCostModal/forms/RepairTimeForm';
import ShippingForm from '../ClaimCostModal/forms/ShippingForm';
import TravelForm from '../ClaimCostModal/forms/TravelForm';
import { LogClaimEditShape } from '@rabbit/sage/components/molecules/claims/ClaimCostsSection/ClaimCostsSection';
import { TrashIcon } from '@heroicons/react/24/solid';
import {
  CFCF_AdministrativeCostLog,
  CFCF_OtherCostLog,
  CFCF_PartsUsed,
  CFCF_RepairTimeLog,
  CFCF_ShippingCostLog,
  CFCF_TravelCostLog,
} from '@rabbit/bizproc/core';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { AppContext } from '@rabbit/app-context';

export default function EditClaimCostModal({
  data,
  index,
  name,
  remove,
  handleClose,
}: LogClaimEditShape & {
  handleClose: () => void;
}) {
  const caseFlow = useContext(CaseflowContext) || ({} as CaseflowInterface);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const [showInternalComment, setShowInternalComment] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>();
  const [confirmationModalSettings, setConfirmationModalSettings] = useState({
    text: 'You are about to delete this row. Are you sure you want to continue?',
    primaryButtonText: 'Yes',
    outlineButtonText: 'No, cancel',
    handleClose: () => setIsConfirmationModalOpen(false),
    handlePrimaryClick: async () => {
      // will set in the future from the form
    },
    handleOutlineClick: () => setIsConfirmationModalOpen(false),
  });
  const { config, tenantInfo } = useContext(AppContext);
  const { warrantorPersona, repairerPersona } = useGetMySagePersonas();
  if (!warrantorPersona?.personaId && !repairerPersona?.personaId) return null;
  const personaId =
    warrantorPersona?.personaId || repairerPersona?.personaId || '';

  const repairerUsers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : repairerPeer.map((repairer, index) => ({
        id: `${index}`,
        label: repairer?.name || '',
        value: repairer?.docid || '',
      }));

  const valueMap: Record<string, LogForms<any>> = {
    'administrative-time': AdministrativeTimeForm(
      tenantInfo,
      config,
      caseFlow,
      repairerUsers,
      personaId,
      { editMode: true, index }
    ),
    'parts-used': PartUsedForm(tenantInfo, config, caseFlow, personaId, {
      editMode: true,
      index,
    }),
    'repair-time': RepairTimeForm(
      tenantInfo,
      caseFlow,
      repairerUsers,
      personaId,
      {
        editMode: true,
        index,
      }
    ),
    shipping: ShippingForm(tenantInfo, config, caseFlow, personaId, {
      editMode: true,
      index,
    }),
    travel: TravelForm(tenantInfo, config, caseFlow, personaId, {
      editMode: true,
      index,
    }),
    other: OtherForm(tenantInfo, caseFlow, personaId, {
      editMode: true,
      index,
    }),
  };

  const selectedForm = valueMap[name];

  const onSubmit = (values: any) => {
    if (!selectedForm) return;
    setIsSubmitting(true);
    selectedForm.onSubmit(values, () => {
      setIsSubmitting(false);
      handleClose();
    });
  };

  const onDelete = () => {
    if (!selectedForm) return;
    setConfirmationModalSettings((setting) => ({
      ...setting,
      handlePrimaryClick: async () => {
        setIsConfirmationModalOpen(false);
        setIsSubmitting(true);
        selectedForm.onDelete(index, () => {
          setIsSubmitting(false);
          handleClose();
        });
      },
    }));
    setIsConfirmationModalOpen(true);
  };

  useEffect(() => {
    if (remove) onDelete();
  }, []);

  let initialValues: any = getFieldValues(name, data);

  return (
    <>
      <Modal
        settings={{
          title: `Edit - ${titleCase(name).replace('-', ' ')}`,
          handleClose,
        }}
        className="w-full max-w-[1024px]"
        kind="generic"
        isLoading={isSubmitting}
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues || selectedForm?.initialValues || {}}
          validationSchema={selectedForm?.schema}
          enableReinitialize
        >
          {(props) => {
            return (
              <Form className="mt-4 flex w-full flex-col px-4">
                {selectedForm && (
                  <div className="flex flex-col gap-4 rounded-lg border border-gray-300 p-4">
                    {
                      // Selected form is displayed here
                      // Depending on the state change
                    }
                    {<selectedForm.Form {...props} values={initialValues} />}

                    {!showInternalComment && (
                      <div className="mt-4">
                        <Button
                          kind="outline"
                          type="button"
                          className="w-full"
                          onClick={() => setShowInternalComment(true)}
                        >
                          Add internal comment
                        </Button>
                      </div>
                    )}
                    {showInternalComment && (
                      <div className="mt-3">
                        <Input
                          type="rich-text"
                          label="Internal comment"
                          name="internal_comment"
                          settings={{
                            id: 'internal_comment',
                            allowSpecialCharacter: true,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}

                <div className="mt-4 flex gap-3 text-white">
                  <Button
                    kind="primary"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !selectedForm ||
                      Object.keys(props.errors).length > 0
                    }
                  >
                    Save
                  </Button>
                  <Button
                    kind="red"
                    className=""
                    icon={<TrashIcon className="text-white" />}
                    disabled={
                      isSubmitting ||
                      !selectedForm ||
                      Object.keys(props.errors).length > 0
                    }
                    onClick={onDelete}
                  >
                    Delete
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={'pop-up'}
          settings={confirmationModalSettings}
        />
      )}
    </>
  );
}

function getFieldValues(name: LogForms<any>['name'], data: any) {
  let initialValues: any = {};
  if (name === 'shipping') {
    const values = data as CFCF_ShippingCostLog;
    initialValues = { ...values };
  }
  if (name === 'administrative-time') {
    const values = data as CFCF_AdministrativeCostLog;
    initialValues = { ...values };
  }
  if (name === 'parts-used') {
    const values = data as CFCF_PartsUsed[0];
    initialValues = {
      parts: [values],
    };
  }
  if (name === 'repair-time') {
    const values = data as CFCF_RepairTimeLog;
    initialValues = { ...values };
  }
  if (name === 'travel') {
    const values = data as CFCF_TravelCostLog;
    initialValues = { ...values };
  }
  if (name === 'other') {
    const values = data as CFCF_OtherCostLog;
    initialValues = { ...values };
  }
  return initialValues;
}
