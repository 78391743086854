import { useEffect, useState } from 'react';
import { ButtonIcon, Modal, Table } from '@rabbit/elements/shared-components';
import ModalAddEditItem, {
  ModalAddEditItemKind,
  ModalAddEditItemMode,
} from '../../organisms/ModalNewEditProduct/ModalAddEditItem';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useManageFaults } from '@rabbit/bizproc/react';
import { t } from 'i18next';

export const tableheaders = (t: any) => [
  {
    header: t('general.fault'),
    accessorKey: 'fault',
    maxSize: 100,
    muiTableBodyCellProps: {
      className: '',
    },
  },
  {
    header: t('general.faultCategory'),
    accessorKey: 'faultCategory',
  },
  {
    title: 'actions',
    header: '',
    accessorKey: 'actions',
    size: 15,
  },
];

export default function FaultsTab() {
  const { faultList } = useManageFaults();
  const faultListData = faultList?.data;
  const [formattedFaults, setFormattedFaults] = useState<any[]>([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<ModalAddEditItemMode>(
    ModalAddEditItemMode.new
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState<any>();

  const handleEditModal = (itemDetails?: any) => {
    setItemDetails(itemDetails || null);
    setModalMode(
      itemDetails ? ModalAddEditItemMode.edit : ModalAddEditItemMode.new
    );
    setEditModalOpen(true);
  };

  useEffect(() => {
    if (faultListData) {
      const formattedFaultsArr = faultListData.map((fault) => {
        return {
          docid: fault.docid,
          fault: fault.title,
          faultCategory: fault.category?.[0] ?? '-',
          details: fault.description,
          actions: (
            <div className="text-center">
              <ButtonIcon
                label={t('general.editItem')}
                kind={'bgLightGreen'}
                iconLeft={true}
                Icon={PencilSquareIcon}
                onClick={() => handleEditModal(fault)}
              />
            </div>
          ),
        };
      });
      setFormattedFaults(formattedFaultsArr);
    } else {
      setFormattedFaults([]);
    }
  }, [faultListData]);

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: t('message.confirmDeleteFault'),
      primaryButtonText: t('general.yes'),
      outlineButtonText: t('general.cancel'),
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  return (
    <div className="flex w-full flex-col">
      <div className="relative flex w-full items-center justify-between py-4">
        <div className="absolute right-5 top-[60px] flex w-full justify-end space-x-4">
          <div className="relative z-10">
            {/* // Disabled until fault categories are implemented */}
            {/* <div>
              <ButtonIcon
                label={'Filters'}
                iconLeft={true}
                Icon={FunnelIcon}
                kind={'bgLightGreen'}
                onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
              />
            </div> */}
            {/* {isFilterModalOpen && (
              <div className="absolute right-0 top-10 z-20">
                <ProductsAndPartsFilters
                  title={'Fault category'}
                  items={faultsFilters}
                  onChange={() => void 0}
                  activeCategory={'faults'}
                />
              </div>
            )} */}
          </div>
          <div className="z-10">
            <ButtonIcon
              label={t('general.newFault')}
              Icon={PlusIcon}
              iconLeft={true}
              onClick={() => handleEditModal()}
            />
          </div>
        </div>
      </div>
      <Table
        columns={tableheaders(t)}
        data={formattedFaults}
        initialState={{ showGlobalFilter: true }}
        state={{
          isLoading: faultList.isLoading,
        }}
        muiSearchTextFieldProps={{
          placeholder: t('message.searchByFaultOrCategory'),
        }}
      />
      {editModalOpen && (
        <div>
          <ModalAddEditItem
            handleClose={() => setEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            itemDetails={itemDetails}
            kind={ModalAddEditItemKind.faults}
            title={
              modalMode === ModalAddEditItemMode.edit
                ? t('general.editFault')
                : t('general.newFault')
            }
            mode={modalMode}
          />
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </div>
  );
}
