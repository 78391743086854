import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import {
  Colour,
  DateTime,
  ImageUrls,
  Money,
  NoSqlHierarchyDoc,
  UploadedDocumentUrls,
  UserUploadedDocument,
  VolumeDimensions,
  Z_Colour,
  Z_NoSqlHierarchyDoc,
  Z_UserUploadedDocument,
} from '../base/basic';
import { ManufacturerLink } from '../persona/manufacturer';
import { DTCategoryInfo, Z_DTCategoryInfo } from './category';
import { z } from 'zod';
import { schema } from '@rabbit/utils/ts';
import { WarrantyTemplateLink } from './warranty-template';

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                Subset Types                                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                             VendableLegacyModel                            */
/* -------------------------------------------------------------------------- */
/** Used for storing legacy model numbers */
type VendableLegacyModel = {
  /** Legacy model number */
  id: string;
  /** DatTime:: When the model number was first used */
  startTime: DateTime;
  /** DatTime:: When the model number was last used */
  endTime?: DateTime;
};

export const SELF_REGISTERED_VENDABLE = 'SRV';

export const Z_VendableLegacyModel = schema<VendableLegacyModel>()(
  z.object({
    id: z.string(),
    startTime: z.number(),
    endTime: z.number().optional(),
  })
);
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               Document Types                               */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                  Vendable                                  */
/* -------------------------------------------------------------------------- */

/** Publicly viewable data for Vendable - an item that is (or was) sold. */
export interface DTVendable extends NoSqlHierarchyDoc {
  // Description

  /** Human readable descriptive short headline title of the vendable. May be shown on its own. */
  title: string;

  /** Human readable longer name of the vendable. Will not be shown with the title. */
  full?: string;

  /** Human readable detailed description. Will be shown with "full", or "title" if not available. */
  detail?: string;

  /** Brand name as displayed to the consumer */
  brand?: string;

  /** Url of image. If array then it is multiple images with the first being the headline. */
  img?: ImageUrls;

  /** Array of uploaded image info. If multiple items the first is the headline. */
  images?: UserUploadedDocument[];

  /** Category info, using hardcoded system for now */
  category?: DTCategoryInfo;

  /** Feature colour of the product (as used in marketing or as it appears)
   * Can be multiple colours, should be sorted by importance
   */
  colour?: Colour[];

  // Identification

  /** UPC Barcode number
   *
   * As defined by the International Article Numbering Association (EAN.UCC)
   * https://en.wikipedia.org/wiki/Universal_Product_Code
   * */
  upc?: string;

  /** Amazon Standard Identification Number
   *
   * https://www.datafeedwatch.com/blog/amazon-asin-number-what-is-it-and-how-do-you-get-it
   */
  asin?: string;

  /** ManufacturerLink:: Manufacturer - their persona */
  mfr?: ManufacturerLink;

  /** Manufacturer Part Number (model number). Also known as SKU, which is used in Shoplifter to identify the matching product there. */
  // TODO: Make mandatory, possibly even update name to 'sku' and migrate things accordingly to reduce potential confusion
  mpn?: string;

  /** Series name */
  series?: string;

  /** Additional Manufacturer part numbers */
  ampn?: string[]; // TODO: Should this be here? Or do we just list all alt part numbers and give them types so we know where they come from?

  /** Legacy model numbers */
  legacy?: VendableLegacyModel[];

  /** DateTime:: Date when product was first available (undefined if unknown) */
  startTime?: DateTime;

  /** DateTime:: Date when product was last available (undefined if still available) */
  endTime?: DateTime;

  /** Search engine score value */
  score: number;

  /** Link to the user manual(s) */
  manual?: UserUploadedDocument[];

  /** An array of keywords (to be) used in vendable searches. */
  // TODO: Integrate with search engine
  keywords?: string[];

  /** If the vendable is deprecated or not, aka, if the business has stopped actively selling it */
  deprecated?: boolean;

  /** If the vendable is published or not, aka, if it should be visible in search */
  published?: boolean;

  /** If the vendable is unique true then it is not visible in search */
  unique?: boolean;

  /** If the vendable is a part or not, so for example we can use it in claim part search */
  isPart?: boolean;

  /** Any other data held in a freeform object style */
  freeform?: any;

  /** The warranty template that automatically applies to this vendable when purchased (i.e. manufacturer warranty) */
  defaultTemplate?: WarrantyTemplateLink;

  /** Extra warranty templates this vendable is eligible for (i.e. extended warranty) */
  extraTemplates?: WarrantyTemplateLink[];

  chassis_number?: string;

  registration_number?: string;

  production_date?: Date;

  mileage?: {
    value?: number | undefined;
    unit?: string | undefined;
  };
}

export const FBD_Vendable = MakeFBDocType<DTVendable>({
  name: 'Vendable',
  collection: 'vendable',
  empty: () => {
    const result: DTVendable = {
      ...Empty_NoSqlDoc(),
      title: '',
      defaultTemplate: '',
      p: [],
      r: [],
      score: 0,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});

export const Z_Vendable = schema<DTVendable>()(
  z
    .object({
      title: z.string(),
      full: z.string().optional(),
      detail: z.string().optional(),
      brand: z.string().optional(),
      img: schema<ImageUrls>()(z.array(z.string())).optional(),
      images: z.array(Z_UserUploadedDocument).optional(),
      category: Z_DTCategoryInfo.optional(),
      colour: z.array(Z_Colour).optional(),
      upc: z.string().optional(),
      asin: z.string().optional(),
      mfr: z.string().optional(),
      mpn: z.string().optional(),
      series: z.string().optional(),
      ampn: z.array(z.string()).optional(),
      legacy: z.array(Z_VendableLegacyModel).optional(),
      startTime: z.number().optional(),
      endTime: z.number().optional(),
      score: z.number(),
      manual: z.array(Z_UserUploadedDocument).optional(),
      keywords: z.array(z.string()).optional(),
      deprecated: z.boolean().optional(),
      freeform: z.any().optional(),
      defaultTemplate: z.string().optional(),
      extraTemplates: z.array(z.string()).optional(),
      published: z.boolean().optional(),
      unique: z.boolean().optional(),
      chassis_number: z.string().optional(),
      registration_number: z.string().optional(),
      production_date: z.date().optional(),
      mileage: schema<any>()(
        z.object({
          value: z.number().optional(),
          unit: z.string().optional(),
        })
      ).optional(),
    })
    .merge(Z_NoSqlHierarchyDoc)
);
/* -------------------------------------------------------------------------- */
/*                              Vendable_Private                              */
/* -------------------------------------------------------------------------- */

/** Private data for Vendable. Only viewable internally (or perhaps by manufacturer?) */
export interface DTVendable_Private extends NoSqlDoc {
  /** ManufacturerLink:: Manufacturer - their persona */
  mfr?: ManufacturerLink;

  /** Raw data  */
  raw?: string; // TODO: Change to some kind of untyped object? Or maybe json?

  /** Product supplier */
  supplier?: string;

  /** Sales volume - used in search ranking */
  sales?: number;

  /** Recommended retail price */
  rrp?: Money;

  // /** Bill of Materials (not sure the format of this) */
  bom?: any;

  /** Array of part ids. Type might change to "PartLink" or similar in the future */
  parts?: string[];

  /** Array of fault ids. Type might change to "FaultLink" or similar in the future */
  faults?: string[];

  /** Size of the product (in mm) */
  dimensions_millimeter?: VolumeDimensions;

  /** Country where the product was manufactured */
  manufactured_country?: string;

  /** Weight of the product, in grams */
  weight_grams?: number;

  /** Any other data held in a freeform object style */
  freeform?: any;

  /** If the vendable is published or not, aka, if it should be visible in search */
  published?: boolean;

  /** If the vendable is deprecated or not */
  deprecated?: boolean;

  /** Internal notes - freeform text added by manufacturer regarding the vendable */
  internalNotes?: string;
}

export const FBD_Vendable_Private = MakeFBDocType<DTVendable_Private>({
  name: 'Vendable_Private',
  collection: 'vendable_private',
  empty: () => {
    const result: DTVendable_Private = { ...Empty_NoSqlDoc() };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

export const Z_Vendable_Private = schema<DTVendable_Private>()(
  z.object({
    mfr: z.string().optional(),
    raw: z.string().optional(),
    supplier: z.string().optional(),
    sales: z.number().optional(),
    rrp: schema<Money>()(
      z.object({ amount: z.number(), currency: z.string() })
    ).optional(),
    bom: z.any().optional(),
    parts: z.array(z.string()).optional(),
    faults: z.array(z.string()).optional(),
    dimensions_millimeter: schema<VolumeDimensions>()(
      z.object({
        width: z.number(),
        height: z.number(),
        length: z.number(),
      })
    ).optional(),
    manufactured_country: z.string().optional(),
    weight_grams: z.number().optional(),
    freeform: z.any().optional(),
    deprecated: z.boolean().optional(),
    internalNotes: z.string().optional(),
    published: z.boolean().optional(),
  })
);

/** Link to a Vendable */
export type VendableLink = string;

export const Z_VendableLink = z.string();

/** An object with both vendable documents */
export interface VendableFull {
  pub: DTVendable;
  priv: DTVendable_Private;
}

export const Z_VendableFull = schema<VendableFull>()(
  z.object({
    pub: Z_Vendable,
    priv: Z_Vendable_Private,
  })
);
