import { Heading, getCurrencyFormat } from '@rabbit/elements/shared-components';
import {
  PlanDataShape,
  PlansTab,
} from '../components/templates/PlansAndPricesElement/PlansTab';
import { PricesTab } from '../components/templates/PlansAndPricesElement/PricesTab';
import { SelectOptionShape } from '@rabbit/elements/shared-types';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useContext } from 'react';
import { AppContext } from '@rabbit/app-context';

export interface PlanCategoryInterface {
  title: string;
  description: string;
  start?: number;
}

export interface PlanOptionInterface {
  title: string;
  values: (string | boolean)[];
}

export interface PlanInterface {
  title: string;
  description: string;
  category: PlanCategoryInterface[];
  options: PlanOptionInterface[];
}

export interface PlanAddons {
  title: string;
  options: SelectOptionShape[];
}

export function PlanCategory(plan: PlanCategoryInterface) {
  const { tenantInfo } = useContext(AppContext);
  return (
    <div
      className="relative z-[1] flex flex-col gap-4 px-12 py-6 text-center"
      key={plan.title}
    >
      <div className="absolute left-0 top-0 z-[-2] h-[calc(230%+140px)] w-full rounded-lg border border-gray-300 bg-white"></div>
      <Heading kind="h5">{plan.title}</Heading>
      {plan.start && (plan.start as any) !== true && (
        <div className="font-caudex flex items-center justify-center gap-2 whitespace-nowrap text-lg font-bold">
          <span className="text-sm">from</span>{' '}
          {getCurrencyFormat(plan.start, tenantInfo?.currency ?? 'GBP')}
        </div>
      )}
      <Heading kind="p">{plan.description}</Heading>
    </div>
  );
}

export function LabelSelect({
  label,
  options,
  onSelect,
}: {
  label: string;
  options: SelectOptionShape[];
  onSelect: (v: any) => void;
}) {
  return (
    <div
      className="font-nunito flex w-fit items-center gap-2 rounded-md bg-white px-4 py-2 pr-0"
      key={label}
    >
      <span className="flex text-gray-500">{label}</span>-
      <select
        className="font-nunito h-auto w-auto border-0 bg-transparent p-0 font-bold"
        onChange={(v) => onSelect(v)}
      >
        {options.map((option) => (
          <option key={'option-' + label + '' + option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

const gridMap: Record<number, string> = {
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
};

export function PlansHeader({
  title,
  description,
  plans,
  planAddons,
  onAddonChange,
}: {
  title: string;
  description: string;
  plans: PlanCategoryInterface[];
  planAddons?: PlanAddons[];
  onAddonChange?: (option: SelectOptionShape | undefined) => void;
}) {
  return (
    <>
      <div className="grid grid-cols-[360px_1fr] gap-4">
        <div className="bg-primary-600 flex flex-col gap-4 rounded-md border border-gray-300 p-4">
          <Heading kind="h2" className="text-white">
            {title}
          </Heading>
          {planAddons &&
            planAddons.map((addons) =>
              LabelSelect({
                label: addons.title,
                options: addons.options,
                onSelect: (e) =>
                  onAddonChange?.(
                    planAddons[0].options.find(
                      (a) => a.value === e.target.value
                    )
                  ),
              })
            )}
          <Heading kind="p" className="text-white">
            {description}
          </Heading>
        </div>
        <div className={`grid ${gridMap[plans.length]} gap-4`}>
          {plans.map((plan) => PlanCategory(plan))}
        </div>
      </div>
    </>
  );
}

export function PlansGrid({ plans }: { plans: PlanDataShape }) {
  return (
    <div className="grid grid-cols-[360px_1fr] gap-4">
      <div className="flex flex-col gap-4">
        <div className="font-caudex rounded-md bg-white py-2 px-4 text-xl font-bold">
          Term
        </div>
        <div className="flex flex-col rounded-md border border-gray-300 bg-white">
          {plans.header.map((item) => (
            <div
              className="font-caudex flex border-b border-gray-200 px-4 py-3 text-sm uppercase text-gray-800"
              key={item}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className={`grid grid-cols-4 gap-4`}>
        {plans.options.map((option) => (
          <>
            <div className="flex flex-col gap-4" key={option.title}>
              <div className="font-caudex rounded-md bg-white py-2 px-4 text-xl font-bold">
                {option.title}
              </div>
              <div className="flex flex-col rounded-md border border-gray-300 bg-white">
                {option.values.map((item) => (
                  <div
                    className="font-caudex flex border-b border-gray-200 px-4 py-3 text-sm uppercase text-gray-800"
                    key={item}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export function PlansGridList(plan: PlanDataShape) {
  return (
    <div className="flex flex-col gap-4 rounded-md border border-gray-300 text-center">
      <Heading kind="h5" className="px-12 py-6 "></Heading>
    </div>
  );
}

const planPropsGrid: Record<string, string> = {
  Bronze_1: '0 REPAIRS',
  Bronze_2: '0 REPAIRS',
  Bronze_3: '0 REPAIRS',
  Bronze_5: '0 REPAIRS',
  Silver_1: '2 MINOR REPAIRS',
  Silver_2: '4 MINOR REPAIRS',
  Silver_3: '6 MINOR REPAIRS',
  Silver_5: '10 MINOR REPAIRS',
  Gold_1: '2 MINOR, 1 MAJOR',
  Gold_2: '4 MINOR, 2 MAJOR',
  Gold_3: '6 MINOR, 3 MAJOR',
  Gold_5: '10 MINOR, 5 MAJOR',
  Platinum_1: 'UNLIMITED*',
  Platinum_2: 'UNLIMITED*',
  Platinum_3: 'UNLIMITED*',
  Platinum_5: 'UNLIMITED*',
};

export function PlanOptions({
  options,
  plans,
  alt = false,
}: {
  options: PlanOptionInterface[];
  plans?: PlanCategoryInterface[];
  alt?: boolean;
}) {
  return (
    <div
      className={
        alt
          ? 'flex flex-col rounded-lg border-2 border-gray-300 bg-white p-6'
          : ''
      }
    >
      {options.map((option) => (
        <div
          className="font-nunito relative z-[1] grid grid-cols-[360px_1fr] gap-4 border-b border-gray-200 py-4 text-sm last:border-b-0"
          key={option.title}
        >
          <div className="font-caudex font-bold">{option.title}</div>
          <div className={`grid ${gridMap[option.values.length]} gap-4`}>
            {option.values.map((value, index) => (
              <div
                className="justify-left flex items-center gap-2 px-4"
                key={index + '-mao'}
              >
                {plans?.[index]?.title && (
                  <span
                    className="rounded-md bg-gray-100 px-2 py-1"
                    data-index={`${plans?.[index]?.title + '_' + index}`}
                  >
                    {
                      planPropsGrid[
                        plans?.[index]?.title + '_' + parseInt(option.title)
                      ]
                    }
                  </span>
                )}
                {typeof value === 'boolean' ? (
                  value ? (
                    <CheckCircleIcon className="text-primary-600 h-4 w-4" />
                  ) : (
                    '-'
                  )
                ) : (
                  ''
                )}
                {typeof value === 'string' && value}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export function PlansAndPricesView() {
  const tabs = [
    { label: 'Plans', children: <PlansTab /> },
    { label: 'Prices', children: <PricesTab /> },
  ];
  return (
    <div className="relative">
      <PlansTab />
      {/* <TabsGeneric items={tabs} hugTabs /> */}
    </div>
  );
}
