import IconModal from '../IconModal/IconModal';
import TextModal from '../TextModal/TextModal';
import GenericModal from '../GenericModal/GenericModal';
import LoadingSpinner from '../../../atoms/loaders/LoadingSpinner/LoadingSpinner';

export interface ModalSettingsShape {
  headerBackground?: boolean;
  title?: string;
  text?: string;
  primaryButtonText?: string;
  outlineButtonText?: string;
  loading?: boolean;
  handleClose?: () => any;
  handlePrimaryClick?: () => any;
  handleOutlineClick?: () => any;
  handleBackPress?: () => any;
  childrenClassName?: string;
}

export type ModalProps = {
  kind: 'pop-up' | 'info' | 'search' | 'tc' | 'generic';
  settings: ModalSettingsShape;
  isFullScreen?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  className?: string;
};

function renderChildren({
  kind,
  settings,
  children,
  isLoading,
  className = 'bg-white max-w-[90vw] md:w-3/4 lg:w-1/4 lg:max-w-[350px] w-full rounded-md h-fit',
}: ModalProps) {
  if (kind === 'pop-up')
    return (
      <div className={className}>
        <IconModal kind="pop-up" settings={settings} />
      </div>
    );

  if (kind === 'search')
    return (
      <div className={className}>
        <IconModal kind="search" settings={settings} />
      </div>
    );

  if (kind === 'info')
    return (
      <div className={className}>
        <TextModal kind="info" settings={settings} />
      </div>
    );

  if (kind === 'tc')
    return (
      <div className={className}>
        <TextModal kind="tc" settings={settings} />
      </div>
    );

  if (kind === 'generic' && children)
    return (
      <div className={'relative ' + className}>
        {isLoading && <LoadingSpinner overlay={true} size="sm" />}
        <GenericModal settings={settings}>{children}</GenericModal>
      </div>
    );

  return null;
}

function FullScreen({ children }: any) {
  return (
    <div className="fixed left-0 top-0 z-50 m-auto flex h-full w-full flex-wrap content-center justify-center overflow-y-auto bg-gray-500/50">
      {children}
    </div>
  );
}

export function Modal({
  kind,
  settings,
  isFullScreen = true,
  isLoading,
  children,
  className,
}: ModalProps) {
  if (isFullScreen) {
    return (
      <FullScreen>
        {renderChildren({ kind, settings, children, className, isLoading })}
      </FullScreen>
    );
  } else {
    return renderChildren({ kind, settings, children, className, isLoading });
  }
}

export default Modal;
