import {
  DeciderScheduleStringified,
  DTOptionsSingleOption,
  Z_DTOptionsSingleOption,
} from '../decider/types';
import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { RetailerLink, WarrantorLink } from '../persona';
import { z, schema } from '@rabbit/utils/ts';
import { Money, Z_Money } from '../base/basic';

export enum ServiceTypes {
  ROAD_SIDE_ASSISTANCE = 'ROAD_SIDE_ASSISTANCE',
}

/** A single item of service information, such as a right, exclusion **/
export interface ServiceInfoItem {
  title: string;
  description?: string;
  included?: string;
}

export enum ServiceInfoSectionEnum {
  CHECKS = 'checks',
  SIMPLE = 'simple',
}

/** Service info sections. */
export interface ServiceInfoSection {
  title: string;
  type?: ServiceInfoSectionEnum;
  items: ServiceInfoItem[];
}

/* ---------------------------- Warranty Options ---------------------------- */
export type ServiceOptions = {
  optionList: ServiceOptions[];
  pricingCurrency: string;
  // a stringified RecursiveNumberArray, aka a multi-dimensional array of numbers
  pricing: string;
};

/** **Zod Schema for `ServiceInfoItem`** */
export const Z_ServiceInfoItem = z.object({
  title: z.string(),
  description: z.optional(z.string()),
  included: z.optional(z.string()),
});

/** **Zod Schema for `ServiceInfoSection`** */
export const Z_ServiceInfoSection = z.object({
  title: z.string(),
  type: z.optional(z.enum(['checks', 'simple'])),
  items: z.array(Z_ServiceInfoItem),
});

/* -------------------------------------------------------------------------- */
/*                                 Conditions                                 */
/* -------------------------------------------------------------------------- */
/** The conditions that must be met in order for a Warranty to be offered for a given vendable */
export interface ServiceCondition {
  and?: ServiceCondition[];
  or?: ServiceCondition[];
  field: string;
  operator: '==' | '!=' | '<' | '<=' | '>' | '>='; // in future maybe: 'in' | 'not in', etc.
  value: ServiceConditionValue;
}

export type ServiceConditionValue =
  | string
  | number
  | boolean
  | DTOptionsSingleOption; // in future maybe | string[] | number[] | boolean[]

/** **Zod Schema for `ServiceConditionValue`** */
const Z_ServiceConditionValue = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  Z_DTOptionsSingleOption, // Ensuring DTOptionsSingleOption compatibility
]);

/* -------------------------------------------------------------------------- */
/*                            Service Template                                 */
/* -------------------------------------------------------------------------- */

/** A service template defines the available service options that a user can opt into. */
export interface DTService_Template extends NoSqlDoc {
  /** The name of the service template */
  title: string;

  /** The type of the service template.  */
  type: ServiceTypes;

  /** A brief description of the service template */
  description?: string;

  /** the options and their price */
  options?: ServiceOptions;

  /** Price of the Service */
  price: Money;

  /** Array of retailers authorised to sell this warranty */
  retailers?: RetailerLink[];

  /** The warranty information, displayed on Olive */
  info?: ServiceInfoSection[];

  /** The warranty terms, displayed on Olive */
  terms?: ServiceInfoSection[];

  /** The conditions that must be met in order for a Service to be offered for a given vendable
   *  If empty, the service is offered for all vendables.
   */
  conditions?: ServiceCondition;

  /** If decisions can be made with this template, the schedule defines them */
  decider?: DeciderScheduleStringified;

  /** How much time should be subtracted from the Service duration in order
   *  to calculate the end date of the warranty coverage, in ISO 8601 format  */
  endDateOffset?: string;

  /** The entity that provides this warranty template and handles claims related to it */
  warrantorLink: WarrantorLink;
  // TODO: Add more fields as needed
}

/** Firebase document for storing service templates */
export const FBD_Service_Template = MakeFBDocType<DTService_Template>({
  name: 'Service Template',
  collection: 'service_template',
  empty: () => {
    const result: DTService_Template = {
      ...Empty_NoSqlDoc(),
      title: '',
      type: ServiceTypes.ROAD_SIDE_ASSISTANCE, // Default to vehicle breakdown
      price: { currency: '', amount: 0 },
      conditions: {} as ServiceCondition,
      warrantorLink: '',
      description: '',
    };
    return result;
  },
  keygen: FBDTKeygenGeneric, // Key generation logic here
});

/** **Corrected Zod Schema for `DTService_Template`** */
export const Z_DTService_Template = schema<DTService_Template>()(
  z
    .object({
      title: z.string().min(1, 'Title is required'),
      description: z.string().optional(),
      type: z.nativeEnum(ServiceTypes),
      pric: Z_Money,
      terms: z.array(Z_ServiceInfoSection).optional(),
      warrantorLink: z.string(),
    })
    .merge(Z_NoSqlDoc.partial()) // Ensures tcreate/tupdate are optional
);

export type ServiceTemplateLink = string;
export const Z_ServiceTemplateLink = z.string();
