import { z } from 'zod';
import { FBF, HttpsCallableResult } from '@rabbit/firebase/adapter';
import { globalApiPrefix } from './constants';

export type CFRequest<INTYPE> = {
  /** Params - what you pass into the cloud function */
  p: INTYPE;
  /** Riders - k:v pairs that ride along with every request to convey additional information */
  r: { [key: string]: string };
};

export type CFResponse<OUTTYPE> =
  | {
      ok: true;
      data: OUTTYPE;
      error?: string;
      apiVersion: string | null;
    }
  | {
      ok: false;
      error: string;
      apiVersion: string | null;
    };

export type CFCloudFunctionSpec<
  INZOD extends z.ZodType<any, any, any>,
  OUTZOD extends z.ZodType<any, any, any>
> = {
  name: string;
  inShape: INZOD;
  outShape: OUTZOD;
  call: (params: z.infer<INZOD>) => Promise<CFResponse<z.infer<OUTZOD>>>;
};

const NameValidator = z.string().regex(/^[a-z0-9-]{1,62}$/);

const rider: { [key: string]: string } = {};

export function CloudFunctionSetRider(key: string, value: string) {
  rider[key] = value;
}

export function MakeCloudFunctionSpec<
  INZOD extends z.ZodType<any, any, any>,
  OUTZOD extends z.ZodType<any, any, any>
>(
  name: string,
  inShape: INZOD,
  outShape: OUTZOD
): CFCloudFunctionSpec<INZOD, OUTZOD> {
  // v2 function names can only contain lower case letters, numbers, hyphens, and not exceed 62 characters in length
  if (!name.startsWith(globalApiPrefix)) {
    try {
      NameValidator.parse(name);
    } catch (e) {
      throw new Error(
        `Cloud function name can only contain lower case letters, numbers, hyphens, and not exceed 62 characters. Given: ${name}`
      );
    }
  }

  // The return type is inferred from the below rather than declared, since declaring it
  // means a ton of templating effort
  return {
    name,
    inShape,
    outShape,
    call: async (
      params: z.infer<INZOD>
    ): Promise<CFResponse<z.infer<OUTZOD>>> => {
      // validate the input data
      let param: z.infer<INZOD>;
      try {
        param = inShape.parse(params);
      } catch (e: any) {
        // parsing error
        return {
          ok: false,
          error: `[${name}] <clientside request parse>: ${e.toString()}`,
          apiVersion: null,
        };
      }

      const req: CFRequest<z.infer<INZOD>> = {
        p: param,
        r: rider,
      };

      // Make the server call
      let serverResponse: HttpsCallableResult<CFResponse<z.infer<OUTZOD>>>;
      try {
        serverResponse = await FBF.cloudcall(name, req);
      } catch (e: any) {
        const errorMessage = e.response?.data?.message ?? e.toString();

        return {
          ok: false,
          error: errorMessage,
          apiVersion: null,
        };
      }

      // Check for OK condition
      if (serverResponse.data.ok === false) {
        return {
          ok: false,
          error: `[${name}] <server exception>: ${serverResponse.data.error}`,
          apiVersion: serverResponse.data.apiVersion ?? null,
        };
      }

      // validate the result
      let result: z.infer<OUTZOD>;
      console.debug('OutData:', serverResponse.data.data);
      try {
        result = outShape.parse(serverResponse.data.data);
      } catch (e: any) {
        // parsing error
        console.log('[incoming] zod says:', e);
        return {
          ok: false,
          error: `[${name}] <clientside response parse>: ${e.toString()}`,
          apiVersion: serverResponse.data.apiVersion ?? null,
        };
      }
      // after every call, if there's an api version in the response, update the client's version
      if (serverResponse.data?.apiVersion)
        sessionStorage.setItem('apiVersion', serverResponse.data.apiVersion);

      // return the result
      return {
        ok: true,
        data: result,
        apiVersion: serverResponse.data.apiVersion ?? null,
      };
    },
  };
}
