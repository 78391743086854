import {
  LoadingSpinner,
  Modal,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import { useGetWarrantyHistory } from '@rabbit/bizproc/react';
import { DTWarranty } from '@rabbit/data/types';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import { useContext } from 'react';

export interface ModalHistoryLogProps {
  handleClose: () => void;
  warrantyLink: string;
}

const type = (o: any) => Object.prototype.toString.call(o).slice(8, -1);
const parseIfJson = (value: any) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value; // Return as is if parsing fails
    }
  }
  return value;
};
const differ = (o1: any, o2: any, parentKey: string = ''): any => {
  const diff: any = {};
  const keys = new Set([...Object.keys(o1), ...Object.keys(o2)]);
  for (const key of keys) {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;
    let val1 = o1[key];
    let val2 = o2[key];
    if (key === 'decider') {
      val1 = parseIfJson(val1);
      val2 = parseIfJson(val2);
    }
    if (Array.isArray(val1) && Array.isArray(val2)) {
      const maxLength = Math.max(val1.length, val2.length);
      for (let i = 0; i < maxLength; i++) {
        const item1 = val1[i];
        const item2 = val2[i];
        const arrayKey = `${fullKey}.${i}`;
        if (type(item1) === 'Object' && type(item2) === 'Object') {
          const nestedDiff = differ(item1, item2, arrayKey);
          if (Object.keys(nestedDiff).length > 0) {
            Object.assign(diff, nestedDiff);
          }
        } else if (item1 !== item2) {
          diff[arrayKey] = [item1, item2];
        }
      }
    } else if (type(val1) === 'Object' && type(val2) === 'Object') {
      const nestedDiff = differ(val1, val2, fullKey);
      if (Object.keys(nestedDiff).length > 0) {
        Object.assign(diff, nestedDiff);
      }
    } else if (val1 !== val2) {
      diff[fullKey] = [val1, val2];
    }
  }
  return diff;
};

export function ModalHistoryLog({
  handleClose,
  warrantyLink,
}: ModalHistoryLogProps) {
  const { tenantInfo } = useContext(AppContext);
  const { t } = useTranslation();
  const { warrantyHistory } = useGetWarrantyHistory(warrantyLink) || {
    warrantyHistory: null,
  };
  const mileageUnit =
    (warrantyHistory?.[0].SRVSnapshot?.srvInfo?.productInfo as any)
      ?.mileageUnit || 'km';
  const buildLog = () => {
    if (!warrantyHistory) return [];
    const all: any = [];
    const logLength = warrantyHistory.length;
    for (let i = 0; i < logLength - 1; i++) {
      const currentWarranty: DTWarranty = warrantyHistory[i];
      const previousWarranty: DTWarranty = warrantyHistory[i + 1];
      // Compare currentWarranty with previousWarranty and create a new object with the differences
      all.push(differ(currentWarranty, previousWarranty));
    }
    return all;
  };

  const groupByDate = (log: any) => {
    const days = log.reduce((acc: any, curr: any) => {
      const date = new Date(
        curr.tupdate ? curr.tupdate[1] : acc.tupdate ? acc.tupdate[1] : 0
      ).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].unshift(curr);
      return acc;
    }, {});

    // Sort the logs by time
    for (let key in days) {
      days[key] = days[key].sort(
        (a: any, b: any) =>
          new Date(b.tupdate).getTime() - new Date(a.tupdate).getTime()
      );
    }
    return days;
  };

  const all = buildLog();
  const logs = groupByDate(all);

  const renderRow = (label: string, obj: string[]) => {
    function checkValue(value: string | number) {
      if (
        label.toLowerCase().includes('price') ||
        label.toLowerCase().includes('vat') ||
        label.toLowerCase().includes('rate')
      ) {
        if (label.toLowerCase().includes('rate')) {
          value =
            getCurrencyFormat(value, tenantInfo?.currency ?? 'GBP') + '/h';
        } else value = getCurrencyFormat(value, tenantInfo?.currency ?? 'GBP');
      }
      if (label.toLowerCase().includes('date')) {
        if (value === '') return '-';
        if (type(value) === 'String') return value;
        value = new Date(value).toLocaleDateString();
      }
      if (type(value) === 'Boolean') {
        value = value ? 'Yes' : 'No';
      }
      if (label.toLowerCase().includes('mileage')) {
        value = value + mileageUnit;
      }
      return value;
    }
    const current = checkValue(obj[1]);
    const previous = checkValue(obj[0]);
    return (
      <div className="text-md flex gap-2 text-gray-500" key={label}>
        <p>{label}:</p>
        <p className="line-through">{previous}</p>
        <ArrowLongRightIcon width={20} />
        <p>{current}</p>
      </div>
    );
  };

  const SRVKeys: Record<string, string> = {
    'SRVSnapshot.srvInfo.productInfo.regDate': 'Reg date',
    'SRVSnapshot.srvInfo.productInfo.fuel': 'Fuel',
    'SRVSnapshot.srvInfo.productInfo.mileage': 'Mileage',
    'decider.stipulated.riskCategory.label': 'Risk category',
    'decider.stipulated.isHybrid': 'Hybrid',
    'decider.stipulated.isCommercial': 'Commercial vehicle',
    'decider.stipulated.is4x4': '4x4',
    'SRVSnapshot.srvInfo.productInfo.techCheckDate': 'Tech check date',
  };
  const warrantyKeys: Record<string, string> = {
    templateName: 'Warranty plan',
    'price.amount': 'Price',
    endDate: 'End date',
    'decider.stipulated.claimLimit.label': 'Claim limit',
    'decider.stipulated.duration.label': 'Duration',
    'decider.decided.warrantyPrice': 'Warranty price',
    'decider.decided.VAT': 'VAT',
    'appliedOptions.2.value': 'Labour rate',
    registeredTime: 'Registration date',
  };

  const renderDetails = (obj: any, keys: any) => {
    return (
      <div className="flex flex-col gap-2 rounded-lg border border-gray-200 bg-gray-50 p-2">
        {Object.keys(obj).map((key: string) => {
          if (key in keys) {
            return renderRow(keys[key], obj[key]);
          }
        })}
      </div>
    );
  };
  const modalSettings = {
    title: t('general.historyLog'),
    handleClose: () => handleClose(),
  };
  delete logs['1/1/1970'];
  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[724px] rounded-md border bg-white"
    >
      <div className="flex flex-col gap-3 px-4 py-4">
        {warrantyHistory === null && (
          <div className="font-nunito text-lg text-gray-500">
            <LoadingSpinner size="sm" />
          </div>
        )}
        {warrantyHistory !== null && Object.keys(logs).length === 0 && (
          <div className="font-nunito p-4 text-center text-gray-500">
            No logs found
          </div>
        )}
        {Object.keys(logs)
          .reverse()
          .map((date: string) => {
            return (
              <div key={date} className="flex flex-col gap-2">
                <p className="font-nunito text-lg text-gray-900">{date}</p>
                {logs[date].map((log: any, index: number) => (
                  <div
                    key={log.tupdate + index}
                    className="flex flex-col gap-2 rounded-lg border border-gray-200 p-3 text-gray-500"
                  >
                    <p className="font-nunito ">Warranty was edited</p>
                    <div className="flex flex-col gap-2 rounded-lg border border-gray-200 bg-gray-50 p-2">
                      <p className="font-bold">
                        {t('general.warrantyDetails')}
                      </p>
                      {renderDetails(log, warrantyKeys)}
                    </div>
                    <div className="flex flex-col gap-2 rounded-lg border border-gray-200 bg-gray-50 p-2">
                      <p className="font-bold">{t('general.productDetails')}</p>
                      {renderDetails(log, SRVKeys)}
                    </div>
                    <div className="text-right text-sm">
                      {log.tupdate
                        ? log.tupdate.length == 2
                          ? new Date(log.tupdate[1]).toLocaleTimeString()
                          : new Date(log.tupdate[0]).toLocaleTimeString()
                        : ''}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
      </div>
    </Modal>
  );
}
