import { DTVendable, FBD_Vendable } from '@rabbit/data/types';

export interface BLIHolding {
  getVendable: (vendableId: string) => any;
}

export const BL_HOLDING: BLIHolding = {
  getVendable,
};

async function getVendable(vendableId: string) {
  return await FBD_Vendable.get(vendableId);
}
