import { FBF_CloudCallable } from '@rabbit/firebase/adapter';

export const nestApiCreateWarrantyOfferForPartner = FBF_CloudCallable<any, any>(
  'api/warranty-offer/create-offer|POST'
);

export const nestApiGetAllWarrantyOffers = FBF_CloudCallable<any, any>(
  'api/warranty-offer/get-all-offers|POST'
);

export const nestApiGetApprovedWarrantyOffers = FBF_CloudCallable<any, any>(
  'api/warranty-offer/get-approved-offers|POST'
);
