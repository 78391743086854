import { httpsCallable } from '@firebase/functions';
import { FBF_CloudCallable } from '@rabbit/firebase/adapter';
import { firebaseFunctions } from '@rabbit/firebase/adapter-react';
import { PubSubSendEmailCommand } from '@rabbit/pubsub';
import { OurDateTime } from '@rabbit/utils/ts';
import { format } from 'date-fns';
import { apiCallable } from 'libs/firebase/adapter-client-pure/src/apiCallable';
import { t } from 'i18next';

// TODO: rename all Caseflow related functions with the CF_ prefix for extra clarity, or maybe split this into separate hooks - dc
// TODO: DEV-697 - Cleanup all unused templates, both here and in mandrill
// todo: move this to a central location since it'll be used in the backend primarily in the (hopefully near) future
export const MANDRILL_TEMPLATES = {
  BLANK: 'cust_blank_template',
  WAITING_FOR_REVIEW: 'cust_waiting_for_review',
  REQUEST_SEND_ITEM: 'cust_request_send_item',
  REPLACEMENT: 'cust_replacement',
  REPAIRER_ASSIGNED: 'cust_assigned_repairer',
  QUOTE: 'cust_quote',
  CLAIM_REJECTED: 'cust_reject_close_case',
  AUTHORISED_REPAIRER_TASK_NOTIFICATION: '3rd_party_repairer_case_assigned',
  AUTHORISED_REPAIR_COMPLETE: 'cust_authorised_repair_closed',
  POST_RECEIVED: 'cust_inbound_post_received',
  GENERIC_CLOSE_CASE: 'cust_generic_close_case',
  ASSESSMENTS_DIFFER: 'cust_prelim_final_assessment_differ',
  APPROVED_FOR_REPAIR: 'cust_approved_for_repair',
  RETURN_ITEM_TO_CUST: 'cust_return_item', //TODO: not being used -VP
  REPAIR_COMPLETE: 'cust_repair_complete', //TODO: not being used -VP
  POSTAGE_LOGGED: 'cust_postage_logged',
  READY_FOR_CUST_COLLECTION: 'cust_item_ready_for_collection',
  LOCAL_REPAIRER_ASSIGNED: 'cust_local_repairer_assigned',
  PASSWORDLESS_LOGIN: 'passwordless_login',
  SAGE_RESET_PASSWORD: 'sage_reset_password',
  SAGE_INVITE_NEW_USER: 'sage_invite_new_user',
  ASSIGN_CASE: 'cust_assign_case',
  //ADD BODY TEMPLATE NAMES HERE
  BODY_BLANK: 'body_cust_blank_template',
  BODY_BLANK_V2: 'body_cust_blank_template_v2',
  BODY_AUTHORISED_REPAIRER_BLANK: 'body_authorised_repairer_blank',
  BODY_CUST_NEW_HOLDING: 'body_cust_new_holding',
  BODY_CUST_NEW_HOLDING_V2: 'body_cust_new_holding_v2',
  BODY_PASSWORDLESS_LOGIN: 'body_passwordless_login',
  BODY_PASSWORDLESS_LOGIN_V2: 'body_passwordless_login_v2',
  BODY_SAGE_RESET_PASSWORD: 'body_sage_reset_password',
  BODY_SAGE_RESET_PASSWORD_V2: 'body_sage_reset_password_v2',
  BODY_CONSUMER_CLAIM_WAITING_FOR_REVIEW:
    'body_consumer_claim_waiting_for_review',
  BODY_CONSUMER_CLAIM_WAITING_FOR_REVIEW_V2:
    'body_consumer_claim_waiting_for_review_v2',
  BODY_REQUEST_SEND_ITEM: 'body_cust_request_send_item',
  BODY_REQUEST_SEND_ITEM_V2: 'body_cust_request_send_item_v2',
  BODY_REPLACEMENT: 'body_cust_replacement',
  BODY_REPLACEMENT_V2: 'body_cust_replacement_v2',
  BODY_POSTAGE_LOGGED: 'body_cust_postage_logged',
  BODY_POSTAGE_LOGGED_V2: 'body_cust_postage_logged_v2',
  BODY_READY_FOR_CUST_COLLECTION: 'body_cust_item_ready_for_collection',
  BODY_READY_FOR_CUST_COLLECTION_V2: 'body_cust_item_ready_for_collection_v2',
  BODY_AUTHORISED_REPAIRER_TASK_NOTIFICATION:
    'body_3rd_party_repairer_case_assigned',
  BODY_AUTHORISED_REPAIRER_TASK_NOTIFICATION_V2:
    'body_3rd_party_repairer_case_assigned_v2',
  BODY_REPAIRER_ASSIGNED: 'body_cust_assigned_repairer',
  BODY_REPAIRER_ASSIGNED_V2: 'body_cust_assigned_repairer_v2',
  BODY_AUTHORISED_REPAIR_COMPLETE: 'body_cust_authorised_repair_closed',
  BODY_AUTHORISED_REPAIR_COMPLETE_V2: 'body_cust_authorised_repair_closed_v2',
  BODY_LOCAL_REPAIRER_ASSIGNED: 'body_cust_local_repairer_assigned',
  BODY_LOCAL_REPAIRER_ASSIGNED_V2: 'body_cust_local_repairer_assigned_v2',
  BODY_GENERIC_CLOSE_CASE: 'body_cust_generic_close_case',
  BODY_GENERIC_CLOSE_CASE_V2: 'body_cust_generic_close_case_v2',
  BODY_QUOTE: 'body_cust_quote',
  BODY_QUOTE_V2: 'body_cust_quote_v2',
  BODY_POST_RECEIVED: 'body_cust_inbound_post_received',
  BODY_POST_RECEIVED_V2: 'body_cust_inbound_post_received_v2',
  BODY_ASSESSMENTS_DIFFER: 'body_cust_prelim_final_assessment_differ',
  BODY_ASSESSMENTS_DIFFER_V2: 'body_cust_prelim_final_assessment_differ_v2',
  BODY_PRELIM_ASSESSMENT_V2: 'body_cust_prelim_assessment_v2',
  BODY_INITIAL_ASSESSMENT: 'body_cust_initial_assessment',
  BODY_INITIAL_ASSESSMENT_V2: 'body_cust_initial_assessment_v2',
  BODY_APPROVED_FOR_REPAIR: 'body_cust_approved_for_repair',
  BODY_APPROVED_FOR_REPAIR_V2: 'body_cust_approved_for_repair_v2',
  BODY_REPAIR_COMPLETE: 'body_cust_repair_complete',
  BODY_REPAIR_COMPLETE_V2: 'body_cust_repair_complete_v2',
  BODY_CLAIM_REJECTED: 'body_cust_reject_close_case',
  BODY_CLAIM_REJECTED_V2: 'body_cust_reject_close_case_v2',
  BODY_SAGE_INVITE_NEW_USER: 'body_sage_invite_new_user',
  BODY_SAGE_INVITE_NEW_USER_V2: 'body_sage_invite_new_user_v2',
  BODY_SAGE_VEHICLE_REGISTRATION_WIRE: 'body_sage_vehicle_registration', //only for Warranty Ireland
  BODY_SAGE_VEHICLE_REGISTRATION_PW: 'body_sage_vehicle_registration_pw', //only for Pinnacle Warranties
  BODY_SAGE_PARTNER_INVITATION: 'body_sage_partner_invitation',
  BODY_SAGE_PARTNER_INVITATION_V2: 'body_sage_partner_invitation_v2',
  BODY_SAGE_STONE_REGISTRATION: 'body_sage_stone_registration',
  BODY_ASSIGN_CASE: 'body_cust_assign_case',
  BODY_ASSIGN_CASE_V2: 'body_cust_assign_case_v2',
  MAIN_HEADER_FOOTER_DEFAULT_WITHOUT_IWARRANTY_LOGO: 'main_header_footer_default_without_iwarranty_logo',
  BODY_CHAT_MESSAGE: 'body_chat_message_template',
};

export const SHELTA_ADDRESS =
  'Unit 4/1 Foundation Place<br/>Pemulwuy NSW 2148<br/>Australia';

/** Send an e-mail through the front end. 'From' field has to include "@global.iwarranty.co"
 *  'plaintext' serves as a fallback if html is not supported. If not provided, a default message will be sent.
 *  htmlTemplate should be a stringified HTML template - likely built using MJML.
 */
export function useSendEmail() {
  const sendTemplateBodyEmailFn = FBF_CloudCallable<
    PubSubSendEmailCommand,
    any
  >('api/emails/send');

  const getTemplateFn = apiCallable(
    firebaseFunctions,
    'api/legacy/getemailtemplate'
  );

  const getPasswordlessSignInLink = apiCallable(
    firebaseFunctions,
    'api/legacy/getpasswordlesssigninlink'
  );

  const getResetPasswordLink = apiCallable(
    firebaseFunctions,
    'api/legacy/getresetpasswordlink'
  );

  /* -------------------------------------------------------------------------- */
  /*                               Base functions                               */
  /* -------------------------------------------------------------------------- */

  const sendEmailTemplateWithBody = async (
    emailTo: string,
    emailFrom: string,
    mergeVars: { name: string; content: string }[],
    body_template: string,
    main_template: string,
    business_name: string,
    template_language: string
  ) => {
    try {
      const res = await sendTemplateBodyEmailFn({
        to: emailTo,
        from: emailFrom,
        mergeVars,
        bodyTemplateName: body_template,
        mainTemplateName: main_template,
        businessName: business_name,
        templateLanguage: template_language,
      });

      return res;
    } catch (err) {
      throw new Error(
        `Error when sending e-mail template: ${body_template}: ${err}`
      );
    }
  };

  const getEmailTemplate = async (
    mainTemplate: string,
    bodyTemplate: string,
    mergeVars: { name: string; content: any }[]
  ) => {
    try {
      const res = await getTemplateFn({
        mainTemplate,
        bodyTemplate,
        mergeVars,
      });
      return res;
    } catch (err) {
      throw new Error(`Error when fetching email template: ${bodyTemplate}`);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                          Sage user creation invite                         */
  /* -------------------------------------------------------------------------- */

  const SE_Sage_InviteNewUser = async (
    email_to: string,
    email_from: string,
    //body_template_name: string, //TODO review if needed -VP
    main_template_name: string,
    business_name: string,
    first_name: string,
    last_name: string,
    signup_link: string,
    template_language: string
  ) => {
    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        {
          name: 'subject',
          content: t('email.subject.inviteNewUser', { business_name }),
        },
        { name: 'business_name', content: business_name },
        { name: 'first_name', content: first_name },
        { name: 'last_name', content: last_name },
        { name: 'signup_link', content: signup_link },
      ],
      MANDRILL_TEMPLATES.BODY_SAGE_INVITE_NEW_USER_V2,
      main_template_name,
      business_name,
      template_language
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                              Template senders                              */
  /* -------------------------------------------------------------------------- */

  /* --------------------------- Caseflow Templates --------------------------- */

  const SE_Sage_VehicleRegistration = async (
    email_to: string,
    email_from: string,
    business_name: string,
    main_template_name: string,
    body_template_name: string = MANDRILL_TEMPLATES.BODY_SAGE_VEHICLE_REGISTRATION_WIRE,
    first_name: string,
    start_date: string,
    expiry_date: string,
    registration_date: string,
    registration_plate: string,
    make: string,
    model: string,
    claim_limit: string,
    current_mileage: string,
    warranty_type: string,
    warranty_duration: string,
    warranty_labour_rates: string,
    link_to_holding: string,
    template_language: string
  ) => {
    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        {
          name: 'subject',
          content: t('email.subject.vehicleRegistration', { make, model }),
        },
        { name: 'business_name', content: business_name },
        { name: 'first_name', content: first_name },
        { name: 'start_date', content: start_date },
        { name: 'expiry_date', content: expiry_date },
        { name: 'registration_date', content: registration_date },
        { name: 'registration_plate', content: registration_plate },
        { name: 'make', content: make },
        { name: 'model', content: model },
        { name: 'claim_limit', content: claim_limit },
        { name: 'current_mileage', content: current_mileage },
        { name: 'warranty_type', content: warranty_type },
        { name: 'warranty_duration', content: warranty_duration },
        { name: 'increased_labour_rate', content: warranty_labour_rates },
        { name: 'link_to_holding', content: link_to_holding },
      ],
      body_template_name,
      main_template_name,
      business_name,
      template_language
    );
  };

  const SE_Sage_StoneRegistration = async (
    email_to: string,
    email_from: string,
    business_name: string,
    main_template_name: string,
    first_name: string,
    warranty_plan: string,
    term: string,
    registration_date: string,
    start_date: string,
    expiry_date: string,
    type_of_stone: string,
    area: string,
    sealing: string,
    link_to_holding: string,
    template_language: string
  ) => {
    const warrantyBookletLinks = {
      Bronze: 'https://www.nu-cover.com.au/bronze-booklet/',
      Silver: 'https://www.nu-cover.com.au/silver-booklet/',
      Gold: 'https://www.nu-cover.com.au/gold-booklet/',
      Platinum: 'https://www.nu-cover.com.au/platinum-booklet/',
    };

    const link_to_warranty_booklet =
      warrantyBookletLinks[
        warranty_plan as keyof typeof warrantyBookletLinks
      ] ?? 'https://www.nu-cover.com.au';

    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        {
          name: 'subject',
          content: t('email.subject.stoneRegistrationThankYou', {
            type_of_stone,
          }),
        },
        { name: 'business_name', content: business_name },
        { name: 'first_name', content: first_name },
        { name: 'warranty_plan', content: warranty_plan },
        { name: 'term', content: term },
        { name: 'registration_date', content: registration_date },
        { name: 'start_date', content: start_date },
        { name: 'expiry_date', content: expiry_date },
        { name: 'type_of_stone', content: type_of_stone },
        { name: 'area', content: area },
        { name: 'sealing', content: sealing },
        { name: 'link_to_holding', content: link_to_holding },
        { name: 'link_to_warranty_booklet', content: link_to_warranty_booklet },
      ],
      MANDRILL_TEMPLATES.BODY_SAGE_STONE_REGISTRATION,
      main_template_name,
      business_name,
      template_language
    );
  };

  const SE_Olive_Holding_Registered = async (
    email_to: string,
    email_from: string,
    //body_template_name: string, //TODO review if needed -VP
    main_template_name: string,
    business_name: string,
    consumer_name: string,
    product_name: string,
    link_to_holding: string,
    template_language: string
  ) => {
    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        {
          name: 'subject',
          content: t('email.subject.registrationThankYou', { product_name }),
        },
        {
          name: 'first_name',
          content: consumer_name,
        },
        {
          name: 'business_name',
          content: business_name,
        },
        {
          name: 'product_name',
          content: product_name,
        },
        {
          name: 'link_to_holding',
          content: link_to_holding,
        },
      ],
      MANDRILL_TEMPLATES.BODY_CUST_NEW_HOLDING_V2,
      main_template_name,
      business_name,
      template_language
    );
  };

  const SE_Olive_Passwordless_Login = async (
    email_to: string,
    email_from: string,
    //body_template_name: string, //TODO review if needed -VP
    main_template_name: string,
    business_name: string,
    origin_link: string,
    template_language: string
  ) => {
    const res = (await getPasswordlessSignInLink({
      email_to,
      origin_link,
    })) as unknown as { data: string };
    if (!res) throw new Error('Error when generating passwordless login link');
    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        {
          name: 'subject',
          content: t('email.subject.loginLink', { business_name }),
        },
        {
          name: 'login_link',
          content: res.data ?? res,
        },
      ],
      MANDRILL_TEMPLATES.BODY_PASSWORDLESS_LOGIN_V2,
      main_template_name,
      business_name,
      template_language
    );
    window.localStorage.setItem('emailForSignIn', email_to);
  };

  const SE_Sage_ResetPassword = async (
    email_to: string,
    email_from: string,
    //body_template_name: string, //TODO review if needed -VP
    main_template_name: string,
    business_name: string,
    origin_link: string,
    template_language: string
  ) => {
    try {
      const res = (await getResetPasswordLink({
        email_to,
        origin_link,
      })) as unknown as { data: string };
      if (!res)
        throw new Error('Error when generating passwordless login link');
      await sendEmailTemplateWithBody(
        email_to,
        email_from,
        [
          {
            name: 'subject',
            content: t('email.subject.passwordResetLink', { business_name }),
          },
          {
            name: 'password_reset_link',
            content: res.data ?? res,
          },
        ],
        MANDRILL_TEMPLATES.BODY_SAGE_RESET_PASSWORD_V2,
        main_template_name,
        business_name,
        template_language
      );
      window.localStorage.setItem('emailForPasswordReset', email_to);
      return res;
    } catch (err) {
      throw new Error(`Error when sending password reset e-mail`);
    }
  };

  const SE_Olive_Claim_Waiting_For_Review = async (
    email_to: string,
    email_from: string,
    //body_template_name: string, //TODO review if needed -VP
    main_template_name: string,
    business_name: string,
    consumer_name: string,
    claim_id: string,
    product_name: string,
    case_time_created: number,
    link_to_claim: string,
    template_language: string
  ) => {
    const formattedTime = format(
      OurDateTime.timestampToDate(case_time_created),
      'dd/MM/yyyy'
    );
    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        {
          name: 'subject',
          content: t('email.subject.claimRegisteredWaitingReview', {
            business_name,
            product_name,
          }),
        },
        {
          name: 'first_name',
          content: consumer_name,
        },
        {
          name: 'business_name',
          content: business_name,
        },
        {
          name: 'claim_registration_date',
          content: formattedTime,
        },
        {
          name: 'product_name',
          content: product_name,
        },
        {
          name: 'claim_id',
          content: claim_id,
        },
        {
          name: 'link_to_claim',
          content: link_to_claim,
        },
      ],
      MANDRILL_TEMPLATES.BODY_CONSUMER_CLAIM_WAITING_FOR_REVIEW_V2,
      main_template_name,
      business_name,
      template_language
    );
  };

  const SE_Sage_Notify_Authorised_Repairer = async (
    email_to: string,
    email_from: string,
    //body_template_name: string, //TODO review if needed -VP
    main_template_name: string,
    business_name: string,
    claim_id: string,
    first_name: string,
    product_name: string,
    //link_to_claim: string,
    template_language: string
  ) => {
    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        {
          name: 'subject',
          content: t('email.subject.repairCaseAssigned', {
            business_name,
            product_name,
          }),
        },
        { name: 'business_name', content: business_name },
        { name: 'claim_id', content: claim_id },
        { name: 'first_name', content: first_name },
        { name: 'product_name', content: product_name },
        //{ name: 'link_to_claim', content: link_to_claim },
      ],
      MANDRILL_TEMPLATES.BODY_AUTHORISED_REPAIRER_TASK_NOTIFICATION_V2,
      main_template_name,
      business_name,
      template_language
    );
  };

  const SE_Sage_PartnerInvitation = async (
    email_to: string,
    email_from: string,
    business_name: string,
    business_logo: string,
    main_template_name: string,
    partner_name: string,
    partner_type: string,
    invitation_url: string,
    template_language: string
  ) => {
    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        {
          name: 'subject',
          content: t('email.subject.invitedToJoin', {
            business_name,
            partner_type,
          }),
        },
        { name: 'business_name', content: business_name },
        { name: 'business_logo', content: business_logo },
        { name: 'partner_name', content: partner_name },
        { name: 'partner_type', content: partner_type },
        { name: 'invitation_url', content: invitation_url },
      ],
      MANDRILL_TEMPLATES.BODY_SAGE_PARTNER_INVITATION_V2,
      main_template_name,
      business_name,
      template_language
    );
  };

  const SE_Sage_Chat_Message = async (
    email_to: string,
    email_from: string,
    subject: string,
    // main_template_name: string,
    business_name: string,
    claim_id: string,
    first_name: string,
    product_name: string,
    chat_message: string,
    link_to_claim: string,
    template_language: string
  ) => {
    const main_template_name = MANDRILL_TEMPLATES.MAIN_HEADER_FOOTER_DEFAULT_WITHOUT_IWARRANTY_LOGO;

    await sendEmailTemplateWithBody(
      email_to,
      email_from,
      [
        { name: 'subject', content: subject },
        { name: 'business_name', content: business_name },
        { name: 'claim_id', content: claim_id },
        { name: 'first_name', content: first_name },
        { name: 'product_name', content: product_name },
        { name: 'chat_message', content: chat_message },
        { name: 'link_to_claim', content: link_to_claim },
      ],
      MANDRILL_TEMPLATES.BODY_CHAT_MESSAGE,
      main_template_name,
      business_name,
      template_language
    );
  };

  return {
    sendEmailTemplateWithBody,
    getEmailTemplate,
    SE_Sage_ResetPassword,
    SE_Sage_InviteNewUser,
    SE_Olive_Holding_Registered,
    SE_Olive_Passwordless_Login,
    SE_Olive_Claim_Waiting_For_Review,
    SE_Sage_VehicleRegistration,
    SE_Sage_Notify_Authorised_Repairer,
    SE_Sage_PartnerInvitation,
    SE_Sage_StoneRegistration,
    SE_Sage_Chat_Message,
  };
}
