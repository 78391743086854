import { useContext, useState } from 'react';
import { CaseflowContext } from '../../../context/CaseflowContext';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LabelledAddressItem,
  LabelledItem,
} from '@rabbit/elements/shared-components';
import ContactCustomerModal from '../../organisms/case-flow-modals/ContactCustomerModal/ContactCustomerModal';
import { EnvelopeIcon, PencilIcon } from '@heroicons/react/24/solid';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import Skeleton from 'react-loading-skeleton';
import {
  Address,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { ModalNewEditCustomer } from '../../organisms/ModalNewEditCustomer/ModalNewEditCustomer';
import { CORPORATE } from '@rabbit/sage/utils/consts.ts';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerDetailsCardProps {}

export function CustomerDetailsCard(props: CustomerDetailsCardProps) {
  const context = useContext(CaseflowContext);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const { t } = useTranslation();
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';
  const {
    setModalSettings,
    setShowModal,
    consumerPersonaData,
    operatingPersona,
  } = context || {};
  let showCustomerHeader = true;
  const modalSettingsOptions = {
    contact_customer_generic: {
      kind: 'generic' as const,
      settings: {
        title: t('message.contactCustomerForMoreInfo'),
        primaryButtonText: t('general.notifyCustomer'),
        secondaryButtonText: t('general.cancel'),
      },
      children: <ContactCustomerModal />,
    },
  };
  if (
    operatingPersona?.startsWith(
      PersonaTypeSingleLetter.Consumer + PersonaIdTypeSplitter
    )
  ) {
    showCustomerHeader = false;
  }

  return (
    <CardWrapperWithHeader
      title={t('general.customerDetails')}
      headerRight={
        showCustomerHeader && (
          <div className="flex gap-4">
            <>
              <ButtonIcon
                type="primary"
                label={``}
                Icon={EnvelopeIcon}
                onClick={() => {
                  if (setModalSettings && setShowModal) {
                    setModalSettings(
                      modalSettingsOptions.contact_customer_generic
                    );
                    setShowModal(true);
                  }
                }}
              />
              <ButtonIcon
                type="secondary"
                kind="bgLightGreen"
                label={``}
                Icon={PencilIcon}
                onClick={() => setEditCustomerModal(true)}
              />
            </>
          </div>
        )
      }
    >
      {!consumerPersonaData ? (
        <div className=" flex flex-col gap-3">
          <Skeleton count={2} containerClassName="max-w-[150px]" />
          <Skeleton count={2} containerClassName="max-w-[200px]" />
          <Skeleton count={2} containerClassName="max-w-[170px]" />
          <Skeleton count={4} containerClassName="max-w-[220px]" />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-3">
            {consumerPersonaData?.consumer_type === CORPORATE && (
              <LabelledItem
                label={t('general.businessName')}
                value={consumerPersonaData?.business_name ?? '-'}
              />
            )}
            <LabelledItem
              label={t('general.name')}
              value={consumerPersonaData?.fullname ?? ''}
              arrowLink={`${SAGE_ROUTE_NAME.MANAGE}/customer/${consumerPersonaData?.docid}`}
            />
            <LabelledItem
              label={t('general.emailAddress')}
              value={consumerPersonaData?.email ?? '-'}
            />
            <LabelledItem
              label={t('general.phoneNumber')}
              value={
                consumerPersonaData?.phone
                  ? `+ ${consumerPersonaData?.phone}`
                  : '-'
              }
            />
            <LabelledAddressItem
              addresses={(consumerPersonaData?.address ?? []) as Address[]}
            />
          </div>
          {editCustomerModal && (
            <div>
              <ModalNewEditCustomer
                handleClose={() => setEditCustomerModal(false)}
                handleDelete={() => {}}
                customerDetails={consumerPersonaData}
                modalSettings={{
                  title: t('general.editCustomer'),
                  handleClose: () => setEditCustomerModal(false),
                }}
              />
            </div>
          )}
        </>
      )}
    </CardWrapperWithHeader>
  );
}

export default CustomerDetailsCard;
