import {
  Empty_NoSqlDoc,
  FBD_Keygen_BANNED,
  MakeFBDocType,
  NoSqlDoc,
  Z_NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { WarrantorLink, Z_WarrantorLink } from '../persona';
import { z } from 'zod';
import {
  WarrantyTemplateLink,
  Z_WarrantyTemplateLink,
} from './warranty-template';
import {
  DeciderWarrantyOffer_Stringified,
  Z_DeciderWarrantyOffer,
} from '../decider/types';
import { TenantLink, Z_TenantLink } from './tenant';
import { schema } from '@rabbit/utils/ts';

export interface DTWarranty_Offer extends NoSqlDoc {
  /** The name of the warranty offer, usually inherited from the parent template */
  title: string;

  /** The entity which owns this warranty offer and handles claims related to it */
  warrantorLink: WarrantorLink;

  /** The warranty template that this offer is based on */
  templateLink: WarrantyTemplateLink;

  /** If decisions can be made with this offer, the schedule defines them */
  decider?: DeciderWarrantyOffer_Stringified;

  /** The partner-tenant which distributes this offer */
  tenantLink: TenantLink;

  /** Is this offer enabled? "Deleting" an offer will only disable it */
  enabled: boolean;

  /** The docid of business_user_invite of the partner for which this offer is being created before invite is accepted */
  partnerInviteLink?: string;

  /** The email of the partner for which this offer is being created before invite is accepted */
  partnerEmail?: string;

  /** Flag to indicate whether this offer is pending activation */
  pendingActivation?: boolean;

  /** The order number of the offer */
  orderNo?: number;
}

export const FBD_Warranty_Offer = MakeFBDocType<DTWarranty_Offer>({
  name: 'Warranty Offer',
  collection: 'warranty_offer',
  empty: () => {
    const result: DTWarranty_Offer = {
      ...Empty_NoSqlDoc(),
      title: '',
      warrantorLink: '',
      templateLink: '',
      tenantLink: '',
      enabled: true,
      partnerInviteLink: '',
      partnerEmail: '',
      pendingActivation: false,
      orderNo: 1,
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to [templateLink]_[tenantLink]_[random6charstring]!.' // eg. 'basicTemplate_PINNACLEWARRANTIES_RiskCatB' if hardcoded or 'basicTemplate_PINNACLEWARRANTIES_RiskCatB_123456'
  ),
});

export const Z_DTWarranty_Offer = schema<DTWarranty_Offer>()(
  z
    .object({
      title: z.string(),
      warrantorLink: Z_WarrantorLink,
      templateLink: Z_WarrantyTemplateLink,
      tenantLink: Z_TenantLink,
      enabled: z.boolean(),
      decider: Z_DeciderWarrantyOffer.optional(),
      partnerInviteLink: z.string().optional(),
      partnerEmail: z.string().optional(),
      pendingActivation: z.boolean().optional(),
      orderNo: z.number().optional(),
    })
    .merge(Z_NoSqlDoc)
);

export type WarrantyOfferLink = string;

export const Z_WarrantyOfferLink = z.string();
