import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { OptionShape, useGetRepairerPeers } from '@rabbit/bizproc/react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import classNames from 'classnames';

export interface AdministrativeTimeModalProps {
  index?: number | null;
  data?: FormValueShape | null;
  remove?: boolean | null;
}

interface FormValueShape {
  delegate_repairer_id: string;
  timeStamp: string;
  time_spent_assessing: string;
}

const validationSchema = Yup.object().shape({
  delegate_repairer_id: Yup.string()
    .trim()
    .required('Please select a technician'),
  timeStamp: Yup.string().trim(),
  time_spent_assessing: Yup.string().trim(),
});

export function AdministrativeTimeModal({
  index,
  data,
  remove,
}: AdministrativeTimeModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const repairerUsers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : repairerPeer.map((repairer, index) => ({
        id: `${index}`,
        label: repairer?.name || '',
        value: repairer?.docid || '',
      }));
  const [usersForAssignment, setUsersForAssignment] = useState(repairerUsers);

  const initialValues: FormValueShape = {
    delegate_repairer_id: data?.delegate_repairer_id ?? '',
    timeStamp: data?.timeStamp ?? '',
    time_spent_assessing: data?.time_spent_assessing ?? '',
  };

  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  useEffect(() => {
    setUsersForAssignment(repairerUsers);
  }, [repairerUsers]);

  const getRepairerName = (id: string) => {
    const repairer = usersForAssignment.find((user) => user.value === id);
    return repairer?.label;
  };

  const onDelete = async () => {
    setIsSubmitting(true);
    const updatedRegistry = caseFacts.repair_work_registry?.filter(
      (registry, i) => i !== index
    );
    try {
      await alterCaseFacts({ repair_work_registry: updatedRegistry });
      toast.success('Claim updated successfully.');
      setShowModal(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (values: FormValueShape) => {
    setIsSubmitting(true);
    const updatedRegistry = caseFacts.repair_work_registry?.map(
      (registry, i) => {
        if (i === index) {
          return {
            ...registry,
            delegate_repairer_id: values.delegate_repairer_id,
            delegate_repairer_name: getRepairerName(
              values.delegate_repairer_id
            ),
            timeStamp: values.timeStamp,
            time_spent_assessing: values.time_spent_assessing,
          };
        }
        return registry;
      }
    );

    try {
      await alterCaseFacts({ repair_work_registry: updatedRegistry });
      toast.success('Claim updated successfully.');
      setShowModal(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      settings={{
        title: 'Administrative time',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      className={classNames('max-h-[768px] w-full max-w-[1024px]', {
        'max-w-[500px]': remove,
      })}
      isLoading={isSubmitting}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => {
          return (
            <Form className="mt-5 flex flex-col gap-3 px-4">
              <Input
                type="select"
                label="Team member*"
                name="delegate_repairer_id"
                settings={{
                  options: repairerUsers ?? [],
                  id: 'delegate_repairer_id',
                  placeholder: 'Please select an option',
                  hint: '*required',
                }}
              />
              <div className="flex gap-3">
                <Input
                  type="datepicker"
                  label="Date*"
                  name="timeStamp"
                  settings={{
                    id: 'timeStamp',
                    placeholder: 'Select a date',
                    hint: '*required',
                  }}
                />
                <Input
                  type="time"
                  label="Log time spent repairing*"
                  name="time_spent_assessing"
                  settings={{
                    id: 'time_spent_assessing',
                    hint: '*required',
                    errors: errors.time_spent_assessing,
                  }}
                />
              </div>
              {/** DOUBLE CHECK RATE HERE */}
              {/* <div>
                <Input
                  type="currency"
                  name="parts_cost"
                  label='Rate*'
                  settings={{
                    placeholder: 'Per unit',
                    currency: appInfo.currency,
                    hint: '*required',
                  }}
                />
              </div> */}
              <div className="mt-4 flex gap-4">
                <Button kind="primary" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AdministrativeTimeModal;
