import { useGetMySagePersonas } from '@rabbit/data/portal';
import {
  DTConsumer_Private,
  DTRepairer_Private,
  DTRetailer_Private,
} from '@rabbit/data/types';
import React, { useEffect } from 'react';

type SagePersonaCheckerProps = {
  // setIdentityPrivate: React.Dispatch<
  //   React.SetStateAction<DTIdentity_Private | null>
  // >;
  setRepairerPersonaData: React.Dispatch<
    React.SetStateAction<DTRepairer_Private | null>
  >;
  setInstallerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
  setInstallerPersonaData: React.Dispatch<
    React.SetStateAction<DTRepairer_Private | null>
  >;
  setRepairerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
  setRetailerPersonaData: React.Dispatch<
    React.SetStateAction<DTRetailer_Private | null>
  >;
  setRetailerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
  setConsumerPersonaData: React.Dispatch<
    React.SetStateAction<DTConsumer_Private | null>
  >;
  setConsumerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
  // setManufacturerPersonaData: React.Dispatch<
  //   React.SetStateAction<DTManufacturer_Private | null>
  // >;
  // setManufacturerPersonaId: React.Dispatch<React.SetStateAction<string | null>>;
};

// TO CHECK: isLoading - is it the best option here?
export function SagePersonaChecker({
  //setIdentityPrivate,
  setRepairerPersonaData,
  setRepairerPersonaId,
  setInstallerPersonaId,
  setInstallerPersonaData,
  setRetailerPersonaData,
  setRetailerPersonaId,
  setConsumerPersonaData,
  setConsumerPersonaId,
}: // setManufacturerPersonaData,
// setManufacturerPersonaId,
SagePersonaCheckerProps) {
  const {
    repairerPersona,
    retailerPersona,
    installerPersona,
    queryStatus,
    consumerPersona,
    //manufacturerPersona
  } = useGetMySagePersonas();

  const queryResults = queryStatus.queryResults;
  const {
    isLoading: repairerIsLoading,
    isLoadingError: repairerIsLoadingError,
    isError: repairerIsError,
  } = queryResults.repairer_private;
  const {
    isLoading: retailerIsLoading,
    isLoadingError: retailerIsLoadingError,
    isError: retailerIsError,
  } = queryResults.retailer_private;
  const {
    isLoading: installerIsLoading,
    isLoadingError: installerIsLoadingError,
    isError: installerIsError,
  } = queryResults.repairer_private;

  const {
    isLoading: consumerIsLoading,
    isLoadingError: consumerIsLoadingError,
    isError: consumerIsError,
  } = queryResults.consumer_private;

  useEffect(() => {
    if (
      installerPersona?.repairer_private &&
      !(installerIsError || installerIsLoading || installerIsLoadingError)
    ) {
      setInstallerPersonaId(installerPersona.personaId);
      setInstallerPersonaData(installerPersona.repairer_private);
    }
  }, [installerPersona?.personaId, installerPersona?.repairer_private?.docid]);

  useEffect(() => {
    if (
      repairerPersona?.repairer_private &&
      !(repairerIsError || repairerIsLoading || repairerIsLoadingError)
    ) {
      setRepairerPersonaData(repairerPersona.repairer_private);
      setRepairerPersonaId(repairerPersona.personaId);
    }
  }, [repairerPersona?.personaId, repairerPersona?.repairer_private?.docid]);

  useEffect(() => {
    if (
      retailerPersona?.retailer_private &&
      !(retailerIsError || retailerIsLoading || retailerIsLoadingError)
    ) {
      setRetailerPersonaData(retailerPersona.retailer_private);
      setRetailerPersonaId(retailerPersona.personaId);
    }
  }, [retailerPersona?.personaId, retailerPersona?.retailer_private?.docid]);

  useEffect(() => {
    if (
      consumerPersona?.consumer_private &&
      !(consumerIsError || consumerIsLoading || consumerIsLoadingError)
    ) {
      setConsumerPersonaData(consumerPersona?.consumer_private);
      setConsumerPersonaId(consumerPersona?.personaId);
    }
  }, [consumerPersona?.personaId, consumerPersona?.consumer_private?.docid]);

  return null;
}
