import {
  asyncValidateSchema,
  Button,
  getCountryByLabel,
  Input,
  InputTypeSelectAddress,
} from '@rabbit/elements/shared-components';
import { Form, Formik } from 'formik';
import { CreateNewConsumerForm_FieldNames } from '@rabbit/sage/components/organisms/ModalNewRegistration/tenant-config/types.ts';
import { CONTACT_OPTIONS, LIST_COUNTRIES } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Dispatch, SetStateAction, useContext, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { NAME_VALIDATION_REGEX } from '@rabbit/sage/utils/consts.ts';
import { AppContext } from '@rabbit/app-context';

interface customerDetailsProps {
  steps: any;
  setCustomerData: Dispatch<SetStateAction<any>>;
  setCurrentStep: Dispatch<SetStateAction<string>>;
}

export default function CustomerDetailsView({
  steps,
  setCustomerData,
  setCurrentStep,
}: customerDetailsProps) {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formikRef = useRef(null) as any;
  const onSubmitCustomer = async (values: any) => {
    const data = {
      ...values,
      consumer_telephone:
        values.consumer_telephone_e164 ?? values.consumer_telephone,
    };
    setCustomerData(data);
    setCurrentStep(steps[2]);
  };
  const backToInstaller = () => void setCurrentStep(steps[0]);

  const initialValues: Partial<any> = {
    [CreateNewConsumerForm_FieldNames.consumer_first_name]: '',
    [CreateNewConsumerForm_FieldNames.consumer_last_name]: '',
    [CreateNewConsumerForm_FieldNames.consumer_email]: '',
    [CreateNewConsumerForm_FieldNames.consumer_telephone]: '',
    [CreateNewConsumerForm_FieldNames.consumer_preferred_contact]: [],
    [CreateNewConsumerForm_FieldNames.consumer_address]: {
      [CreateNewConsumerForm_FieldNames.consumer_address_line1]: '',
      [CreateNewConsumerForm_FieldNames.consumer_address_line2]: '',
      [CreateNewConsumerForm_FieldNames.consumer_address_town]: '',
      [CreateNewConsumerForm_FieldNames.consumer_address_state]: '',
      [CreateNewConsumerForm_FieldNames.consumer_address_country]:
        tenantInfo?.country,
    },
  };

  const validationSchemaRegister = () =>
    Yup.object().shape({
      [CreateNewConsumerForm_FieldNames.consumer_first_name]: Yup.string()
        .required(t('message.pleaseEnterFirstName'))
        .matches(
          NAME_VALIDATION_REGEX,
          t('message.firstNameMustStartWithCapitalAndContainOnlyLetters')
        ),
      [CreateNewConsumerForm_FieldNames.consumer_last_name]: Yup.string()
        .trim()
        .required(t('message.pleaseEnterLastName'))
        .matches(
          NAME_VALIDATION_REGEX,
          t('message.lastNameMustStartWithCapitalAndContainOnlyLetters')
        ),
      [CreateNewConsumerForm_FieldNames.consumer_email]: Yup.string()
        .email(t('message.pleaseEnterAValidEmailAddress'))
        .required(t('message.emailCannotBeEmpty')),
      [CreateNewConsumerForm_FieldNames.consumer_telephone]: Yup.string()
        .min(6, t('message.phoneNumberMinLength'))
        .required(t('message.pleaseEnterAPhoneNumber')),
      [CreateNewConsumerForm_FieldNames.consumer_preferred_contact]: Yup.array()
        .min(1, t('message.selectAtLeastOneContactMethod'))
        .typeError(t('message.selectPreferredContactMethod'))
        .required(t('message.selectPreferredContactMethod')),
      [CreateNewConsumerForm_FieldNames.consumer_address]: Yup.mixed(),
    });

  const handleSelectorChange = async (address?: any) => {
    if (address) {
      // props.resetForm();
      await formikRef.current.setFieldValue(
        CreateNewConsumerForm_FieldNames.consumer_address_line1,
        address.line_1
      );
      await formikRef.current.setFieldValue(
        CreateNewConsumerForm_FieldNames.consumer_address_line2,
        address.line_2
      );
      await formikRef.current.setFieldValue(
        CreateNewConsumerForm_FieldNames.consumer_address_town,
        address.locality
      );
      await formikRef.current.setFieldValue(
        CreateNewConsumerForm_FieldNames.consumer_address_state,
        address.province_name
      );
      await formikRef.current.setFieldValue(
        CreateNewConsumerForm_FieldNames.consumer_address_postcode,
        address.postal_code
      );
      await formikRef.current.setFieldValue(
        CreateNewConsumerForm_FieldNames.consumer_address_postcode,
        getCountryByLabel(address.country_name)?.value
      );
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Formik
        initialValues={initialValues}
        validate={asyncValidateSchema(validationSchemaRegister())}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={onSubmitCustomer}
        innerRef={formikRef}
      >
        <Form className="font-nunito flex flex-col gap-3">
          <Grid container spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                type="text"
                name={CreateNewConsumerForm_FieldNames.consumer_first_name}
                label={`${t('general.firstName')}*`}
                settings={{
                  id: CreateNewConsumerForm_FieldNames.consumer_first_name,
                  placeholder: t('general.firstName'),
                  hint: t('*required'),
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                type="text"
                name={CreateNewConsumerForm_FieldNames.consumer_last_name}
                label={`${t('general.lastName')}*`}
                settings={{
                  id: CreateNewConsumerForm_FieldNames.consumer_last_name,
                  placeholder: t('general.lastName'),
                  hint: t('*required'),
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                type="email"
                name={CreateNewConsumerForm_FieldNames.consumer_email}
                label={`${t('general.email')}*`}
                settings={{
                  id: CreateNewConsumerForm_FieldNames.consumer_email,
                  placeholder: t('example@example.com'),
                  hint: t('*required'),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                type="phone"
                name={CreateNewConsumerForm_FieldNames.consumer_telephone}
                label={`${t('general.phoneNumber')}*`}
                settings={{
                  id: CreateNewConsumerForm_FieldNames.consumer_telephone,
                  placeholder: t('general.phoneNumber'),
                  hint: t('*required'),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                type="select"
                name={
                  CreateNewConsumerForm_FieldNames.consumer_preferred_contact
                }
                label={`${t('general.preferredContactMethod')}*`}
                settings={{
                  isMulti: true,
                  id: CreateNewConsumerForm_FieldNames.consumer_preferred_contact,
                  placeholder: t('message.pleaseSelectAnOption'),
                  options: CONTACT_OPTIONS,
                  errors: '',
                  hint: t('*required'),
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <div className="mb-0">
                <label className="font-nunito text-base font-medium">
                  {t('general.customerAddress')}
                </label>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                type="select"
                label={t('general.country')}
                name={CreateNewConsumerForm_FieldNames.consumer_address_country}
                settings={{
                  options: LIST_COUNTRIES,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InputTypeSelectAddress
                //onChange={(address) => setSelectedAddress(address)}
                onChange={(address) => handleSelectorChange(address)}
                errors={''}
                // errors={props.errors['consumer_address']}
                country={t('CFG_DEFAULT_COUNTRY')}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                type="text"
                label={t('general.addressLine')}
                name={CreateNewConsumerForm_FieldNames.consumer_address_line1}
                settings={{}}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                type="text"
                label={t('general.addressLine2')}
                name={CreateNewConsumerForm_FieldNames.consumer_address_line2}
                settings={{}}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                type="text"
                label={t('general.citySuburb')}
                name={CreateNewConsumerForm_FieldNames.consumer_address_town}
                settings={{}}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                type="text"
                label={t('general.stateTerritory')}
                name={CreateNewConsumerForm_FieldNames.consumer_address_state}
                settings={{}}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                type="text"
                label={t('general.postCode')}
                name={
                  CreateNewConsumerForm_FieldNames.consumer_address_postcode
                }
                settings={{
                  id: 'zip_code',
                  placeholder: '',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Button
                className="w-full"
                kind="primary"
                type="submit"
                loading={isSubmitting}
              >
                {t('general.continue')}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Button
                className="w-full"
                kind="outline_red"
                type="button"
                onClick={backToInstaller}
              >
                {t('general.back')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Box>
  );
}
