const store: Record<string, ((...args: any[]) => any)[]> = {};

const Bus = {
  on: (event: string, fn: (...args: any[]) => any) => {
    store[event] = store[event] || [];
    store[event].push(fn);
  },

  off: (event: string, fn: (...args: any[]) => void) => {
    const index = store[event].indexOf(fn);
    if (index > -1) {
      store[event].splice(index, 1);
    } else console.log('not found');
  },

  emit: (event: string, data: any) => {
    store[event] = store[event] || [];
    store[event].forEach((fn) => fn(data));
  },

  // Events
  events: {
    modalContent: 'modal:content',
    dropdownOpen: 'dropdown:open',
  },
};

export default Bus;
