import * as Sentry from '@sentry/react';

function StartSentry() {
  //@ts-ignore
  if (import.meta.env.MODE === 'development') {
    console.log('Sentry: Not adding Sentry in development mode');
    return;
  }

  //@ts-ignore
  console.log('Sentry: Release -> ', import.meta.env.VITE_GIT_CODENAME);

  const SentryCommonParams = {
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.004, // This sets the sample rate at 0.4%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    //@ts-ignore
    environment: import.meta.env.VITE_FIREBASE_MODE,
    //@ts-ignore
    release: import.meta.env.VITE_GIT_CODENAME,
  };

  //@ts-ignore
  if (import.meta.env.VITE_APP_NAME === 'OLIVE') {
    console.log('Sentry: Starting for Project Olive');

    Sentry.init({
      dsn: 'https://fcdf96dcd0b79c8c3a653710175d4fdb@o4506903135518720.ingest.us.sentry.io/4506909194977280',
      ...SentryCommonParams,
    });
  } else if (import.meta.env.VITE_APP_NAME === 'SAGE') {
    console.log('Sentry: Starting for Project Sage');

    Sentry.init({
      dsn: 'https://38d00d8bfb3f130d4f829b90725980d5@o4506903135518720.ingest.us.sentry.io/4506949030576128',
      ...SentryCommonParams,
    });
  } else if (import.meta.env.VITE_APP_NAME === 'ADMIN') {
    console.log('Sentry: Starting for Project Admin');

    Sentry.init({
      dsn: 'https://2decdf32f64e353ccaa96fa6e7835136@o4506903135518720.ingest.us.sentry.io/4508896040648704',
      ...SentryCommonParams,
    });
  } else {
    console.log(
      "Sentry: I don't know this project. If you want Sentry, define VITE_APP_NAME and edit sentry setup."
    );
  }
}

StartSentry();

(window as any).TriggerError = () => {
  setTimeout(() => {
    throw new Error(
      //@ts-ignore
      'What were you thinking? -> ' + import.meta.env.VITE_APP_NAME
    );
  }, 100);
};
