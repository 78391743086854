import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ModalSettingsShape } from '../Modal/Modal';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef, useState } from 'react';
import Bus from '@rabbit/bus';

export type GenericModalProps = {
  settings: ModalSettingsShape;
  children: React.ReactNode;
};

const dynamicStyles = {
  container:
    'flex-col border-gray-200 rounded-lg relative w-full max-h-[90vh] flex pb-4 bg-white',
  text: 'text-center font-nunito font-normal text-gray-500 text-base px-1',
};

export function GenericModal({ settings, children }: GenericModalProps) {
  const {
    handleClose,
    title,
    headerBackground,
    childrenClassName,
    handleBackPress,
  } = settings;
  const contentRef = useRef<HTMLDivElement>(null);
  const [visibleOverflow, setVisibleOverflow] = useState(false);

  // Scroll to the top when `children` changes
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }

    const contentOpenListener = (open: boolean) => {
      if (
        (contentRef.current?.scrollHeight || 0) <=
        (contentRef.current?.offsetHeight || 0)
      ) {
        setVisibleOverflow(open);
      }
    };

    Bus.on(Bus.events.modalContent, contentOpenListener);

    return () => {
      Bus.off(Bus.events.modalContent, contentOpenListener);
    };
  }, [children]);

  return (
    <div className={classNames(`${dynamicStyles.container}`)}>
      {settings.title ? (
        <div
          className={classNames(
            'flex w-full items-center justify-between rounded-t border-b p-5',
            { ['bg-gray-50']: headerBackground }
          )}
        >
          <div
            className={
              'font-caudex truncate text-xl font-medium text-gray-900' +
              (handleBackPress ? ' flex cursor-pointer items-center' : '')
            }
            onClick={handleBackPress ? handleBackPress : () => 0}
          >
            {handleBackPress && (
              <ChevronLeftIcon className="h-6 w-6 text-gray-900" />
            )}
            {title}
          </div>
          <div className="ml-auto">
            <XMarkIcon
              onClick={handleBackPress ? handleBackPress : handleClose}
              className={classNames(
                'h-[18px] w-[18px] cursor-pointer bg-transparent text-gray-400 hover:bg-gray-200 hover:text-gray-600',
                { ['text-gray-900']: headerBackground }
              )}
            />
            <span className="sr-only">Close modal</span>
          </div>
        </div>
      ) : (
        <div className="absolute top-4 right-4">
          <XMarkIcon
            onClick={handleClose}
            className={classNames(
              'h-[18px] w-[18px] cursor-pointer bg-transparent text-gray-400 hover:bg-gray-200 hover:text-gray-600',
              { ['text-gray-900']: headerBackground }
            )}
          />
        </div>
      )}
      <div
        ref={contentRef}
        className={`flex-grow-1 ${visibleOverflow ? '' : 'overflow-auto'} ${
          childrenClassName ? childrenClassName : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default GenericModal;
