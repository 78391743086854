import {
  LoadingSpinner,
  AuthContainer,
} from '@rabbit/elements/shared-components';
import { useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { useState } from 'react';
import ForgotPasswordForm from '../components/templates/ForgotPasswordForm/ForgotPasswordForm';
import { useTranslation } from 'react-i18next';

function ForgotPasswordView() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState('');

  const navigate = useNavigate();
  if (isLoading) return <LoadingSpinner extraClasses={'mt-2'} size={'lg'} />;

  return (
    <div className="flex flex-grow flex-col justify-around">
      <AuthContainer
        title={t('message.resetPassword')}
        goBack={() => navigate(SAGE_ROUTE_NAME.LOGIN)}
      >
        <div>
          <ForgotPasswordForm
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
          {/* <div className="font-nunito my-4 text-base">
            Don't have an account? &nbsp;
            <NavLink
              to={ROUTE_NAME.SIGNUP}
              className="text-primary-600"
              reloadDocument
            >
              Create one
            </NavLink>
          </div> */}
        </div>
      </AuthContainer>
    </div>
  );
}

export default ForgotPasswordView;
