import { BL_Warranty } from '@rabbit/bizproc/core';
import { DTTenant_Public, Warranty } from '@rabbit/data/types';
import { getUnixTime } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  formatUnixTime,
  getClaimLimit,
  getCurrencyFormat,
  getIncreasedLabourRate,
} from '../../utils/helpers';
import { isWarrantyExpired } from '@rabbit/elements/shared-components';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { calcWarrantyRemaining } from '@rabbit/bizproc/react';
import { useGetMySagePersonas } from '@rabbit/data/portal';

export interface RecordType {
  label: string;
  value: string | Array<string> | boolean | number | undefined;
  ValueElement?: JSX.Element;
}

export type TenantType = 'carTenants' | 'tableTenants' | 'defaultTenants';

export default function useWarrantyTypes({
  warranty,
  type,
  tenantInfo,
}: {
  type: TenantType;
  warranty: Warranty;
  tenantInfo: DTTenant_Public['info'];
}): RecordType[] {
  const { t } = useTranslation();
  const { warrantorPersona } = useGetMySagePersonas();

  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';

  const remainingTime = calcWarrantyRemaining(warranty);

  let labourRateValue: any[] | null = [];
  if (type === 'carTenants') {
    labourRateValue = getIncreasedLabourRate(
      warranty,
      tenantInfo?.currency || 'GBP'
    );
  }

  const CFG_WARRANTY_INFO_URL = t('CFG_WARRANTY_INFO_URL');
  const CFG_WARRANTY_INFORMATION_URL = t('CFG_WARRANTY_INFO_URL');
  const CFG_WARRANTY_TERMS_URL = t('CFG_WARRANTY_INFO_URL');


  switch (type) {
    case 'defaultTenants':
      return [
        {
          label: t('general.warrantyId'),
          value: warranty?.warrantyLink?.slice(0, 5).toUpperCase(),
        },
        {
          label: t('general.registrationDate'),
          value: warranty?.registeredTime
            ? formatUnixTime(warranty.registeredTime, 'dd/MM/yyyy')
            : '-',
        },
        {
          label: t('general.startDate'),
          value: warranty?.startDate
            ? formatUnixTime(getUnixTime(warranty.startDate), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: t('general.expiryDate'),
          value: warranty?.endDate
            ? formatUnixTime(getUnixTime(warranty.endDate), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: t('general.term'),
          value: BL_Warranty.getWarrantyTerm(warranty),
        },
        {
          label: t('general.warrantyExpired'),
          value: warranty?.endDate ? isWarrantyExpired(warranty?.endDate) : '-',
        },
        {
          label: t('general.goTo'),
          value: '',
          ValueElement: RenderGoToUrl(
            CFG_WARRANTY_INFO_URL,
            'Terms & Conditions'
          ),
        },
        {
          label: t('general.warrantySummary'),
          value: '',
          ValueElement: RenderGoToUrl(
            CFG_WARRANTY_INFORMATION_URL,
            t('general.view')
          ),
        },
      ];
    case 'tableTenants':
      return [
        {
          label: t('general.id'),
          value: warranty?.warrantyLink?.slice(0, 5).toUpperCase(),
        },
        {
          label: t('general.servicePlanType'),
          value:
            warranty?.templateTitle +
              ((warranty?.decider?.stipulated as any).surfaceAreaClass.label ===
              'Standard'
                ? ' (Standard)'
                : '') || '-',
        },
        {
          label: t('general.registrationDate'),
          value: warranty?.registeredTime
            ? formatUnixTime(getUnixTime(warranty.registeredTime), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: t('general.startDate'),
          value: warranty?.startDate
            ? formatUnixTime(getUnixTime(warranty.startDate), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: t('general.expiryDate'),
          value: warranty?.endDate
            ? formatUnixTime(getUnixTime(warranty.endDate), 'dd/MM/yyyy')
            : '-',
        },
        ...(warrantorPersona !== null ? [{
          label: t('general.purchasePrice'),
          value: warranty?.decider?.decided?.totalPrice
            ? getCurrencyFormat(
                warranty?.decider?.decided?.totalPrice as number,
                tenantInfo?.currency as string
              )
            : '-',
        }]: []),
        {
          label: t('general.servicePlanTerm'),
          value: warranty?.duration.amount + ' ' + warranty?.duration.division,
        },
        {
          label: t('general.termRemaining'),
          value: remainingTime?.amount + ' ' + remainingTime?.unit,
        },
        {
          label: t('general.termExpired'),
          value: new Date() > new Date(warranty?.endDate || '') ? 'Yes' : 'No',
        },
        {
          label: t('general.extendedServicePlan'),
          value: 'Yes',
        },
        {
          label: t('general.goTo'),
          value: '',
          ValueElement: RenderGoToUrl(
            CFG_WARRANTY_INFO_URL,
            t('general.servicePlanTermsAndConditions')
          ),
        },
        // {
        //   label: t('general.troubleshootingGuide'),
        //   value: '',
        //   ValueElement: RenderGoToUrl(
        //     CFG_WARRANTY_INFO_URL,
        //     t('general.troubleshootingGuide')
        //   ),
        // },
      ];
    case 'carTenants':
      return [
        {
          label: t('general.warrantyId'),
          value: warranty?.warrantyLink?.slice(0, 5).toUpperCase(),
        },
        {
          label: t('general.registrationDate'),
          value:
            warranty.registeredTime && warranty.registeredTime > 0
              ? formatUnixTime(warranty.registeredTime, 'dd/MM/yyyy')
              : '-',
        },
        {
          label: t('general.startDate'),
          value: warranty?.startDate
            ? formatUnixTime(getUnixTime(warranty.startDate), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: t('general.expiryDate'),
          value: warranty?.endDate
            ? formatUnixTime(getUnixTime(warranty.endDate), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: t('general.term'),
          value: BL_Warranty.getWarrantyTerm(warranty),
        },
        {
          label: t('general.warrantyExpired'),
          value: warranty?.endDate ? isWarrantyExpired(warranty?.endDate) : '-',
        },
        {
          label: t('general.extendedWarranty'),
          value: 'Yes',
        },
        {
          label: t('general.claimLimit'),
          value: getClaimLimit(
            warranty,
            tenantInfo?.currency,
            CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT
          ),
        },
        ...(labourRateValue?.length
          ? [{ label: t('general.labourRate'), value: labourRateValue }]
          : []),
        {
          label: t('general.goTo'),
          value: '',
          ValueElement: RenderGoToUrl(
            CFG_WARRANTY_INFO_URL,
            t('general.termsAndConditions')
          ),
        },
      ];
    default: {
      return [];
    }
  }
}

const RenderGoToUrl = (url: string, label: string) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-1 text-base font-medium text-gray-700"
    >
      {label}
      <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
    </a>
  );
};
