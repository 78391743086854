import { useEffect, useRef, useState, useSyncExternalStore } from 'react';
import { useField, useFormikContext } from 'formik';
import { InputPictureSettingsShape } from '@rabbit/elements/shared-types';
import placeholderImageSrc from '../../../assets/images/picture_placeholder.png';
import Heading from '../../Heading/Heading';
import {
  CameraIcon,
  FolderIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import LoadingSpinner from '../../loaders/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';

export interface InputSelectFileProps {
  name: string;
  settings?: InputPictureSettingsShape;
}
export function InputPicture({ name, settings }: InputSelectFileProps) {
  const { uploadHandlers, placeholder, personaId, externalRef } =
    settings || {};
  const {
    uploadFiles,
    uploadedTempFiles,
    uploadedFiles,
    deleteFile,
    clearFileFromState,
    category,
  } = uploadHandlers || {};
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [field, meta] = useField(name);
  const { value, ...rest } = field;
  const { setFieldValue, setFieldTouched, setErrors } = useFormikContext();
  const ref = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState(
    field.value || placeholder || placeholderImageSrc
  );
  const [showOption, setShowOption] = useState(false);

  useEffect(() => {
    setPreviewImage(field.value || placeholder || placeholderImageSrc);
  }, [placeholder]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsLoading(true);
    const files = Array.prototype.slice.call(event.target.files);
    const reader = new FileReader();
    reader.onload = (function (theFile) {
      return async function (e: any) {
        if (e.target && uploadFiles && category) {
          await uploadFiles([theFile], personaId ?? 'W:Admin', category);
          void setFieldTouched(name, true);
        } else {
          setErrors(1);
        }
      };
    })(files[0]);
    reader.readAsDataURL(files[0]);
  }

  useEffect(() => {
    if (!uploadFiles?.length) return;

    if (uploadedFiles?.filesArr.length === 1) {
      setPreviewImage(uploadedFiles?.filesArr[0].url);
      void setFieldValue(name, uploadedFiles?.filesArr[0].url);
      setIsLoading(false);
      (
        (uploadedFiles?.filesArr.length && settings?.onChange) ||
        (() => void 0)
      )(uploadedFiles?.filesArr[0].url);
    }
  }, [uploadFiles]);

  const showUpdateOption = (e: React.MouseEvent<HTMLElement>) => {
    if (value !== undefined) {
      e.preventDefault();
      setShowOption(!showOption);
    }
  };

  const browsePhoto = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    ref.current?.click();
  };

  const removePhoto = () => {
    setPreviewImage(placeholderImageSrc);
    (settings?.onRemove || (() => void 0))(meta.value);
  };

  const defaultStyle = 'relative mx-auto w-fit bg-gray-100 cursor-pointer';

  if (isLoading)
    return (
      <div className="m-auto grid h-[140px] w-[140px] place-items-center overflow-hidden bg-gray-100">
        <LoadingSpinner size="xs" />
      </div>
    );

  return (
    <label
      className={
        meta?.touched && meta?.error?.length
          ? 'border-2 border-red-200 ' + defaultStyle
          : defaultStyle
      }
    >
      <span
        style={externalRef ? { display: 'none' } : {}}
        onClick={showUpdateOption}
        className="bg-primary-900 hover:bg-primary-700 absolute -right-2 -top-2 z-10 grid h-8 w-8 cursor-pointer place-items-center rounded-2xl text-sm text-white"
      >
        {meta.value ? (
          <CameraIcon className="h-4 w-4" />
        ) : (
          <PencilIcon className="h-4 w-4" />
        )}
        <div
          className={
            (showOption ? '' : 'hidden') +
            ' absolute top-[calc(100%+4px)] right-0 flex w-[260px] flex-col gap-6 rounded-md bg-white p-3 shadow-md'
          }
        >
          <div className="flex justify-between">
            <Heading kind="h5">{t('general.upload')}</Heading>
            <XMarkIcon className="w-6 text-gray-400" />
          </div>
          <div className="flex flex-col gap-4">
            <div
              ref={externalRef}
              className="font-nunito focus:border-1 bg-primary-900 hover:bg-primary-700 flex min-h-[41px] flex-shrink-0 flex-grow cursor-pointer items-center justify-center gap-2 rounded-lg px-4 py-2.5 text-base font-medium text-white"
              onClick={browsePhoto}
            >
              <FolderIcon className="h-[18px] w-[18px]" />
              {t('general.browse')}...
            </div>
            <div
              className="font-nunito focus:border-1 flex min-h-[41px] flex-shrink-0 flex-grow cursor-pointer items-center justify-center gap-2 rounded-lg border border-red-700 bg-white px-4 py-2.5 text-base font-medium text-red-700 hover:border-red-700 hover:text-red-700"
              onClick={removePhoto}
            >
              <TrashIcon className="h-[18px] w-[18px]" />
              {t('general.removeCurrentPhoto')}
            </div>
          </div>
        </div>
      </span>

      <input
        {...rest}
        id="file-upload"
        ref={ref}
        multiple={false}
        className="hidden"
        aria-describedby="file_input_help"
        type="file"
        accept={'image/*'}
        onChange={onChange}
        disabled={isLoading}
      />
      <div className="grid h-[140px] w-[140px] place-items-center overflow-hidden bg-gray-100">
        <img
          src={previewImage}
          alt="default placeholder"
          className="object-scale-down"
        />
      </div>
    </label>
  );
}

export default InputPicture;
