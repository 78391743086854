import { toast } from 'react-toastify';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { TenantInfo, Z_GazetteEmailContext } from '@rabbit/data/types';
import {
  FBD_Consumer_Private,
} from '@rabbit/data/types';
import { getConsumerURL } from 'apps/sage/src/utils/helpers';
import { z } from 'zod';
import { t } from 'i18next';
import { CORPORATE } from '@rabbit/sage/utils/consts.ts';

interface EmailNotificationParams {
  status: string;
  caseFlowCase: any;
  tenantInfo: TenantInfo | undefined;
  alterCasePublicEmail: (params: any) => Promise<void>;
  t: typeof t;
  comment_to_customer?: string;
}

export async function ClaimCaseEmailNotification({
  status,
  caseFlowCase,
  tenantInfo,
  alterCasePublicEmail,
  t,
  comment_to_customer,
}: EmailNotificationParams) {
  if (!alterCasePublicEmail) {
    throw new Error(t('Unable to send email notification!'));
  }

  const caseId = caseFlowCase.GetCaseId();
  const caseFacts = caseFlowCase.GetAllFacts();
  const consumerPersonaId = caseFacts?.consumer_persona_id;

  const consumerData = await FBD_Consumer_Private.get(consumerPersonaId);

  const to = caseFacts?.consumer_email ?? '';
  const from = tenantInfo?.emailInfo?.emailSender;
  const business_name = tenantInfo?.name ?? '';
  const first_name = caseFacts?.consumer_name ?? '';
  const product_name = caseFacts.consumer_holding_name ?? '';
  const link_to_claim =
    consumerData?.consumer_type === CORPORATE
      ? `${window.location.origin}/claims/${caseId}`
      : `${getConsumerURL()}/repairs/${caseId}`;

  let template: string;
  let subject: string;
  let email_context: z.infer<typeof Z_GazetteEmailContext>;

  switch (status) {
    case 'preliminary_assessment':
      email_context = 'preliminary_assessment';
      template = MANDRILL_TEMPLATES.BODY_PRELIM_ASSESSMENT_V2;
      subject = t('email.subject.preliminaryAssessment', {
        business_name,
        product_name,
      });
      break;
    case 'initially_assessed':
      email_context = 'initially_assessed';
      template = MANDRILL_TEMPLATES.BODY_INITIAL_ASSESSMENT_V2;
      subject = t('email.subject.claimUnderAssessment', {
        business_name,
        product_name,
      });
      break;
    case 'in_repair':
      email_context = 'approved_for_repair';
      template = MANDRILL_TEMPLATES.BODY_APPROVED_FOR_REPAIR_V2;
      subject = t('email.subject.claimApprovedForRepair', {
        business_name,
        product_name,
      });
      break;
    case 'repaired':
      email_context = 'repair_complete';
      template = MANDRILL_TEMPLATES.BODY_REPAIR_COMPLETE_V2;
      subject = t('email.subject.repairComplete', {
        business_name,
        product_name,
      });
      break;
    case 'closed':
      email_context = 'generic_close_case';
      template = MANDRILL_TEMPLATES.BODY_GENERIC_CLOSE_CASE_V2;
      subject = t('email.subject.claimClosed', { business_name, product_name });
      break;
    default:
      console.warn(`Unhandled status: ${status}`);
      return;
  }

  try {
    const emailSubstitutions: Record<string, string> = {
      subject,
      business_name,
      claim_id: caseId,
      first_name,
      product_name,
      link_to_claim,
    };

    if (comment_to_customer) {
      emailSubstitutions.comment_to_customer = comment_to_customer;
    }

    await alterCasePublicEmail({
      context: email_context,
      to,
      from,
      subject,
      template,
      substitutions: emailSubstitutions,
      shallBeSentViaBackend: true,
    });
  } catch (error) {
    toast.error('Failed to send email notification');
    console.log(error);
  }
}

