import * as Yup from 'yup';
import {
  Button,
  Heading,
  formatUnixTime,
  getClaimLimit,
  getCurrencyFormat,
  getFormattedDate,
  getIncreasedLabourRate,
} from '@rabbit/elements/shared-components';
import { VEHICLE_CATEGORY_OPTIONS } from '@rabbit/bizproc/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { getUnixTime } from 'date-fns';
import { titleCase } from '@rabbit/sage/utils/helpers';
import { MileageUnit } from '@rabbit/data/types';
import { useContext, useState } from 'react';
import { AppContext } from '@rabbit/app-context';

export const validationSchema = Yup.object().shape({
  registrationNo: Yup.string()
    .trim()
    .min(5, 'Enter a valid registration plate')
    .required('Enter a registration plate'),
});

export interface NewClaimVehicleSummaryProps {
  handleClose: () => void;
  onChange: any; //TODO
  setIsLoading: (loading: boolean) => void;
  data: any;
}

export function NewClaimVehicleSummary(props: NewClaimVehicleSummaryProps) {
  const { onChange, handleClose, data } = props;
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);

  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';

  const currentWarranty = data?.selectedWarranty || data?.warranties?.[0];
  const productInfo = data?.holding?.self_registration?.srvInfo?.productInfo;
  const consumer = data?.consumer;

  let expiredWarning = false;
  if (currentWarranty && currentWarranty?.endDate) {
    const expiryDate = new Date(currentWarranty?.endDate);
    const today = new Date();
    if (expiryDate < today) {
      expiredWarning = true;
    }
  }

  const [expand, setExpand] = useState(false);

  const defaultMileageUnit = t('CFG_COBRAND_MILEAGE_UNIT', 'km') as MileageUnit;
  const carRecord = [
    // {
    //   label: 'Registration Plate',
    //   value: String(productInfo?.registrationNo).toUpperCase(),
    // },
    // { label: 'Make', value: productInfo?.make },
    // { label: 'Model', value: productInfo?.model },
    { label: t('general.version'), value: productInfo?.version },
    { label: t('general.body'), value: productInfo?.body || '-' },
    {
      label: t('general.registrationDate'),
      value: productInfo?.regDate
        ? getFormattedDate(productInfo?.regDate)
        : '-',
    },
    { label: t('general.engineCc'), value: productInfo?.engineCc || '-' },
    { label: t('general.colour'), value: productInfo?.colour || '-' },
    { label: t('general.fuel'), value: productInfo?.fuel || '-' },
    {
      label: t('general.transmission'),
      value: productInfo?.transmission || '-',
    },
    { label: t('general.driveType'), value: productInfo?.driveType || '-' },
    {
      label: t('general.yearOfManufacture'),
      value: productInfo?.yearOfManufacture || '-',
    },
    { label: t('general.vin'), value: productInfo?.chassisNo || '-' },
    { label: t('general.engineNumber'), value: productInfo?.engineNo || '-' },
    {
      label: t('general.currentMileage'),
      value: productInfo?.mileage
        ? Number(productInfo?.mileage).toLocaleString(tenantInfo?.country) +
            productInfo?.mileageUnit || defaultMileageUnit
        : '-',
    },
    {
      label: t('general.lastServiceDate'),
      value: productInfo?.last_service_date
        ? formatUnixTime(
            getUnixTime(new Date(productInfo?.last_service_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: t('general.motDateExpiration'),
      value: productInfo?.tech_check_date
        ? formatUnixTime(
            getUnixTime(new Date(productInfo?.tech_check_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: t('general.commercialVehicle'),
      value: productInfo?.commercialVehicle
        ? titleCase(productInfo?.commercialVehicle)
        : '-',
    },
    {
      label: t('general.vehicleCategory'),
      value: productInfo?.vehicleCategory
        ? VEHICLE_CATEGORY_OPTIONS.find(
            (i) => i.value === productInfo?.vehicleCategory
          )?.label
        : '-',
    },
    {
      label: t('general.purchasePrice'),
      value: productInfo?.purchasePrice
        ? getCurrencyFormat(
            productInfo?.purchasePrice.amount,
            productInfo?.purchasePrice.currency
          )
        : '-',
    },
  ];

  const labourRate = getIncreasedLabourRate(
    currentWarranty,
    tenantInfo?.currency || 'GBP'
  );

  return (
    <div className="flex flex-col gap-4 px-5 pt-4">
      {/* Customer car */}
      <div>
        <Heading kind="h4" className="mb-2">
          {t('Customer details')}
        </Heading>
        <div className="font-nunito flex flex-col gap-1 rounded-md border border-gray-300 p-4">
          <p>
            <span className="text-gray-500">Name:</span> {consumer?.firstName}{' '}
            {consumer?.lastName}
          </p>
          <p>
            <span className="text-gray-500">Email:</span>{' '}
            {consumer?.consumerEmail}
          </p>
          {consumer?.address && (
            <p>
              <span className="text-gray-500">Address:</span>{' '}
              {consumer?.address?.postcode} {consumer?.address?.line1},{' '}
              {consumer?.address?.line2} {consumer?.address?.town}{' '}
              {consumer?.address?.state} {consumer?.address?.country}
            </p>
          )}
          {consumer?.phoneNumber && (
            <p>
              <span className="text-gray-500">Phone:</span> +
              {consumer?.phoneNumber}
            </p>
          )}
        </div>
      </div>

      {/* Selected car */}
      <div>
        <Heading kind="h4" className="mb-2">
          {t('Vehicle details')}
        </Heading>
        <div className="flex flex-col gap-4">
          <div
            className={'font-nunito rounded-md border border-gray-300 p-4'}
            onClick={() => 0}
          >
            <div
              className="grid items-center gap-4"
              onClick={() => setExpand(!expand)}
            >
              {/* <div className="h-[64px] rounded-md bg-red-700">
                <img
                  src={productPlaceholder}
                  className="h-[64px] w-[64px] object-cover"
                />
              </div> */}
              <div className="flex h-fit cursor-pointer items-center justify-between">
                <div className="flex flex-col gap-1">
                  <p>
                    <span className="text-gray-500">Make:</span>{' '}
                    {productInfo?.make || productInfo?.brand}
                  </p>
                  <p>
                    <span className="text-gray-500">Model:</span>{' '}
                    {productInfo?.model} {productInfo?.version}
                  </p>
                  <p>
                    <span className="text-gray-500">Registration plate:</span>{' '}
                    <span className="uppercase">
                      {productInfo.registrationNo}
                    </span>
                  </p>
                  {productInfo?.cherishedRegistrationNo && (
                    <p>
                      <span className="text-gray-500">Cherished plate:</span>{' '}
                      <span className="uppercase">
                        {productInfo.cherishedRegistrationNo}
                      </span>
                    </p>
                  )}
                </div>
                <ChevronDownIcon
                  className={
                    'relative h-6 w-6 text-gray-900' +
                    (expand ? ' rotate-180' : '')
                  }
                />
              </div>
            </div>
            {expand && (
              <div className="mt-6 grid grid-cols-3 gap-4">
                {carRecord.map((record, index) => (
                  <div key={'record' + index} className="flex flex-col">
                    <span className="font-sm text-gray-500">
                      {record?.label}
                    </span>
                    <div className="font-medium text-black">
                      {record?.value}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Warranty info */}
      <div>
        <Heading kind="h4" className="mb-2">
          {t('Warranty details')}
        </Heading>
        <div className="flex flex-col gap-4">
          <div
            className={
              'font-nunito flex flex-col gap-2 rounded-md border border-gray-300 p-4'
            }
          >
            <div className="flex gap-2">
              <span className="font-semibold text-black">
                {currentWarranty?.templateTitle}
              </span>
              {expiredWarning && (
                <span className="flex items-center rounded bg-red-100 py-1 px-2 text-xs capitalize text-red-700">
                  {t('Expired')}
                </span>
              )}
            </div>
            <div>
              <span className="text-gray-500">Start date:</span>{' '}
              {getFormattedDate(currentWarranty?.startDate)}
            </div>
            <div>
              <span className="text-gray-500">Term:</span>{' '}
              {currentWarranty?.decider?.stipulated?.duration?.label}
            </div>
            <div>
              <span className="text-gray-500">Claim limit:</span>{' '}
              {getClaimLimit(
                currentWarranty,
                tenantInfo?.currency,
                CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT
              )}
            </div>
            {labourRate?.length ? (
              <div>
                <span className="text-gray-500">Labour rate:</span> {labourRate}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="sticky bottom-0 -mb-2 flex w-full gap-4 bg-white py-4">
        <Button kind="primary" type="submit" onClick={() => onChange(2, data)}>
          {t('Continue')}
        </Button>
        <Button kind="red" type="submit" onClick={handleClose}>
          {t('Cancel')}
        </Button>
      </div>
    </div>
  );
}

export default NewClaimVehicleSummary;
