import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import Layout from '../components/templates/Layout/Layout';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { AppContext } from '@rabbit/app-context';
import { FileStorageProviderWrapper } from '@rabbit/bizproc/react';

const PublicRoutes = () => {
  const isDemoEnv = import.meta.env.DEMO;
  const contextValues = useContext(UserContext);
  const { config } = useContext(AppContext);

  let redirectUrl = localStorage.getItem('redirectUrl');
  if (redirectUrl && window.location.pathname !== '/login') {
    localStorage.removeItem('redirectUrl');
  }

  return contextValues !== null &&
    !contextValues.isLoading &&
    contextValues.user == null ? (
    <FileStorageProviderWrapper noIdentity={true}>
      <Layout>
        <Outlet />
      </Layout>
    </FileStorageProviderWrapper>
  ) : (
    <Navigate
      to={
        isDemoEnv
          ? SAGE_ROUTE_NAME.DASHBOARD
          : redirectUrl
          ? redirectUrl
          : config.NAVIGATION.SAGE.DEFAULT_PATH
      }
    />
  );
};

export default PublicRoutes;
