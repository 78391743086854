import {
  Button,
  formatUnixTime,
  getCurrencyFormat,
  Heading,
} from '@rabbit/elements/shared-components';
import { getUnixTime } from 'date-fns';
import {
  formatDate,
  getVehicleCategoryByValue,
  titleCase,
} from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';
import { MileageUnit } from '@rabbit/data/types';
import { CarRegistrationShape } from './PWCreateNewRegistration';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { useContext } from 'react';
import { AppContext } from '@rabbit/app-context';

export interface NewRegistrationDetailsFormProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape & {
    mileage: string;
    mileageUnit: string;
    body: string;
    regDate: string;
    colour: string;
    engineNo: string;
    purchasePrice: {
      amount: string;
      currency: string;
    };
  };
}

export function PWNewRegistrationDetailsForm(
  props: NewRegistrationDetailsFormProps
) {
  const { onChange, handleClose, data } = props;
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const defaultMileageUnit = t('CFG_COBRAND_MILEAGE_UNIT', 'km') as MileageUnit;

  const carRecord = [
    {
      label: t('general.registrationNo'),
      value: String(data.registrationNo).toUpperCase(),
    },
    { label: t('general.make'), value: data.make },
    { label: t('general.model'), value: data.model },
    { label: t('general.version'), value: data.version },
    { label: t('general.body'), value: data.body || '-' },
    {
      label: t('general.registrationDate'),
      value: data.regDate ? formatDate(new Date(data.regDate)) : '-',
    },
    { label: t('general.engineCc'), value: data.engineCc || '-' },
    { label: t('general.colour'), value: data.colour || '-' },
    { label: t('general.fuel'), value: data.fuel || '-' },
    { label: t('general.transmission'), value: data.transmission || '-' },
    { label: t('general.driveType'), value: data.driveType || '-' },
    {
      label: t('general.yearOfManufacture'),
      value: data.yearOfManufacture || '-',
    },
    { label: t('general.vin'), value: data.chassisNo || '-' },
    { label: t('general.engineNumber'), value: data.engineNo || '-' },
    {
      label: t('general.currentMileage'),
      value: data.mileage
        ? Number(data.mileage).toLocaleString(tenantInfo?.country) +
            data.mileageUnit || defaultMileageUnit
        : '-',
    },
    {
      label: t('general.lastServiceDate'),
      value: data.last_service_date
        ? formatUnixTime(
            getUnixTime(new Date(data.last_service_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: t('general.motDateExpiration'),
      value: data.tech_check_date
        ? formatUnixTime(
            getUnixTime(new Date(data.tech_check_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: t('general.commercialVehicle'),
      value: data.commercialVehicle ? titleCase(data.commercialVehicle) : '-',
    },
    {
      label: t('general.vehicleCategory'),
      value: data.vehicleCategory
        ? getVehicleCategoryByValue(data.vehicleCategory)?.label ?? '-'
        : '-',
    },
    {
      label: t('general.purchasePrice'),
      value: data.purchasePrice
        ? getCurrencyFormat(
            data.purchasePrice.amount,
            data.purchasePrice.currency
          )
        : '-',
    },
    {
      label: t('general.cherishedPlate'),
      value: data.cherishedRegistrationNo
        ? String(data.cherishedRegistrationNo).toUpperCase()
        : '-',
    },
  ];

  return (
    <div className="p-4">
      <div className="mb-4">
        <Heading kind="h4" className="mb-2">
          Selected car
        </Heading>
        <div className="font-nunito rounded-md border border-gray-300 p-4">
          <div className="font-bold text-black">
            {data.make} {data.model} {data.version}
          </div>
          <div className="mb-6 text-black">
            {BL_Warranty.getVehicleRegistrationNo(data)}
          </div>
          <div className="grid grid-cols-3 gap-4">
            {carRecord.map((record, index) => (
              <div key={'record' + index} className="flex flex-col">
                <span className="font-sm text-gray-500">{record.label}</span>
                <div className="font-medium text-black">{record.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-5 flex w-full gap-4">
        <Button kind="primary" type="submit" onClick={() => onChange(3, data)}>
          Continue
        </Button>
        <Button kind="red" type="submit" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default PWNewRegistrationDetailsForm;
