import { Modal, ModalProps } from '@rabbit/elements/shared-components';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DTConsumer_Private } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import { toast } from 'react-toastify';

export interface ModalTermsConditionsProps {
  consumerPersonaData: DTConsumer_Private;
  consumerPersonaId: string;
}

export function ModalTC({ handleClose }: { handleClose: () => void }) {
  const { tenantInfo } = useContext(AppContext);
  const [modalSettings, setModalSettings] = useState<ModalProps>(
    {} as ModalProps
  );
  const modalSettingsOptions = useMemo(() => {
    const settings = {
      warning: {
        kind: 'pop-up' as const,
        settings: {
          text: `In order to use iWarranty, please read and confirm that you accept our terms and conditions. <br/><br/>By confirming, you also agree with <strong>
      ${tenantInfo?.name}
      </strong> <a href="${tenantInfo?.warrantyInfoUrl}" target="_blank">Terms and Conditions</a>.`,
          primaryButtonText: 'Confirm',
          outlineButtonText: 'View Terms',
          handlePrimaryClick: () => onAcceptTerms(),
          handleOutlineClick: () => onShowTerms(),
        },
      },
      terms: {
        kind: 'tc' as const,
        settings: {
          title: 'Terms and conditions',
          text: '', //hardcoded in TextModal.tsx
          primaryButtonText: 'I accept',
          handleClose: () => onShowWarning(),
          handlePrimaryClick: () => onAcceptTerms(),
        },
        className: 'w-full max-w-[729px] bg-white',
      },
    };
    setModalSettings(settings.warning);
    return settings;
  }, [tenantInfo?.name]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const onAcceptTerms = () => {
    toast.success('Terms and conditions accepted!');
    handleClose();
  };

  const onShowWarning = () => {
    setModalSettings(modalSettingsOptions.warning);
  };

  const onShowTerms = () => {
    setModalSettings(modalSettingsOptions.terms);
  };

  if (!tenantInfo) return;

  return (
    <Modal
      kind={modalSettings?.kind}
      settings={modalSettings.settings}
      className={modalSettings.className}
    />
  );
}

export default ModalTC;
