import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import {
  AssessmentPart,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import { useManageFaults } from '@rabbit/bizproc/react';
import { AppContext } from '@rabbit/app-context';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import {
  Button,
  CardWrapperWithHeader,
  LabelledItemList,
  LabelledItemShape,
  Table,
} from '@rabbit/elements/shared-components';
import UpdateClaimAssessmentModal from '@rabbit/sage/components/organisms/case-flow-modals/UpdateClaimAssessmentModal/UpdateClaimAssessmentModal';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import { stripTags } from '@rabbit/sage/utils/helpers';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { partsAssessmentData } from '@rabbit/sage/components/molecules/claims/ClaimCostsSection/ClaimCostsSectionTableColumns.tsx';
import { CreateTable } from '@rabbit/sage/utils/CreateTable.tsx';

export default function ClaimAssessmentSection() {
  const { holdingData, caseFacts, operatingPersona } =
    useContext(CaseflowContext) || {};
  const { t } = useTranslation();
  const { config } = useContext(AppContext);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { faultList } = useManageFaults();
  const faultOptions = !faultList?.data
    ? []
    : faultList.data.map((fault) => ({
        id: fault.docid,
        value: fault.docid,
        label: fault.title,
        name: fault.title,
      }));

  const items: any[] = [];
  caseFacts?.repair_work_registry?.forEach((item) => {
    items.push(...item.parts_used_for_repair);
  });
  const parts = caseFacts?.assessment_parts?.map((part) => part.name);
  const totalParts = caseFacts?.assessment_parts;

  const record: LabelledItemShape[] = [
    ...(config.CLAIMS.CLAIMS_FLOW.PARTS_ASSESSMENT
      ? []
      : [
          {
            label: t('general.parts'),
            value: parts?.join(', ') || '-',
          },
        ]),
    {
      label: t('general.faults'),
      value:
        caseFacts?.holding_faults
          ?.map((id) => faultOptions.find((f) => f.id === id)?.label)
          .join(', ') || '-',
    },
    {
      label: t('general.preliminaryAssessment'),
      value: caseFacts?.preliminary_assessment || '-',
    },
    {
      label: t('general.finalAssessment'),
      value: caseFacts?.final_assessment || '-',
    },
    {
      label: t('general.outcome'),
      value: caseFacts?.claim_outcome || '-',
    },
    {
      label: t('general.cause'),
      value: caseFacts?.claim_cause || '-',
    },
    {
      label: t('general.additionalComment'),
      value: stripTags(caseFacts?.assessment_comment || '') || '-',
    },
  ];

  const isRepairer = operatingPersona?.startsWith(
    PersonaTypeSingleLetter.Repairer + PersonaIdTypeSplitter
  );

  let hideAssessment = false;
  if (isRepairer) {
    if (!caseFacts?.external_repair_invoices) return;
    const invoiceNotApproved =
      (
        caseFacts?.external_repair_invoices
          ?.map((invoice) => {
            return invoice?.status !== ExternalRepairInvoiceStatus.APPROVED;
          })
          .filter((i) => i) || []
      )?.length > 0;

    if (invoiceNotApproved) {
      hideAssessment = true;
    } else if (caseFacts?.final_assessment === 'Not covered') {
      hideAssessment = true;
    }
  }

  const renderPartsTable = (
    label: string,
    columns: any,
    data: AssessmentPart[]
  ) => {
    const PartsTable = CreateTable<AssessmentPart>(data || [], columns);
    return (
      <div>
        <CardWrapperWithHeader
          title={label}
          canCollapse={true}
          collapsedByDefault={true}
          smaller={true}
          noPadding={true}
        >
          <div className="max-w-[757px] border-gray-100">
            <PartsTable
              enablePagination={data.length > 10}
              initialState={{
                showGlobalFilter: true,
              }}
              muiSearchTextFieldProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiTableHeadCellProps={{
                className: 'relative bg-gray-200 uppercase font-light',
              }}
              muiTableBodyCellProps={{
                className: 'px-4 py-0',
              }}
            />
          </div>
        </CardWrapperWithHeader>
      </div>
    );
  };

  return (
    <CardWrapperWithHeader
      title={t('general.claimAssessment')}
      headerRight={
        !hideAssessment && (
          <Button
            kind="primary"
            size="sm"
            onClick={() => setShowUpdateModal(true)}
            icon={<ClipboardDocumentIcon />}
            children={t('general.updateAssessment')}
          />
        )
      }
    >
      <LabelledItemList
        items={record}
        itemProps={{
          labelClasses: 'font-nunito mb-[10px] text-sm text-gray-500',
          valueClasses: 'font-nunito font-medium',
        }}
      />
      {config.CLAIMS.CLAIMS_FLOW.PARTS_ASSESSMENT &&
        totalParts &&
        totalParts.length > 0 &&
        renderPartsTable(
          `${t('general.defectiveParts')}`,
          partsAssessmentData(t, faultOptions),
          totalParts
        )}
      {showUpdateModal && (
        <UpdateClaimAssessmentModal onClose={() => setShowUpdateModal(false)} />
      )}
    </CardWrapperWithHeader>
  );
}
