import { Form, Formik } from 'formik';
import {
  Button,
  InfoText,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CaseFlowActions_Fatbikes_ExternalRepairer } from '@rabbit/bizproc/core';

export interface InitiateRepairModalProps {}

interface FormValuesShape {
  external_repairer_comment: string;
}

const validationSchema = Yup.object().shape({
  external_repairer_comment: Yup.string().trim(),
});

export function InitiateRepairModal({}: InitiateRepairModalProps) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);

  const initialValues = {
    external_repairer_comment: '',
  };

  const {
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const { external_repairer_comment } = values;

    setIsSubmitting(true);

    executeAction(CaseFlowActions_Fatbikes_ExternalRepairer.InitiateRepair);

    const factsToAlter = {
      ...(external_repairer_comment && {
        external_repairer_comment: {
          comment: external_repairer_comment,
          author: operatingPersona,
        },
      }),
    };

    if (Object.keys(factsToAlter).length > 0) {
      await alterCaseFacts(factsToAlter);
    }

    setShowModal(false);
    toast.success(t('Claim updated successfully.'));
  };

  return (
    <Modal
      settings={{
        title: t('Initiate repair'),
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <InfoText containerStyles="mb-2 flex gap-1 text-gray-500">
              {t(
                'This action will initiate the repair process. If necessary, you may provide any additional internal comments here.'
              )}
            </InfoText>
            {/* <div className="mt-4">
              <Input
                type="rich-text"
                name="comment_to_customer"
                label="Comment to customer"
                settings={{
                  id: 'comment_to_customer',
                  placeholder: 'Comment to customer',
                }}
              />
            </div> */}
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  {t('Add comment')}
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label={t('Comment')}
                  name="external_repairer_comment"
                  settings={{
                    id: 'external_repairer_comment',
                    placeholder: '',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button kind="primary" type="submit" loading={isSubmitting}>
                {t('Initiate repair')}
              </Button>
              <Button kind="outline_red" onClick={() => setShowModal(false)}>
                {t('Cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default InitiateRepairModal;
