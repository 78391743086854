import {
  DTConsumer_Private,
  DTHoldingProxy,
  Warranty,
} from '@rabbit/data/types';
import {
  CardWrapperWithHeader,
  formatUnixTime,
  Table,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { format, getUnixTime } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getWarrantyTermsData } from '@rabbit/sage/utils/helpers.tsx';

interface WarrantyHoldingDetailsSectionProps {
  allHoldingsList?: DTHoldingProxy[] | null;
  consumer_private?: DTConsumer_Private | null;
  holding_proxy?: DTHoldingProxy | null;
  holdingId: string;
  storeAddress: string;
}

const tableHeaders = (t: any) => [
  {
    header: t('general.warranty'),
    accessorKey: 'warranty',
    size: 150,
  },
  {
    header: t('general.coverage'),
    accessorKey: 'coverage',
    size: 200,
  },
  {
    header: t('general.term'),
    accessorKey: 'term',
    size: 15,
  },
  {
    header: t('general.startDate'),
    accessorKey: 'start_date',
    size: 15,
  },
  {
    header: t('general.endDate'),
    accessorKey: 'end_date',
    size: 15,
  },
  {
    header: t('general.mileageLimit'),
    accessorKey: 'mileage_limit',
    size: 15,
  },
];

const tableHeadersTerms = (t: any) => [
  {
    header: t('general.category'),
    accessorKey: 'category',
    size: 10,
  },
  {
    header: t('general.details'),
    accessorKey: 'details',
    size: 500,
  },
];

const formatResultsForTerms = (warrantyTerms: any) => {
  const results = [];
  if (warrantyTerms) {
    const termsHeader = Object.keys(warrantyTerms);

    for (let i = 0; i < termsHeader.length; i++) {
      const termSingle = warrantyTerms[termsHeader[i]];
      const termSingleObj = Object.keys(termSingle);
      results[i] = {
        category: termsHeader[i].replace(/([A-Z])/g, ' $1').trim(),
        details: '',
        subRows: [] as any,
      };
      for (let j = 0; j < termSingleObj.length; j++) {
        if (results[i] && results[i].subRows) {
          results[i].subRows.push({
            category: termSingleObj[j].replace(/([A-Z])/g, ' $1').trim(),
            details: termSingle[termSingleObj[j]],
          });
        }
      }
    }
  }
  return results;
};

const formatResultsForPartnersTable = (warrantyInfo: Warranty) => {
  return [
    {
      warranty: 'Basic',
      coverage: 'Defects',
      term: '12 months',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('12', 'months')
            .subtract('1', 'day')
            .format('DD/MM/YY')
        : '-',
      mileage_limit: '120,000 kms',
    },
    {
      warranty: 'Structural',
      coverage: 'Moving components',
      term: '24 months',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('24', 'months')
            .subtract('1', 'day')
            .format('DD/MM/YY')
        : '-',
      mileage_limit: '240,000 kms',
    },
    {
      warranty: 'Structural',
      coverage: 'Fixed components',
      term: '60 months',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('60', 'months')
            .subtract('1', 'day')
            .format('DD/MM/YY')
        : '-',
      mileage_limit: '600,000 kms',
    },

    {
      warranty: 'Paint',
      coverage: 'Rust perforation from defects',
      term: '60 months',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('60', 'months')
            .subtract('1', 'day')
            .format('DD/MM/YY')
        : '-',
      mileage_limit: '600,000 kms',
    },
    {
      warranty: 'Repairs',
      coverage: 'Repaired or replacement part defects',
      term: '90 days',
      start_date: '',
      end_date: '',
      mileage_limit: '',
    },
  ];
};

export default function WarrantyDetailsSection({
  allHoldingsList,
  holding_proxy,
  consumer_private,
  holdingId,
  storeAddress,
}: WarrantyHoldingDetailsSectionProps) {
  const { t } = useTranslation();
  const warrantyInfo = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);
  const [warrantyTerms, setWarrantyTerms] = useState<any>();

  useEffect(() => {
    if (window) window.localStorage.removeItem('LRP_VendableId');
    const getWarranty = async () => {
      const warranty = await getWarrantyTermsData(t('tenantLink'));
      if (warranty) {
        setWarrantyTerms(warranty.warranty_terms);
      }
    };
    void getWarranty();
  }, [t('tenantLink')]);

  return (
    <>
      <CardWrapperWithHeader title={t('general.warrantyTerms')}>
        <div>
          <Table
            enableSearch={false}
            enablePagination={false}
            enableRowSelection={false}
            enableRowExpansion={false}
            enableSorting={false}
            enableTopToolbar={false}
            columns={tableHeaders(t)}
            data={formatResultsForPartnersTable(warrantyInfo!)}
            initialState={{
              columnPinning: { right: ['actions'] },
              showCustomFilter: false,
            }}
          />
        </div>
      </CardWrapperWithHeader>
      <CardWrapperWithHeader title={t('general.warrantySummary')}>
        <div>
          <Table
            enableSearch={false}
            enablePagination={false}
            enableRowSelection={false}
            enableRowExpansion={false}
            enableSorting={false}
            enableTopToolbar={false}
            columns={tableHeadersTerms(t)}
            enableExpanding={true}
            enableExpandingAll={true}
            data={formatResultsForTerms(warrantyTerms!)}
            initialState={{
              density: 'compact',
              columnPinning: { right: ['actions'] },
              showCustomFilter: false,
            }}
          />
        </div>
      </CardWrapperWithHeader>
    </>
  );
}
