import { useContext, useMemo, useState } from 'react';
import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  CheckIcon,
} from '@heroicons/react/24/solid';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LoadingSpinner,
  Modal,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import ModalAddEditInvoice, {
  ModalAddEditInvoiceModes,
} from '@rabbit/sage/components/organisms/ModalAddEditInvoice/ModalAddEditInvoice';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import {
  CaseFlowStations_Fatbikes,
  CFCF_ExternalRepairInvoice,
  ExternalRepairInvoiceStatus,
  ExternalRepairInvoiceType,
  CaseFlowActions_Fatbikes_Warrantor,
} from '@rabbit/bizproc/core';
import { FileStorageContext } from '@rabbit/bizproc/react';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';
import { CreateTable } from '@rabbit/sage/utils/CreateTable';
import { AppContext } from '@rabbit/app-context';

// TODO: ADD APPROVE / REJECT FOR WARRANTORS
import { getActionIcon } from '@rabbit/sage/utils/consts';

export default function ClaimInvoiceSection() {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const caseFlowContext = useContext(CaseflowContext);
  const fileStorageContext = useContext(FileStorageContext);

  // Extract values from contexts
  const {
    caseFacts,
    alterCaseFacts,
    operatingPersonaSingleKey,
    caseState,
    caseActionsStation,
    caseAlterability,
    moveSpotlight,
    executeAction,
  } = caseFlowContext || {};
  const { deleteFile } = fileStorageContext || {};
  const external_repair_invoices = caseFacts?.external_repair_invoices || [];

  // State declarations
  // this should really only be one state but I can't be bothered to refactor it right now. - DC
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [modalData, setModalData] = useState<{
    data: CFCF_ExternalRepairInvoice;
    mode?: ModalAddEditInvoiceModes;
  } | null>(null);

  // Constants
  const caseTitleMap: Record<string, string> = {
    [CaseFlowStations_Fatbikes.PROFORMA_INVOICE]: 'general.proformaInvoices',
    [CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION]:
      'general.repairInvoices',
    default: 'general.invoices',
  };

  const caseStateButtonLabelMap: Record<string, string> = {
    [CaseFlowStations_Fatbikes.PROFORMA_INVOICE]: 'general.addProformaInvoice',
    [CaseFlowStations_Fatbikes.PROFORMA_INVOICE_SUBMISSION]:
      'general.submitProformaInvoice',
    [CaseFlowStations_Fatbikes.REPAIR_INVOICE]: 'general.addInvoice',
    [CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION]:
      'general.submitInvoice',
  };

  // Memoized values
  const unsubmittedInvoices = useMemo(
    () =>
      external_repair_invoices.filter(
        (invoice) => invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED
      ),
    [external_repair_invoices]
  );

  // Helper functions
  const getSectionTitle = () =>
    t(caseTitleMap[caseState] || caseTitleMap.default);

  const renderNoInvoiceText = () => {
    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer) {
      if (caseState === CaseFlowStations_Fatbikes.REPAIR_INVOICE_SUBMISSION) {
        return t('message.noInvoicesAddedMessage');
      } else if (caseState === CaseFlowStations_Fatbikes.PROFORMA_INVOICE) {
        return t('message.noProformaInvoicesAddedMessage');
      } else {
        return t('message.noInvoicesAdded');
      }
    }
    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor) {
      return t('message.noInvoicesAddedByRepairer');
    }
  };
  const currentStation = Object.keys(caseActionsStation)[0] || '';

  const renderSectionHeaderRight = () => {
    if (!caseStateButtonLabelMap[caseState]) {
      return null;
    }

    if (
      unsubmittedInvoices.length === 0 ||
      caseState === CaseFlowStations_Fatbikes.REPAIRED
    ) {
      return (
        <ButtonIcon
          type="primary"
          label={t(caseStateButtonLabelMap[caseState])}
          Icon={getActionIcon(currentStation)}
          iconLeft
          onClick={() => {
            setModalData(null);
            setInvoiceModal(true);
          }}
        />
      );
    }

    if (
      unsubmittedInvoices.length > 0 &&
      operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer
    ) {
      if (caseAlterability) {
        return (
          <ButtonIcon
            type="primary"
            label={t(caseStateButtonLabelMap[caseState])}
            iconLeft
            Icon={getActionIcon(currentStation)}
            onClick={() => {
              setModalData({
                data: unsubmittedInvoices[0],
                mode: 'submit',
              });
              setInvoiceModal(true);
            }}
          />
        );
      } else {
        return (
          <ButtonIcon
            type="primary"
            label={`${t('general.loading')}...`}
            Icon={PlusIcon}
            iconLeft
            onClick={() => void 0}
            disabled
          />
        );
      }
    }

    return null;
  };

  // Handlers
  const deleteInvoice = async () => {
    if (!modalData?.data || !alterCaseFacts || !deleteFile) return;

    const newInvoices = external_repair_invoices.filter(
      (invoice) => invoice.timestamp !== modalData.data.timestamp
    );

    await alterCaseFacts({ external_repair_invoices: newInvoices });

    if (modalData.data.file.metadata.fullPath) {
      await deleteFile(modalData.data.file.metadata.fullPath, {
        category: UploadedFileCategories.ExternalRepairInvoices,
      });
    }
  };

  const updateInvoiceStatus = async (
    incoming: CFCF_ExternalRepairInvoice,
    status: ExternalRepairInvoiceStatus
  ) => {
    if (!moveSpotlight || !executeAction || !alterCaseFacts) return;

    setLoading(true);
    try {
      const factsToAlter = {
        external_repair_invoices: external_repair_invoices.map((invoice) =>
          invoice.timestamp === incoming?.timestamp
            ? { ...invoice, status }
            : invoice
        ),
      };

      await moveSpotlight(PersonaTypeSingleLetter.Repairer);
      await executeAction(
        status === ExternalRepairInvoiceStatus.APPROVED
          ? CaseFlowActions_Fatbikes_Warrantor.ApproveInvoice
          : CaseFlowActions_Fatbikes_Warrantor.RejectInvoice
      );
      await alterCaseFacts(factsToAlter);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (invoice: CFCF_ExternalRepairInvoice) => {
    let mode: ModalAddEditInvoiceModes = 'submit';

    if (operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer) {
      if (
        invoice.status === ExternalRepairInvoiceStatus.REJECTED ||
        invoice.status === ExternalRepairInvoiceStatus.APPROVED
      ) {
        mode = 'repairer-review';
      } else if (invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED) {
        mode = 'submit';
      } else {
        mode = 'add-edit';
      }
    }

    setModalData({
      data: invoice,
      mode: mode || 'add-edit',
    });
    setInvoiceModal(true);
  };

  // Table creation
  const InvoiceTable = useMemo(
    () =>
      CreateTable<CFCF_ExternalRepairInvoice>(external_repair_invoices, [
        {
          header: t('general.document'),
          value: (invoice) => invoice.file.ogFilename,
          size: 180,
          Cell: ({ cell, row }: any) => (
            <div
              className="flex w-full cursor-pointer items-center gap-2 break-all text-green-600 underline underline-offset-4"
              onClick={(e) => {
                e.stopPropagation();
                window.open(row.original.actions.file.url, '_blank');
              }}
            >
              <DocumentIcon className="h-4 w-4" />
              {cell.getValue()}
            </div>
          ),
        },
        {
          header: t('general.type'),
          type: 'Status',
          value: (invoice) =>
            t(`${invoice.invoiceType || ExternalRepairInvoiceType.REPAIR}`),
        },
        {
          header: t('general.date'),
          type: 'Date',
          value: (invoice) => invoice.timestamp,
        },
        {
          header: t('general.status'),
          type: 'Status',
          value: (invoice) => invoice.status,
        },
        {
          header: t('general.cost'),
          value: (invoice) =>
            getCurrencyFormat(
              invoice.totalCost.amount,
              tenantInfo?.currency ?? 'GBP'
            ),
        },
        {
          header: '',
          accessorKey: 'actions',
          size: 15,
          value: (invoice) => invoice,
          Cell: ({ cell }: any) => {
            const invoice = external_repair_invoices[cell.row.index];
            const invoiceStatus = invoice.status;

            if (
              operatingPersonaSingleKey === PersonaTypeSingleLetter.Repairer
            ) {
              if (
                invoiceStatus === ExternalRepairInvoiceStatus.REJECTED ||
                invoiceStatus === ExternalRepairInvoiceStatus.APPROVED
              ) {
                return (
                  <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                    <MagnifyingGlassIcon
                      className="h-[20px] w-[20px]"
                      onClick={() => {
                        setModalData({
                          data: invoice,
                          mode: 'repairer-review',
                        });
                        setInvoiceModal(true);
                      }}
                    />
                  </div>
                );
              }
              return (
                <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                  <PencilSquareIcon
                    className="h-[20px] w-[20px]"
                    onClick={() => {
                      setModalData({
                        data: invoice,
                        mode: 'add-edit',
                      });
                      setInvoiceModal(true);
                    }}
                  />
                  <TrashIcon
                    className="h-[20px] w-[20px]"
                    onClick={() => {
                      setModalData({
                        data: invoice,
                        mode: 'add-edit',
                      });
                      setDeleteModal(true);
                    }}
                  />
                </div>
              );
            }

            if (
              operatingPersonaSingleKey === PersonaTypeSingleLetter.Warrantor
            ) {
              if (invoiceStatus === ExternalRepairInvoiceStatus.IN_REVIEW) {
                return (
                  <div className="text-primary-900 flex cursor-pointer justify-center gap-4 text-right">
                    <XMarkIcon
                      className="h-[20px] w-[20px] text-red-600"
                      onClick={() =>
                        updateInvoiceStatus(
                          invoice,
                          ExternalRepairInvoiceStatus.REJECTED
                        )
                      }
                    />
                    <CheckIcon
                      className="h-[20px] w-[20px]"
                      onClick={() =>
                        updateInvoiceStatus(
                          invoice,
                          ExternalRepairInvoiceStatus.APPROVED
                        )
                      }
                    />
                  </div>
                );
              }
              return (
                <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                  <MagnifyingGlassIcon
                    className="h-[20px] w-[20px]"
                    onClick={() => {
                      setModalData({
                        data: invoice,
                        mode: 'add-edit',
                      });
                      setInvoiceModal(true);
                    }}
                  />
                </div>
              );
            }
          },
        },
      ]),
    [
      external_repair_invoices,
      t,
      tenantInfo?.currency,
      operatingPersonaSingleKey,
    ]
  );

  // Side effects
  if (uploadSuccessful && unsubmittedInvoices.length > 0) {
    setUploadSuccessful(false);
    setModalData({
      data: unsubmittedInvoices[0],
      mode: 'submit',
    });
    setInvoiceModal(true);
  }

  // Main render
  return (
    <CardWrapperWithHeader
      title={getSectionTitle()}
      noPadding={true}
      collapsedByDefault={false}
      headerRight={renderSectionHeaderRight()}
    >
      {external_repair_invoices.length === 0 && (
        <p className="p-5"> {renderNoInvoiceText()} </p>
      )}
      <div>
        {loading && <LoadingSpinner size="md" overlay={true} />}
        {external_repair_invoices.length > 0 && (
          <InvoiceTable
            enablePagination={external_repair_invoices.length > 10}
            initialState={{ showGlobalFilter: true }}
            muiSearchTextFieldProps={{
              sx: { display: 'none' },
            }}
            muiTopToolbarProps={{
              sx: { display: 'none' },
            }}
            muiTableHeadCellProps={{
              className: 'relative bg-gray-200 uppercase font-light',
            }}
            muiTableBodyCellProps={{
              className: 'px-4 py-0',
            }}
            onClickRow={handleRowClick}
          />
        )}

        {invoiceModal && (
          <ModalAddEditInvoice
            handleClose={(submitted) => {
              setInvoiceModal(false);
              setModalData(null);
              if (submitted === true) {
                setUploadSuccessful(submitted);
              }
            }}
            data={modalData?.data}
            mode={modalData?.mode}
          />
        )}
        {deleteModal && (
          <Modal
            kind="pop-up"
            settings={{
              text: t(`message.confirmDeleteInvoice`),
              primaryButtonText: t('general.yes'),
              outlineButtonText: t('general.cancel'),
              loading: false,
              handlePrimaryClick: async () => {
                await deleteInvoice();
                setDeleteModal(false);
              },
              handleOutlineClick: () => setDeleteModal(false),
            }}
          />
        )}
      </div>
    </CardWrapperWithHeader>
  );
}
