import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

type ButtonLinkProps = {
  label: string;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<any>;

const baseLabelStyle = 'font-nunito mb-2 text-base text-gray-900 ';

export function ButtonLink({
  label,
  children,
  disabled,
  className,
  onClick,
}: ButtonLinkProps) {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick && !disabled) {
      onClick(event);
    }
  };

  return (
    <div className={'flex w-full flex-col'} onClick={handleClick}>
      {label && <label className={baseLabelStyle}>{label}</label>}
      <div
        className={classNames(
          className,
          'font-nunito focus:border-primary-600 flex w-full rounded-lg border-[1px] border-gray-300 bg-gray-50 px-4 py-3 text-base text-gray-500 placeholder-gray-400 !shadow-none !outline-none !ring-0',
          {
            'cursor-pointer hover:bg-white': disabled === undefined,
          }
        )}
      >
        {children}
        {disabled ?? <ChevronRightIcon className="ml-auto w-3 text-gray-500" />}
      </div>
    </div>
  );
}
