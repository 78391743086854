import { Form, Formik, FormikProps } from 'formik';
import {
  Button,
  getCountryByLabel,
  Input,
  InputTypeSelectAddress,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { Address } from '@rabbit/data/types';
import { LIST_COUNTRIES } from '@rabbit/bizproc/react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';

export type AddressModalKind = 'user' | 'tenant' | 'partner' | 'customer';

export function EditAddAddressModal({
  handleClose,
  mode,
  kind,
  address,
  handleSubmit,
  handleDelete,
}: {
  handleClose: () => void;
  mode: 'edit' | 'new';
  kind: AddressModalKind;
  address: Address;
  handleSubmit: (
    address: Address,
    mode: 'edit' | 'new',
    kind: AddressModalKind
  ) => Promise<void>;
  handleDelete: (address: Address) => Promise<void>;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { tenantInfo } = useContext(AppContext);
  const formikRef = useRef<FormikProps<Address>>(null);
  const { t } = useTranslation();
  const initialValues: Address = {
    line1: mode === 'new' ? '' : address?.line1 || '',
    line2: mode === 'new' ? '' : address?.line2 || '',
    label: mode === 'new' ? '' : address?.label || '',
    town: mode === 'new' ? '' : address?.town || '',
    state: mode === 'new' ? '' : address?.state || '',
    postcode: mode === 'new' ? '' : address?.postcode || '',
    country:
      mode === 'new' ? tenantInfo?.country || address?.country || '' : '',
    isDefault: address?.isDefault || false,
  };

  const validationSchema = Yup.object().shape({
    line1: Yup.string().trim().required('Please enter an address.'),
    line2: Yup.string().trim(),
    label:
      kind !== 'customer'
        ? Yup.string().trim().required('Please enter a label')
        : Yup.string().trim(),
    town: Yup.string().trim().required('Please enter a city / suburb.'),
    state: Yup.string().trim().required('Please enter a state / territory.'),
    postcode: Yup.string().trim().required('Please enter a post code.'),
    country: Yup.string().trim().required('Please enter a country.'),
    isDefault: Yup.bool().default(false),
  });

  const handleDeleteAddress = async () => {
    setIsSubmitting(true);
    await handleDelete(address);
    setIsSubmitting(false);
    handleClose();
  };

  useEffect(() => {
    void (async () => {
      if (mode === 'edit' && formikRef.current) {
        await formikRef.current?.setFieldValue('line1', address?.line1);
        await formikRef.current?.setFieldValue('line2', address?.line2);
        await formikRef.current?.setFieldValue('label', address?.label);
        await formikRef.current?.setFieldValue('town', address?.town);
        await formikRef.current?.setFieldValue('state', address?.state);
        await formikRef.current?.setFieldValue('postcode', address?.postcode);
        await formikRef.current?.setFieldValue('country', address?.country);
        await formikRef.current?.setFieldValue('isDefault', address?.isDefault);
      } else if (formikRef.current) {
        await formikRef.current?.setFieldValue(
          'country',
          address?.country || tenantInfo?.country
        );
        formikRef.current?.setErrors({});
      }
    })();
  }, [formikRef.current]);

  const handleSelectorChange =
    (props: FormikProps<Address>) => async (address?: SelectAddressShape) => {
      if (address) {
        await props.setFieldValue('line1', address.line_1);
        await props.setFieldValue('line2', address.line_2);
        await props.setFieldValue('town', address.locality);
        await props.setFieldValue('state', address.province_name);
        await props.setFieldValue('postcode', address.postal_code);
        await props.setFieldValue(
          'country',
          getCountryByLabel(address.country_name)?.value
        );
        props.setErrors({});
      }
    };

  const handleFormSubmit = async (values: Address) => {
    setIsSubmitting(true);
    await handleSubmit({ ...address, ...values }, mode, kind);
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <Modal
      kind="generic"
      settings={{
        title:
          mode === 'edit' ? address?.label || 'Edit address' : 'Add address',
        handleClose,
      }}
      isLoading={isSubmitting}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        {(props) => {
          return (
            <Form className="mt-2 w-full px-5">
              <div className="mt-4">
                <Input
                  type="select"
                  label={t('general.country')}
                  name="country"
                  settings={{
                    options: LIST_COUNTRIES,
                    placeholder: t('general.country'),
                  }}
                />
              </div>
              <div className="mt-4">
                <InputTypeSelectAddress
                  errors={props.errors['country']}
                  onChange={handleSelectorChange(props)}
                  country={props.values.country || tenantInfo?.country}
                />
              </div>
              {kind !== 'customer' && (
                <div className="mt-4">
                  <Input
                    type="text"
                    label={`${t('general.addressLabel')}*`}
                    name="label"
                    settings={{
                      hint: '*required',
                      placeholder: t('general.addressLabel'),
                    }}
                  />
                </div>
              )}
              <div className="mt-4">
                <Input
                  type="text"
                  label={t('general.addressLine')}
                  name="line1"
                  settings={{
                    placeholder: t('general.addressLine'),
                  }}
                />
              </div>
              <div className="mt-4">
                <Input
                  type="text"
                  label={t('general.addressLine2')}
                  name="line2"
                  settings={{
                    placeholder: t('general.addressLine2'),
                  }}
                />
              </div>
              <div className="mt-4 flex gap-3">
                <Input
                  type="text"
                  label={t('general.citySuburb')}
                  name="town"
                  settings={{ placeholder: t('general.citySuburb') }}
                />
                <Input
                  type="text"
                  label={t('general.stateTerritory')}
                  name="state"
                  settings={{
                    placeholder: t('general.stateTerritory'),
                  }}
                />
              </div>
              <div className="mt-4 flex gap-3">
                <Input
                  type="text"
                  label={t('general.postCode')}
                  name="postcode"
                  settings={{
                    id: 'postcode',
                    placeholder: t('general.postCode'),
                  }}
                />
              </div>
              <div className="mt-4">
                <Input
                  type="checkbox"
                  name="isDefault"
                  settings={{
                    checkboxLabel: t('general.setAsDefaultAddress'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
              </div>
              <div className="mt-4 grid grid-cols-2 gap-5 py-2">
                <Button
                  loading={isSubmitting}
                  kind="primary"
                  type="submit"
                  children={t('general.save')}
                />
                {mode === 'new' && (
                  <Button kind="red" onClick={handleClose}>
                    {t('general.cancel')}
                  </Button>
                )}
                {mode === 'edit' && (
                  <Button
                    kind="red"
                    icon={<TrashIcon />}
                    onClick={handleDeleteAddress}
                  >
                    {t('general.delete')}
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
