import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { CardWrapperWithHeader } from '@rabbit/elements/shared-components';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { Link } from 'react-router-dom';
import { DTConsumer_Private, DTHoldingProxy } from '@rabbit/data/types';
import CustomerDetailsContentSection from '@rabbit/sage/components/molecules/CustomerDetailsContentSection/CustomerDetailsContentSection';
import Skeleton from 'react-loading-skeleton';
import { useContext } from 'react';
import { UserContext } from '@rabbit/sage/context/UserContext';
import { useTranslation } from 'react-i18next';
import { getUserPermissions } from '@rabbit/sage/utils/helpers';
import { Permissions } from '@rabbit/data/types';
import { CORPORATE } from '@rabbit/sage/utils/consts.ts';
import BusinessDetailsContentSection from '@rabbit/sage/components/molecules/CustomerDetailsContentSection/BusinessDetailsContentSection.tsx';

interface RegistrationsCustDetailsSectionProps {
  allHoldingsList?: DTHoldingProxy[] | undefined;
  consumer_private?: DTConsumer_Private | undefined;
  favouriteStore?: string;
}
export default function RegistrationsCustDetailsSection({
  allHoldingsList,
  consumer_private,
  favouriteStore,
}: RegistrationsCustDetailsSectionProps) {
  const { t } = useTranslation();

  const contextValues = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, contextValues);
  const canViewEditCustomer =
    userPermissions.includes(Permissions.Owner) ||
    userPermissions.includes(Permissions.ConsumersEdit) ||
    userPermissions.includes(Permissions.ConsumersView);

  return (
    <CardWrapperWithHeader
      title={t('general.customerDetails')}
      headerRight={
        canViewEditCustomer && (
          <Link
            to={SAGE_ROUTE_NAME.MANAGE + '/customer/' + consumer_private?.docid}
          >
            <ArrowTopRightOnSquareIcon className="text-primary-900 h-5 w-5 cursor-pointer" />
          </Link>
        )
      }
    >
      {' '}
      {!allHoldingsList || !consumer_private || !favouriteStore ? (
        <div>
          <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={4} />
            </div>
          </div>
          <div className="font-nunito grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={2} />
            </div>
            <div className="mb-10">
              {' '}
              <Skeleton count={4} />
            </div>
          </div>
        </div>
      ) : consumer_private.consumer_type === CORPORATE ? (
        <BusinessDetailsContentSection
          consumer_private={consumer_private}
          holdingsList={allHoldingsList}
          favouriteStore={favouriteStore}
        />
      ) : (
        <CustomerDetailsContentSection
          consumer_private={consumer_private}
          holdingsList={allHoldingsList}
          favouriteStore={favouriteStore}
        />
      )}
    </CardWrapperWithHeader>
  );
}
