import { Button, Input } from '@rabbit/elements/shared-components';
import { Form, Formik } from 'formik';
import { LIST_COUNTRIES } from '@rabbit/bizproc/react';
import { SageFileUploader } from '@rabbit/sage/components/organisms/upload-wrapper/SageFileUploader.tsx';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useQueryParams } from 'raviger';
import { Dispatch, SetStateAction, useContext, useRef, useState } from 'react';
import { UserUploadedDocument } from '@rabbit/data/types';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { AppContext } from '@rabbit/app-context';

interface HoldingDataCase {
  holding_vendable_id: string;
  purchase_time: Date | null;
  purchase_price: {
    amount: number;
    currency: string;
  };
  purchase_location: {
    docid: string;
    country: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  purchase_country: string;
  serial: string;
  serial_number_proof: UserUploadedDocument[];
  installation_attachment: UserUploadedDocument[];
  installer_id: string;
  installation_date: string;
  installation_time: string;
}

interface ProductDetailsViewProps {
  steps: string[];
  setCurrentStep: Dispatch<SetStateAction<any>>;
  defaultSelected: any;
  onSubmitProductData: (values: HoldingDataCase) => Promise<void>;
  holding_docid: string;
  newConsumerLink: string;
}

export default function ProductDetailsView({
  steps,
  setCurrentStep,
  defaultSelected,
  onSubmitProductData,
  holding_docid,
  newConsumerLink,
}: ProductDetailsViewProps) {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useQueryParams();
  const formikRefProduct = useRef(null) as any;

  const serialNumber = params[0] && params[0].sn ? params[0].sn : '';

  const registrationInitialValues: HoldingDataCase = {
    holding_vendable_id: defaultSelected.id ?? '',
    purchase_time: null,
    purchase_price: {
      amount: 0,
      currency: tenantInfo?.currency ?? 'GBP',
    },
    purchase_location: null,
    store_not_listed: false,
    custom_store_name: '',
    purchase_country: tenantInfo?.country ?? '',
    serial: serialNumber,
    serial_number_proof: [],
    installation_attachment: [],
    installer_id: '',
    installation_date: '',
    installation_time: '',
  };

  const validationSchema = Yup.object().shape({
    holding_vendable_id: Yup.string()
      .trim()
      .required(t('general.productNameCantBeEmpty')),
    purchase_location: Yup.mixed(),
    store_not_listed: Yup.boolean(),
    purchase_price: Yup.object({
      amount: Yup.number()
        .min(1, t('message.pleaseEnterValidAmount'))
        .required(t('message.pleaseEnterValidAmount')),
      currency: Yup.string().trim().required(t('message.currencyIsRequired')),
    })
      .required(t('message.pleaseEnterPurchasePrice'))
      .typeError(t('message.pleaseEnterPurchasePrice')),
    purchase_time: Yup.string().nullable().trim(),
    installation_date: Yup.date().required(
      t('message.installationDateRequired')
    ),
    installation_time: Yup.string()
      .trim()
      .required(t('message.installationTimeRequired')),
  });

  const backToCustomer = () => void setCurrentStep(steps[1]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Formik
        initialValues={registrationInitialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitProductData}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formikRefProduct}
      >
        {({ errors, values, setFieldValue }) => (
          <Form className="flex-col gap-3">
            <Grid
              container
              spacing={2}
              sx={{ paddingLeft: 2, paddingRight: 2 }}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Input
                  type="autocomplete-vendable"
                  name="holding_vendable_id"
                  label={`${t('general.product')}*`}
                  settings={{
                    isMulti: false,
                    id: 'holding_vendable_id',
                    placeholder: t('general.productName'),
                    options: [],
                    hint: t('*required'),
                    tenantLink: t('tenantLink'),
                    disabled: !!defaultSelected,
                    value: defaultSelected,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Input
                  type="datepicker"
                  name="purchase_time"
                  label={t('general.dateOfPurchase')}
                  settings={{
                    id: 'purchase_time',
                    placeholder: t('general.dateOfPurchase'),
                    maxDate: new Date(),
                  }}
                />
                <div className="flex items-center pt-2">
                  <Input
                    type="checkbox"
                    name="noDateOfPurchase"
                    settings={{
                      checkboxLabel: t('general.dateNotKnown'),
                      checkboxLabelStyles: 'text-base text-gray-500',
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="w-full flex-col">
                  <Input
                    type="datepicker"
                    name="installation_date"
                    label={t('general.installationDate')}
                    settings={{
                      id: 'installation_date',
                      placeholder: 'DD/MM/YYYY',
                      minDate: new Date(),
                      initialDate: new Date(),
                      hint: '*required',
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="w-full flex-col">
                  <Input
                    type="time"
                    name="installation_time"
                    label={t('general.installationTime')}
                    settings={{
                      id: 'installation_time',
                      placeholder: t('general.installationTime'),
                      hint: '*required',
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Input
                  type="currency"
                  name="purchase_price"
                  label={`${t('general.purchasePrice')}*`}
                  settings={{
                    id: 'purchase_price',
                    placeholder: t('general.purchaseAmount'),
                    currency: tenantInfo?.currency,
                    hint: '*required',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="flex grow flex-col gap-2">
                  <Input
                    type="autocomplete-location-retailer"
                    name="purchase_location"
                    label={t('general.storeOfPurchase')}
                    settings={{
                      isMulti: false,
                      placeholder: t('message.pleaseSelectAnOption'),
                      options: [],
                      errors: errors,
                      disabled: values.store_not_listed,
                    }}
                  />
                  <Input
                    type="checkbox"
                    name="store_not_listed"
                    settings={{
                      checkboxLabel: t('general.storeIsNotListed'),
                      checkboxLabelStyles: 'text-base text-gray-500',
                    }}
                  />
                  {values.store_not_listed && (
                    <div className="mt-2 flex flex-col gap-2">
                      <Input
                        type="text"
                        name="custom_store_name"
                        settings={{
                          id: 'custom_store_name',
                          placeholder: t('general.enterStoreName'),
                        }}
                      />
                      <Input
                        type="select"
                        label=""
                        name="purchase_country"
                        settings={{
                          options: LIST_COUNTRIES,
                          id: 'purchase_country',
                          placeholder: t(
                            'general.countryWhereProductWasPurchased'
                          ),
                        }}
                      />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Input
                  type="text"
                  label={t('general.serialNumber')}
                  name="serial"
                  settings={{
                    placeholder: t('general.serialNumber'),
                    disabled: serialNumber !== '',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div className="flex flex-col">
                  <SageFileUploader
                    label={t('general.supportingMaterials')}
                    identifiers={{
                      category: UploadedFileCategories.installation_attachment,
                      docType: {
                        docid: holding_docid,
                        type: DocTypeShapeTypes.Holding,
                      },
                      personaId: newConsumerLink,
                    }}
                    onUploadCompleted={(files) =>
                      setFieldValue('installation_attachment', files)
                    }
                    onDeleteFile={() =>
                      setFieldValue('installation_attachment', [])
                    }
                    currentFiles={values.installation_attachment ?? []}
                    shouldAutoUpdateDocs={false}
                    accepts={['image/*', 'video/*', '.pdf']}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Input
                  type="rich-text"
                  label={t('general.internalComment')}
                  name={'internal_comment'}
                  settings={{
                    id: 'internal_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Button
                  kind="primary"
                  type="submit"
                  className="w-full"
                  loading={isSubmitting}
                  disabled={
                    Object.keys(errors).length > 1 ||
                    !formikRefProduct.current?.isValid
                  }
                >
                  {t('general.submit')}
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Button
                  kind="outline_red"
                  type="button"
                  className="w-full"
                  onClick={backToCustomer}
                >
                  {t('general.back')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
