import classNames from 'classnames';
import { Field, useField } from 'formik';
import { InputSettingsShape } from '@rabbit/elements/shared-types';

type InputTypeEmailProps = {
  name: string;
  settings: InputSettingsShape;
};

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 rounded-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 text-red-700',
  disabled: 'border-gray-300 text-gray-400 opacity-60',
  chat: '!bg-white h-[42px]',
};

function StyledInput({ field, ...props }: any) {
  // fixes auto fill bug in formik where formik set form attribute on input fields
  field.form = null;

  return (
    <input
      type={props.type}
      id={props.id}
      placeholder={props.placeholder}
      {...props}
      {...field}
    ></input>
  );
}

//TODO: investigate Errors prop in formik - does not change styles
export function InputTypeEmail({ name, settings }: InputTypeEmailProps) {
  const { placeholder, disabled, id, errors, ...rest } = settings;
  const [, meta] = useField(name);
  return (
    <Field
      name={name}
      // note: something made the component lose formatting and re-adding the classes here is a dirty hack but a necessary one given time constraints
      // todo: fix this on a later date. If we wrap the input above inside Field (check out Input Type Password) it works, so check that one out for a possible fix - dc
      className={classNames(dynamicStyles.base, {
        [`${dynamicStyles.chat}`]: id === 'chat_input',
        [`${dynamicStyles.error}`]: meta.error,
        [`${dynamicStyles.disabled}`]: disabled,
      })}
      component={StyledInput}
      placeholder={placeholder}
      type={'email'}
      id={id}
      disabled={disabled}
      {...rest}
    />
  );
}
