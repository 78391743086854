import {
  Input,
  Button,
  Modal,
  asyncValidateSchema,
  Toggle,
} from '@rabbit/elements/shared-components';
import imagePlaceholder from '../../../../assets/images/profile-placeholder.png';
import { DTRepairer_Public } from '@rabbit/data/types';
import { Formik, Form } from 'formik';
import { useContext, useState } from 'react';
import * as Yup from 'yup';
import { AppContext } from '@rabbit/app-context';

interface EditUserFormShape {
  role: string;
  rate: {
    amount: number;
    currency: string;
  };
}

export function EditUserModal({
  handleClose,
  user,
}: {
  handleClose: () => void;
  user?: DTRepairer_Public;
}) {
  const { tenantInfo } = useContext(AppContext);
  const schema = Yup.object({
    role: Yup.string(),
    rate: Yup.object({
      amount: Yup.number(),
      currency: Yup.string(),
    }),
  });

  const modalSettings = {
    title: 'Edit user',
    handleClose: () => handleClose(),
  };

  const initialValues: EditUserFormShape = {
    role: undefined as any,
    rate: {
      amount: 0,
      currency: tenantInfo?.currency || 'GBP',
    },
  };

  const options = [
    { label: 'Administrator', value: 'Administrator' },
    { label: 'Technical user', value: 'Technical user' },
    { label: 'Enterprise user', value: 'Enterprise user' },
    { label: 'Management', value: 'Management' },
  ];

  const onSubmit = (values: any) => {
    setLoading(true);
    // TODO: Update user values
    console.log(values);
  };

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-full max-w-[512px] rounded-md border"
      isLoading={loading}
    >
      <div className="flex flex-col gap-4 p-5">
        {' '}
        <div className="flex gap-[10px] rounded-lg border border-gray-300 p-4">
          {' '}
          <img
            src={user?.photoUrl || imagePlaceholder}
            alt="user profile photo"
            width={'44px'}
            height={'44px'}
            className="h-[44px] w-[44px]"
          />{' '}
          <div className="flex flex-col">
            <p>{user?.name}</p>
            <p className="font-nunito text-[14px] text-gray-600">
              {user?.email}
            </p>
          </div>
        </div>
        <div className="font-nunito flex justify-between gap-4 rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-base text-gray-500">
          Suspend access
          <Toggle checked={false} onChange={() => 0} />
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validate={asyncValidateSchema(schema)}
        validateOnBlur
      >
        {({ values, errors }) => {
          return (
            <Form>
              <div className="flex flex-col gap-4 px-5">
                <Input
                  type="select"
                  label="Role"
                  name="role"
                  settings={{
                    options: options,
                    placeholder: 'Please select an option',
                    errors: errors,
                  }}
                />
                <Input
                  type="currency"
                  label="Hourly rate"
                  name="rate"
                  settings={{
                    placeholder: 'Hourly rate',
                    currency:
                      values?.rate?.currency ?? tenantInfo?.currency ?? 'GBP',
                  }}
                />
                <div className="flex gap-4">
                  <Button
                    className="w-1/3"
                    kind="primary"
                    children="Save"
                    type="submit"
                  />
                  <Button
                    className="w-1/3"
                    kind="red"
                    children="Cancel"
                    onClick={() => handleClose()}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
