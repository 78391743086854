import { useState, useEffect, useContext } from 'react';
import {
  ButtonIconVertical,
  CardWrapperWithHeader,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import {
  AssignCaseInternalModal,
  ConfirmRepairCoveredModal,
  ConfirmRepairNotCoveredModal,
  ContactCustomerModal,
} from '../../../../organisms/case-flow-modals';
import { CaseflowContext } from '../../../../../context/CaseflowContext';
import { getActionIcon } from '../../../../../utils/consts';
import { PossibleActionEntry } from '@rabbit/data/types';
import {
  CaseFactsShape,
  CaseFlowActions_Fatbikes_ExternalRepairer,
  CaseFlowCaseStations,
  CFCF_ExternalRepairInvoice,
  ExternalRepairInvoiceStatus,
  ExternalRepairInvoiceType,
} from '@rabbit/bizproc/core';
import { t } from 'i18next';
import InitiateRepairModal from '@rabbit/sage/components/organisms/case-flow-modals/InitiateRepairModal/InitiateRepairModal';
import MarkRepairAsCompleteModal from '@rabbit/sage/components/organisms/case-flow-modals/MarkRepairCompleteModal/MarkRepairCompleteModal';
import ModalAddEditInvoice from '@rabbit/sage/components/organisms/ModalAddEditInvoice/ModalAddEditInvoice';
import CloseCaseFatbikesModal from '@rabbit/sage/components/organisms/case-flow-modals/CloseCaseFatbikesModal/CloseCaseFatbikesModal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimActionsType2Props {
  filterAvailableCFCActions: (
    station: string,
    condition: string,
    CASEFLOW_CONDITIONAL_ACTIONS: any
  ) => { [key: string]: any } | null;
}

export function ClaimActionsType2(props: ClaimActionsType2Props) {
  const { filterAvailableCFCActions } = props;
  const {
    caseFlowCase,
    setShowModal,
    setModalSettings,
    caseState,
    caseActionsStation,
    caseActionsAll,
    caseFacts,
    caseAlterability,
  } = useContext(CaseflowContext) || {};

  const [availableActions, setAvailableActions] = useState({});

  const external_repairer_invoice = caseFacts?.external_repair_invoices
    ? caseFacts?.external_repair_invoices?.find(
        (invoice) => invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED
      )
    : undefined;

  useEffect(() => {
    if (caseFacts && filterAvailableCFCActions) {
      handleActionFiltering(
        caseFacts,
        caseState,
        filterAvailableCFCActions,
        setAvailableActions
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caseActionsStation,
    caseFacts,
    caseFacts?.external_repair_invoices,
    caseState,
    filterAvailableCFCActions,
  ]);

  if (
    !caseFlowCase ||
    !setShowModal ||
    !setModalSettings ||
    !caseState ||
    !caseActionsStation ||
    !caseActionsAll ||
    !caseFacts ||
    !caseAlterability
  )
    return (
      <CardWrapperWithHeader title={t('general.claimActions')}>
        <LoadingSpinner size="xs" extraClasses="min-h-[128px]" />
      </CardWrapperWithHeader>
    );

  // Turn the actions into an array for mapping and add relevant data to them
  const actionsArray = Object.entries(
    [
      // CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
      // CaseFlowCaseStations.PROFORMA_INVOICE_SUBMISSION,
      // CaseFlowCaseStations.PROFORMA_INVOICE_ACCEPTED,
      CaseFlowCaseStations.CLOSED,
    ].includes(caseState)
      ? availableActions
      : caseActionsStation
  ).map(([key, value]) => {
    return {
      ...(value as PossibleActionEntry),
      Icon: getActionIcon(key),
    };
  });

  const modalSettingOptions = {
    assign_case: {
      kind: 'generic' as const,
      settings: {
        title: t('general.assignCase'),
      },
      children: <AssignCaseInternalModal />,
    },
    message_customer: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.notifyCustomer'),
        secondaryButtonText: t('general.cancel'),
      },
      children: <ContactCustomerModal messageCustomer />,
    },
    contact_customer_generic: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.notifyCustomer'),
        secondaryButtonText: t('general.cancel'),
      },
      children: <ContactCustomerModal />,
    },
    close_case: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.closeCase'),
        secondaryButtonText: t('general.cancel'),
      },
      children: <CloseCaseFatbikesModal />,
    },
    initiate_repair: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.initiateRepair'),
        secondaryButtonText: t('general.cancel'),
      },
      children: <InitiateRepairModal />,
    },
    mark_repair_complete: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.markRepairAsComplete'),
        secondaryButtonText: t('general.cancel'),
      },
      children: <MarkRepairAsCompleteModal />,
    },
    submit_invoice: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.submitInvoice'),
        secondaryButtonText: t('general.cancel'),
      },
      children: (
        <ModalAddEditInvoice
          data={external_repairer_invoice}
          mode="submit"
          handleClose={() => setShowModal(false)}
        />
      ),
    },

    [CaseFlowActions_Fatbikes_ExternalRepairer.RepairCovered]: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.confirm'),
        secondaryButtonText: t('general.cancel'),
      },
      children: <ConfirmRepairCoveredModal />,
    },
    [CaseFlowActions_Fatbikes_ExternalRepairer.RepairNotCovered]: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.confirm'),
        secondaryButtonText: t('general.cancel'),
      },
      children: <ConfirmRepairNotCoveredModal />,
    },
    [CaseFlowActions_Fatbikes_ExternalRepairer.AddProformaInvoice]: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.addProformaInvoice'),
        secondaryButtonText: t('general.cancel'),
      },
      children: (
        <ModalAddEditInvoice
          data={external_repairer_invoice}
          mode="add-edit"
          handleClose={() => setShowModal(false)}
          invoiceType={ExternalRepairInvoiceType.PROFORMA}
        />
      ),
    },
    [CaseFlowActions_Fatbikes_ExternalRepairer.SubmitProformaInvoice]: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.submitProformaInvoice'),
        secondaryButtonText: t('general.cancel'),
      },
      children: (
        <ModalAddEditInvoice
          data={external_repairer_invoice}
          mode="submit"
          handleClose={() => setShowModal(false)}
          invoiceType={ExternalRepairInvoiceType.PROFORMA}
        />
      ),
    },
    [CaseFlowActions_Fatbikes_ExternalRepairer.AddInvoice]: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('general.addInvoice'),
        secondaryButtonText: t('general.cancel'),
      },
      children: (
        <ModalAddEditInvoice
          data={external_repairer_invoice}
          mode="add-edit"
          handleClose={() => setShowModal(false)}
          invoiceType={ExternalRepairInvoiceType.REPAIR}
        />
      ),
    },
  };

  if (actionsArray.length > 0) {
    return (
      <CardWrapperWithHeader title={t('general.claimActions')}>
        <div className="flex gap-[40px] overflow-auto pb-4 lg:pb-2">
          {caseAlterability === 'AwaitingUpdate' ? (
            <div className="mx-auto items-center">
              <LoadingSpinner size={'xs'} />
            </div>
          ) : (
            actionsArray.map((action) => {
              const key = action.key;

              const handleClick = () => {
                const modalSetting =
                  modalSettingOptions[key as keyof typeof modalSettingOptions];
                setModalSettings(modalSetting);
                setShowModal(true);
              };

              return (
                <ButtonIconVertical
                  key={key}
                  kind={'claim-actions'}
                  label={t(`general.${action.label}`)}
                  Icon={action.Icon}
                  onClick={handleClick}
                />
              );
            })
          )}
          {actionsArray.length === 0 && (
            <p className="font-nunito">
              {t('message.noAvailableActionsForClaim')}
            </p>
          )}
        </div>
      </CardWrapperWithHeader>
    );
  } else {
    return '';
  }
}

export default ClaimActionsType2;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const CASEFLOW_CONDITIONAL_ACTIONS: any = {
  /* -------------------------------- FATBIKES -------------------------------- */
  [CaseFlowCaseStations.PROFORMA_INVOICE_SUBMISSION]: {
    invoice_uploaded: [
      CaseFlowActions_Fatbikes_ExternalRepairer.SubmitProformaInvoice,
    ],
    no_invoice_uploaded: [],
  },
  [CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION]: {
    invoice_uploaded: [CaseFlowActions_Fatbikes_ExternalRepairer.SubmitInvoice],
    no_invoice_uploaded: [],
  },
  [CaseFlowCaseStations.INVOICE_ACCEPTED]: {
    invoice_uploaded: [CaseFlowActions_Fatbikes_ExternalRepairer.CloseCase],
    no_invoice_uploaded: [],
  },
};

const handleActionFiltering = (
  caseFacts: Partial<CaseFactsShape>,
  caseState: string,
  filterAvailableCFCActions: (
    station: string,
    condition: string,
    CASEFLOW_CONDITIONAL_ACTIONS: any
  ) => { [key: string]: any } | null, // todo type any
  setAvailableActions: React.Dispatch<React.SetStateAction<any>> // todo type
) => {
  const { external_repair_invoices } = caseFacts;

  let filteredActions: { [key: string]: any } | null = {};

  const filter = (station: string, condition: string) => {
    filteredActions = filterAvailableCFCActions(
      station,
      condition,
      CASEFLOW_CONDITIONAL_ACTIONS
    );
  };

  switch (caseState) {
    case CaseFlowCaseStations.PROFORMA_INVOICE_SUBMISSION:
      const hasProformaInvoice = external_repair_invoices
        ? external_repair_invoices?.find(
            (invoice: CFCF_ExternalRepairInvoice) =>
              invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED &&
              invoice.invoiceType === ExternalRepairInvoiceType.PROFORMA
          )
        : undefined;

      filter(
        CaseFlowCaseStations.PROFORMA_INVOICE_SUBMISSION,
        hasProformaInvoice ? 'invoice_uploaded' : 'no_invoice_uploaded'
      );
      break;

    case CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION:
      const hasRepairInvoice = external_repair_invoices
        ? external_repair_invoices?.find(
            (invoice: CFCF_ExternalRepairInvoice) =>
              invoice.status === ExternalRepairInvoiceStatus.UNSUBMITTED &&
              invoice.invoiceType === ExternalRepairInvoiceType.REPAIR
          )
        : undefined;

      filter(
        CaseFlowCaseStations.REPAIR_INVOICE_SUBMISSION,
        hasRepairInvoice ? 'invoice_uploaded' : 'no_invoice_uploaded'
      );
      break;
    case CaseFlowCaseStations.INVOICE_ACCEPTED:
      const invoice_accepted = external_repair_invoices
        ? external_repair_invoices?.find(
            (invoice: CFCF_ExternalRepairInvoice) =>
              invoice.status === ExternalRepairInvoiceStatus.APPROVED &&
              invoice.invoiceType === ExternalRepairInvoiceType.REPAIR
          )
        : undefined;

      filter(
        CaseFlowCaseStations.INVOICE_ACCEPTED,
        invoice_accepted ? 'invoice_uploaded' : 'no_invoice_uploaded'
      );
      break;
  }
  setAvailableActions(filteredActions);
};
