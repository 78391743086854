import {
  FBD_Tenant_Public,
  TenantLinkFieldName,
  Z_TenantInfo,
} from '@rabbit/data/types';
import { t, reloadResources } from 'i18next';
import {
  APICallParamsBase,
  APICallResultBase,
  GetPartnerTenantParams,
} from '@rabbit/elements/shared-types';
import {
  CFC_GetPartnerTenantData,
  OCF_GetPartnerTenantData,
  CFC_UpdatePartnerTenantData,
  ICF_UpdatePartnerTenantData,
  OCF_UpdatePartnerTenantData,
} from '@rabbit/bizproc/core';
import { z } from 'zod';

export async function getTenantIdFromLexicon() {
  let tenantId = t(TenantLinkFieldName);
  let tries = 1;

  while ((!tenantId || tenantId === TenantLinkFieldName) && tries < 5) {
    console.warn('TenantLink not found in lexicon, retrying...');
    await new Promise((resolve) => setTimeout(resolve, tries * 1000));
    tenantId = t(TenantLinkFieldName);
    tries++;
  }

  return tenantId;
}

// function that takes an map of cfg keys and writes them in the tenant_public.mck map

async function WriteConfigToTenantPublic(
  config: Record<string, any>,
  tenantId?: string
) {
  // check if config contains only keys starting with "CFG_"
  const cfgKeys = Object.keys(config);
  const cfgKeysFiltered = cfgKeys.filter((key) => key.startsWith('CFG_'));
  if (cfgKeys.length !== cfgKeysFiltered.length) {
    throw new Error(
      `Tenant_WriteConfigToTenantPublic: not all keys start with "CFG_"`
    );
  }

  const tenant = await FBD_Tenant_Public.get(
    tenantId ?? (await getTenantIdFromLexicon())
  );
  if (!tenant) {
    throw new Error(`Tenant ${tenantId} not found`);
  }

  tenant.mck = config;

  await FBD_Tenant_Public.set(tenant);

  // FUTURE: ideally we would want to refresh only cfgKeys that were changed, but for now we refresh all...
  await reloadResources();

  return tenant;
}

async function GetPartnerData({
  requestId,
  apiVersion,
  partnerTenant,
  userTenant,
}: GetPartnerTenantParams & APICallParamsBase): Promise<
  {
    data: z.infer<typeof OCF_GetPartnerTenantData>;
  } & APICallResultBase
> {
  const result = await CFC_GetPartnerTenantData.call({
    requestId,
    apiVersion,
    partnerTenant,
    userTenant,
  });
  if (!result.ok) throw new Error(result.error);
  return result as {
    data: z.infer<typeof OCF_GetPartnerTenantData>;
  } & APICallResultBase;
}

async function UpdatePartnerData({
  requestId,
  apiVersion,
  userTenant,
  partnerTenant,
  formData,
}: z.infer<typeof ICF_UpdatePartnerTenantData> & APICallParamsBase): Promise<
  {
    data: z.infer<typeof OCF_UpdatePartnerTenantData>;
  } & APICallResultBase
> {
  const result = await CFC_UpdatePartnerTenantData.call({
    requestId,
    apiVersion,
    userTenant,
    partnerTenant,
    formData,
  });
  if (!result.ok) throw new Error(result.error);
  return result as {
    data: z.infer<typeof OCF_UpdatePartnerTenantData>;
  } & APICallResultBase;
}

async function UpsertInfo(
  info: z.infer<typeof Z_TenantInfo>,
  tenantId: string
) {
  Z_TenantInfo.parse(info);
  const tenant = await FBD_Tenant_Public.get(tenantId);
  if (!tenant) {
    throw new Error(`Tenant ${tenantId} not found`);
  }
  const tenantInfo = { ...(tenant.info || {}), ...info };
  await FBD_Tenant_Public.set({ ...tenant, info: tenantInfo });
}

export const Tenant = {
  UpsertInfo,
  WriteConfigToTenantPublic,
  GetPartnerData,
  UpdatePartnerData,
};

