import { Fragment, useContext, useEffect, useState } from 'react';
import Select, { MenuProps, components } from 'react-select';
import { useField, useFormikContext } from 'formik';
//import { handleKeyPress } from '@rabbit/components/shared-ui';
import { InputTypeAutoCompleteConsumerShape } from '@rabbit/elements/shared-types';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import { useGetConsumerList } from '@rabbit/bizproc/react';
import { generateSelectStyles } from '../../../utils/consts';
import { CORPORATE } from '@rabbit/sage/utils/consts';
import Bus from '@rabbit/bus';
import { AppContext } from '@rabbit/app-context';

export interface InputAutoCompleteConsumerProps {
  name: string;
  settings: InputTypeAutoCompleteConsumerShape;
  onChange?: (...args: any) => any; // todo: review
}

interface OptionShape {
  id: string;
  label: string;
  item: object;
  value: string;
}

export function InputAutoCompleteConsumer({
  name,
  settings,
  onChange: defaultOnChange,
}: InputAutoCompleteConsumerProps) {
  const { setFieldValue } = useFormikContext();
  const { config } = useContext(AppContext);
  const {
    placeholder,
    isMulti = false,
    isClearable = true,
    disabled = false,
    customButton,
    token,
    type,
    ...props
  } = settings;

  const { consumerList, getConsumersByEmail } = useGetConsumerList(
    token ?? null,
    type,
    config.CUSTOMER.CORPORATE_ACCOUNT
  );
  const [field, meta] = useField(name);

  const [options, setOptions] = useState<OptionShape[]>([]);
  const [inputText, setInputText] = useState('');
  // Note: Debouncing is actually not currently required here as the consumerList query is just being filtered.
  // Setting it to 100ms just to maintain the rest of the code as is, since this doesn't have a major performance impact and
  // it may be handled through an api in the near future. - DC

  const debouncedInput = useDebounce(inputText, 100);
  const [isLoadingResults, setIsLoadingResults] = useState(false);

  let errorField = false;
  if (meta?.error) errorField = true;

  function onChange(option: any) {
    if (isMulti) {
      void setFieldValue(
        name,
        option ? option.map((item: { value: any }) => item.value) : []
      );
    } else {
      void setFieldValue(name, option?.value ? option.value : '');
    }
    defaultOnChange && defaultOnChange(option);
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option: { value: any }) => field.value.indexOf(option.value) >= 0
          )
        : options.find(
            (option: { value: any }) => option.value === field.value
          );
    } else {
      return isMulti ? [] : '';
    }
  };

  useEffect(() => {
    const generateOptions = () => {
      const data = getConsumersByEmail(debouncedInput);
      if (data?.length) {
        const newOptions: OptionShape[] = [];
        data.map((item) => {
          item.email &&
            newOptions.push({
              id: item.docid,
              label:
                type === CORPORATE
                  ? item.business_name + ' (' + item.email + ')'
                  : item.fullname + ' (' + item.email + ')',
              value: item.email,
              item: item,
            });
        });
        setOptions(newOptions);
      }
      setIsLoadingResults(false);
    };

    if (debouncedInput.length) {
      setIsLoadingResults(true);
      generateOptions();
    } else if (settings?.options && settings?.options?.length > 0) {
      setOptions(settings?.options as OptionShape[]);
    } else setOptions([]);
  }, [debouncedInput, type]);

  const onInputChange = (e: string) => {
    setInputText(e);
  };

  const CustomMenu = (props: MenuProps<any>) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="font-nunito text-base font-semibold text-gray-900">
            {type === CORPORATE ? 'Business' : 'Customers'}
          </div>
          {props.children}
          {customButton && (
            <div className="mt-2 w-full border-t border-gray-300 pt-2">
              {customButton}
            </div>
          )}
        </components.Menu>
      </Fragment>
    );
  };

  interface ItemData {
    fullname?: string;
    email?: string;
  }

  interface OptionData {
    item?: ItemData;
  }

  const CustomOption = (props: any) => {
    const {
      innerProps,
      isDisabled,
      data: { item },
    }: { innerProps: any; isDisabled: boolean; data: OptionData } = props;
    return !isDisabled && item ? (
      <div
        {...innerProps}
        className="font-nunito focus-within:bg-primary-200 active:bg-primary-200 hover:!bg-primary-200 my-1 !cursor-pointer overflow-hidden rounded !bg-gray-50 p-2 text-gray-900"
      >
        <div className="font-nunito text-base font-semibold text-gray-700">
          {item.fullname ? item.fullname : '-'}
        </div>
        <div className="font-nunito text-sm text-gray-500">
          {item.email ? item.email : '-'}
        </div>
      </div>
    ) : null;
  };

  return (
    <div>
      <Select
        {...field}
        {...props}
        options={options}
        isMulti={isMulti}
        classNames={generateSelectStyles(errorField)}
        //unstyled
        placeholder={!consumerList.data ? 'Loading...' : placeholder}
        defaultValue={field.value}
        maxMenuHeight={200}
        components={
          // settings.dropDown === 'disabled'
          //   ? {
          //       DropdownIndicator: () => null,
          //       IndicatorSeparator: () => null,
          //     }
          //   : undefined
          {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Menu: CustomMenu,
            Option: CustomOption,
          }
        }
        value={
          options
            ? isMulti
              ? options.find(
                  (option: { value: any }) => option.value === field.value
                )
              : getValue()
            : ''
        }
        //error
        //onKeyDown={handleKeyPress}
        onChange={onChange}
        onMenuOpen={() => Bus.emit(Bus.events.modalContent, true)}
        onMenuClose={() => Bus.emit(Bus.events.modalContent, false)}
        onInputChange={onInputChange}
        isClearable={isClearable}
        isDisabled={disabled || !consumerList.data}
        noOptionsMessage={({ inputValue }) =>
          !inputValue
            ? 'Type something...'
            : inputText === debouncedInput && !isLoadingResults
            ? 'No results found'
            : 'Loading...'
        }
      />
    </div>
  );
}

export default InputAutoCompleteConsumer;
