import {
  DTWarranty_Offer,
  DeciderTableValues,
  DT_AnyValueMultiDimensional,
} from '@rabbit/data/types';
import {
  Button,
  CardWrapperWithHeader,
  LoadingSpinner,
  Table,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { PricePlansShape } from '../../organisms/TabPartnerPricing/TabPartnerPricing';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { AppContext } from '@rabbit/app-context';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface TablePartnerPricingProps {
  data: PricePlansShape;
  onCellChanged?: (
    lookupTables: DeciderTableValues,
    indice: number[],
    value: string | boolean | number
  ) => void;
  onSaveChanges?: (lookup: DeciderTableValues, offer: DTWarranty_Offer) => void;
}

export function TablePartnerPricing(props: TablePartnerPricingProps) {
  const { data, onCellChanged, onSaveChanges } = props;
  const originalTable = data.tableData;
  const originalLookupTable = data.originalOffer.lookupTables[0];
  const { tenantInfo } = useContext(AppContext);
  const { t } = useTranslation();

  const [currentLookup, setLookup] =
    useState<DeciderTableValues>(originalLookupTable);

  const [tableChanged, setTableChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tableData, setTableData] = useState(originalTable);
  const [changesSaved, setChangesSaved] = useState(false);

  if (!originalLookupTable) return <LoadingSpinner size="sm" />;
  // const originalTable = [
  //   {
  //     id: '1',
  //     duration: '12',
  //     500: '20',
  //     1000: '30',
  //     2500: '40',
  //     5000: '50',
  //     15000: '60',
  //   },
  //   {
  //     id: '2',
  //     duration: '24',
  //     500: '60',
  //     1000: '70',
  //     2500: '80',
  //     5000: '90',
  //     15000: '100',
  //   },
  //   {
  //     id: '3',
  //     duration: '36',
  //     500: '120',
  //     1000: '130',
  //     2500: '140',
  //     5000: '150',
  //     15000: '160',
  //   },
  //   {
  //     id: '4',
  //     duration: '48',
  //     500: '220',
  //     1000: '230',
  //     2500: '240',
  //     5000: '250',
  //     15000: '260',
  //   },
  //   {
  //     id: '5',
  //     duration: '60',
  //     500: '320',
  //     1000: '330',
  //     2500: '340',
  //     5000: '350',
  //     15000: '360',
  //   },
  // ];

  const getColumnIndexById = (id: string) => {
    const index = tableHeaders.findIndex((header) => header.accessorKey === id);
    return index;
  };

  const renderCell = (cell: any, row?: any) => {
    const ogRow = originalTable.find(
      (item: { id: string }) => item.id === row?.original?.id
    );
    const ogValue = ogRow?.[String(cell?.column?.id) as keyof typeof ogRow];
    const cellValue = cell.getValue();

    const isDiff =
      ogValue !== undefined && ogValue !== cellValue && !changesSaved;
    if (isDiff) setTableChanged(true);

    const renderValue = (value: any) => {
      if (value === 'true' || value === 'false')
        return value === 'true' ? (
          <CheckIcon width={20} height={20} />
        ) : (
          <XMarkIcon width={20} height={20} />
        );
      return !Number.isNaN(Number(value))
        ? getCurrencyFormat(value, tenantInfo?.currency || 'GBP')
        : value;
    };

    return (
      <div
        className={`flex h-[25px] w-fit items-center justify-center gap-1.5 rounded-md py-1 px-3 ${
          isDiff ? 'bg-green-100' : 'bg-gray-100'
        }`}
      >
        <p>{renderValue(cellValue.toString())}</p>
      </div>
    );
  };

  const renderInputCell = (cell: any, row: any) => {
    const cellValue = cell.getValue().toString();
    const inputType = ['true', 'false'].includes(cellValue) ? 'select' : 'text';

    return {
      type: inputType,
      required: true,
      placeholder: '-',
      className: '!w-[80px] !bg-gray-200 border',
      select: inputType === 'select',
      onBlur: (event: React.ChangeEvent<HTMLInputElement>) => {
        const isValidChange =
          !isNaN(Number(event.target.value)) ||
          typeof event.target.value === 'boolean';
        const updateValue =
          inputType === 'select'
            ? event.target.value
            : Number(event.target.value);
        const updatedRow = {
          ...row.original,
          [cell.column.id]: !isValidChange
            ? row.original[cell.column.id]
            : updateValue,
        };

        setTableData((prevTableData: any) => {
          const updatedData = prevTableData.map((prevRow: any) => {
            if (prevRow.id === row.original.id) {
              return updatedRow;
            } else return prevRow;
          });
          if (isValidChange && onCellChanged) {
            const indice = [];
            indice.push(getColumnIndexById(cell.column.id) - 1);
            indice.push(row.index);
            const updatedLookup = onCellChanged(
              currentLookup,
              indice,
              updateValue
            );
            setChangesSaved(false);
            if (updatedLookup !== undefined) setLookup(updatedLookup);
          }
          return updatedData;
        });
      },
    };
  };

  const tableHeaders = [
    {
      accessorKey: 'duration',
      header: t('tenantLink') !== 'NUCOVER' ? 'Duration' : 'Plan',
      enableEditing: false,
    },
    ...originalLookupTable.dimensionDetail[0].indices
      // Filters the indices of the lookup table based on the values in the corresponding columns.
      // It checks if the column contains any value other than -1.
      .filter((_, index) => {
        const column =
          originalLookupTable.values[
            index as keyof typeof originalLookupTable.values
          ];
        return (
          Array.isArray(column) &&
          (column as DT_AnyValueMultiDimensional[]).some(
            (value: DT_AnyValueMultiDimensional) => !isNegativeOne(value)
          )
        );
      })
      .map((indice: any) => {
        return {
          accessorKey: String(indice.value),
          header: indice.label, //getCurrencyByCode(appInfo.currency)?.symbolNative + row.id,
          editVariant: 'select',
          editSelectOptions: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          muiEditTextFieldProps: ({ cell, row }: { cell: any; row: any }) =>
            renderInputCell(cell, row),
          Cell: ({ cell, row }: { cell: any; row: any }) =>
            renderCell(cell, row),
        };
      }),
  ];

  const resetChanges = () => {
    setTableData(originalTable);
    setTableChanged(false);
  };

  const saveChanges = async () => {
    if (onSaveChanges) {
      setIsSubmitting(true);
      await onSaveChanges(currentLookup, data.originalOffer.offer);
      setTableChanged(false);
      setIsSubmitting(false);
      setChangesSaved(true);
      toast.success('Changes saved successfully.');
    }
  };

  const renderTableActions = () => {
    return (
      <div className="flex justify-end gap-2 bg-gray-100 py-4 px-4">
        <Button
          kind="outline"
          size="sm"
          onClick={resetChanges}
          className="grow-0"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          kind="primary"
          size="sm"
          className="grow-0"
          onClick={saveChanges}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <div className="relative">
      <CardWrapperWithHeader
        noPadding={true}
        title={data.title}
        canCollapse={true}
        collapsedByDefault={true}
        smaller={true}
      >
        <Table
          columns={tableHeaders}
          data={tableData}
          inner={true}
          enableTopToolbar={false}
          renderBottomToolbar={() => tableChanged && renderTableActions()}
          enableSorting={false}
          state={{
            isLoading: false,
          }}
          editDisplayMode="cell"
          enableEditing={true}
          enablePagination={false}
        />
      </CardWrapperWithHeader>
    </div>
  );
}

const isNegativeOne = (value: DT_AnyValueMultiDimensional): boolean => {
  if (typeof value === 'number') {
    return value === -1;
  }
  if (Array.isArray(value)) {
    return value.every(isNegativeOne);
  }
  return false;
};

export default TablePartnerPricing;
export { isNegativeOne };
