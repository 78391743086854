import { TenantInfo } from '@rabbit/data/types';
import { Input } from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { LogForms } from '../ClaimCostModal';
import { OptionShape } from '@rabbit/bizproc/react';
import { CaseflowInterface } from '@rabbit/sage/context/CaseflowContext';
import { CFCF_RepairTimeLog } from '@rabbit/bizproc/core';
import { t } from 'i18next';

const RepairTimeForm = (
  tenantInfo: TenantInfo | undefined,
  { caseFacts, alterCaseFacts }: CaseflowInterface,
  repairerUsers: OptionShape[],
  personaId: string,
  option?: { editMode: true; index: number }
): LogForms<CFCF_RepairTimeLog & { team_member_id: string }> => ({
  name: 'repair-time',
  initialValues: {
    rate: {
      amount: 0,
      currency: tenantInfo?.currency,
    },
  },
  schema: Yup.object().shape({
    description: Yup.string().required(t('Please enter a description')).trim(),
    team_member_id: Yup.string()
      .required(t('Please select a team member'))
      .trim(),
    rate: Yup.object()
      .shape({
        amount: Yup.string(),
        currency: Yup.string(),
      })
      .required(t('Please enter the rate')),
    date: Yup.string().required(t('Please choose a date')),
    time_spent: Yup.string().required(t('Please enter the time')),
    VAT: Yup.string().required(t('Please enter the VAT')),
  }),
  onDelete: async (index, onComplete) => {
    const updatedRegistry = caseFacts?.repair_time_data || [];
    updatedRegistry.splice(index, 1);
    try {
      await alterCaseFacts({ repair_time_data: [...updatedRegistry] });
      toast.success(t('Record deleted successfully.'));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong, please try again'));
    } finally {
      onComplete();
    }
  },
  onSubmit: async (values, onComplete) => {
    const delegate = repairerUsers.find(
      (user) => user.value === values.team_member_id
    );
    const record = values;
    record.team_member = {
      id: delegate?.value,
      name: delegate?.label || '',
    };
    record.date = new Date(values.date || 0).getTime();
    let updatedRegistry;
    if (option?.editMode) {
      updatedRegistry = caseFacts?.repair_time_data || [];
      updatedRegistry[option.index] = record;
    } else {
      updatedRegistry = [...(caseFacts?.repair_time_data || []), record];
    }

    try {
      await alterCaseFacts({
        repair_time_data: [...updatedRegistry],
        ...(values.internal_comment && {
          internal_comment: {
            comment: values.internal_comment,
            author: personaId,
          },
        }),
      });
      toast.success(t('Claim updated successfully.'));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong, please try again'));
    } finally {
      onComplete();
    }
  },
  Form: () => {
    return (
      <>
        <Input
          type="text"
          label={t('Description') + '*'}
          name="description"
          settings={{
            placeholder: t('Enter description here'),
          }}
        />
        <Input
          type="select"
          label={t('Team member') + '*'}
          name="team_member_id"
          settings={{
            id: 'team_member',
            placeholder: t('Please select an option'),
            options: repairerUsers,
          }}
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            type="datepicker"
            label={t('Date') + '*'}
            name="date"
            settings={{
              id: 'date',
              placeholder: t('Select a date'),
            }}
          />
          <Input
            type="time"
            label={t('Time') + '*'}
            name="time_spent"
            settings={{
              id: 'time',
            }}
          />
          <Input
            type="currency"
            label={t('Rate') + '*'}
            name="rate"
            settings={{
              id: 'rate',
              disableCurrency: true,
            }}
          />
          <Input
            type="text"
            name={t('VAT')}
            label={t('VAT') + '*'}
            settings={{
              placeholder: t('VAT'),
              append: '%',
            }}
          />
        </div>
      </>
    );
  },
});

export default RepairTimeForm;
