import { query, orderBy, where, QueryConstraint } from 'firebase/firestore';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import {
  DR_Consumer_Private,
  DR_Consumer_Public,
  getPersonasFromToken,
  isAdmin,
} from '@rabbit/data/portal';
import { DTConsumer_Public, PrincipalsFieldName } from '@rabbit/data/types';
import { t } from 'i18next';
import { CORPORATE, CUSTOMER } from '@rabbit/sage/utils/consts';

export interface ConsumerPrivateWithNonSRVHoldingsCount
  extends DTConsumer_Public {
  nonSRVHoldingsCount: number;
}

/** For use in Sage. Fetches a list of all Olive users (Identities with a Consumer Persona) and provides functions for filtering the list.
 * NOTE: Firestore does not support full text search, so filtering is the way to go for now in order to provide the right 'feel' on the FE.
 * If full text search is required, we should also integrate this collection with Algolia.
 */
export function useGetConsumerList(
  accessToken: string | null = null,
  consumerType: string = '',
  corporateAccount: boolean = false
) {
  const queryConstraints = [orderBy('tupdate')] as QueryConstraint[];

  const keys = isAdmin(accessToken)
    ? t(PrincipalsFieldName).split(',')
    : getPersonasFromToken(accessToken);
  if (!keys.length) {
    console.error('No personas found in token!');
    keys.push('invalid');
  }

  if (corporateAccount) {
    if (consumerType) {
      queryConstraints.push(where('consumer_type', '==', consumerType));
    } else {
      queryConstraints.push(where('consumer_type', 'in', ['', CUSTOMER]));
    }
  }

  queryConstraints.push(where('principals', 'array-contains-any', keys));
  const consumerQuery = query(
    DR_Consumer_Private.collection(),
    ...queryConstraints
  );

  const consumerList = useFirestoreQueryData(
    ['consumerList', consumerType],
    consumerQuery,
    {
      subscribe: true,
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                              Filter Functions                              */
  /* -------------------------------------------------------------------------- */

  function getConsumersByEmail(email: string) {
    return consumerList?.data?.filter(
      (consumer) =>
        consumer.email?.includes(email) ||
        consumer.business_name?.toLowerCase()?.includes(email)
    );
  }

  function getConsumersByFirstName(firstName: string) {
    return consumerList?.data?.filter((consumer) => {
      if (consumer.splitname?.first)
        return consumer.splitname?.first.includes(firstName);
      else return consumer.fullname.split(' ')[0].includes(firstName);
    });
  }

  function getConsumersByLastName(lastName: string) {
    return consumerList?.data?.filter((consumer) => {
      if (consumer.splitname?.last)
        return consumer.splitname?.last.includes(lastName);
      else {
        // Split by space and combine all but the first name
        const splitName = consumer.fullname.split(' ');
        splitName.shift();
        return splitName.join(' ').includes(lastName);
      }
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                   Paginated query (temporarily disabled)                   */
  /* -------------------------------------------------------------------------- */
  // // Create the initial query
  // const consumerQuery = query(
  //   DR_Consumer_Private.collection(),
  //   limit(pageSize),
  //   orderBy('tupdate')
  // );

  // const consumerList = useFirestoreInfiniteQuery(
  //   `consumerList-size=${pageSize}`,
  //   consumerQuery,
  //   (snapshot) => {
  //     const lastDocument = snapshot.docs[snapshot.docs.length - 1];
  //     // Get the documents starting after the last document fetched.
  //     if (lastDocument)
  //       return query(
  //         consumerQuery,
  //         orderBy('tupdate'),
  //         startAfter(lastDocument)
  //       );
  //   }
  // );

  return {
    consumerList,
    getConsumersByEmail,
    getConsumersByFirstName,
    getConsumersByLastName,
  };
}
