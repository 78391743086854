import { ZodError, boolean } from 'zod';
import {
  Z_DTOptionsSingleOption,
  DT_AnyValue,
  DT_AnyValueMultiDimensional,
  DeciderTableValues,
  DeciderSchedule,
  DeciderTypeWithLayout,
  DeciderWorkspace,
  DeciderMenuResult,
  DeciderMenuArray,
  DTOptions,
  DeciderType,
  DTOptionsSingleOption,
  SingleApprovedOptionInfoPairs,
  SingleApprovedOptionInfo,
  DeciderApprovedOptionInfo,
} from '@rabbit/data/types';

export function GetFinalValue(
  value: DT_AnyValue,
  workspace: DeciderWorkspace
): DT_AnyValue {
  if (typeof value === 'string') {
    const result = workspace.variables[value];
    if (result !== undefined) {
      if (result === null) {
        if (value.charAt(0) === 's') {
          // Give stipulation-specific error message
          throw new Error(
            `Required stipulation is not provided or calculated [${
              value.split('.')[1]
            }]`
          );
        }
        throw new Error(`Variable ${value} has no value yet`);
      }
      return result;
    }
  }
  return value;
}

export function GetFinalValueNumber(
  value: DT_AnyValue,
  workspace: DeciderWorkspace
): number {
  const result = GetFinalValue(value, workspace);
  if (typeof result !== 'number') {
    console.log(value, workspace);
    throw new Error(`Value is not a number => ` + value);
  }
  return result;
}

export function GetTypingFromPath(
  path: string,
  schedule: DeciderSchedule
): DeciderTypeWithLayout {
  const parts = path.split('.');
  if (parts.length !== 2) throw new Error('Tragedy');

  if (parts[0] === 'd') {
    const decision = schedule.decisions[parts[1]];
    if (!decision) throw new Error(`Decision ${parts[1]} does not exist`);
    return decision;
  }
  if (parts[0] === 's') {
    const stipulation = schedule.stipulations[parts[1]];
    if (!stipulation) throw new Error(`Stipulation ${parts[1]} does not exist`);
    return stipulation;
  }
  throw new Error('Not a valid path to a typing: ' + path);
}

export function GetOptionIndexFromLabel(
  label: string,
  typing: DTOptions
): number {
  const option = typing.options.find((o) => o.label === label);
  if (!option) throw new Error('Option not found');
  return typing.options.indexOf(option);
}

export function GetOptionIndexFromValue(
  value: string | number,
  typing: DTOptions
): number {
  const option = typing.options.find((o) => o.value === value);
  if (!option) throw new Error('Option not found');
  return typing.options.indexOf(option);
}

export function GetOptionIndexFromSingleOption(
  value: DTOptionsSingleOption,
  typing: DTOptions
): number {
  const option = typing.options.find((o) => o.label === value.label);
  if (!option) throw new Error('Option not found');
  if (option.value !== value.value)
    throw new Error('Option value does not match');
  return typing.options.indexOf(option);
}

export function GetOptionIndexFromVariable(
  variableName: string,
  workspace: DeciderWorkspace
): number {
  const typing = GetTypingFromPath(variableName, workspace.input.schedule);
  const value = GetFinalValue(variableName, workspace);
  if (typing.type !== 'options')
    throw new Error('Only "options" type is supported');
  const parsed = ParseToSingleOption(value);
  if (typeof parsed === 'string') {
    console.log(parsed);
    throw new Error('GetOptionIndexFromVariable: ' + parsed);
  }
  return GetOptionIndexFromSingleOption(parsed, typing);
}

export function GetDimensionDetailFromTyping(typing: DeciderTypeWithLayout) {
  // Make sure our dimension is of type "options"
  //console.log(typing);
  if (typing.type !== 'options') {
    throw new Error('Only "options" type is supported');
  }
  const dimDetail = {
    label: typing.label,
    indices: typing.options,
  };
  return dimDetail;
}

export function ExtractDecisionFromDeciderMenuResult(
  result: DeciderMenuResult,
  decisionName: string
): DeciderTableValues {
  function extractSingles(
    input: DeciderMenuArray
  ): DT_AnyValueMultiDimensional {
    if (Array.isArray(input)) {
      return input.map((i) => extractSingles(i));
    }
    return input[decisionName];
  }

  const typing = result.typing[decisionName];
  if (!typing) throw new Error('Decision not found');

  const dimensionLengths: number[] = [];
  for (const dim of result.dimensionDetail) {
    dimensionLengths.push(dim.indices.length);
  }

  const values = extractSingles(result.values);

  const output = {
    key: decisionName,
    typing,
    dimensionDetail: result.dimensionDetail,
    dimensionLengths,
    values,
  };

  //console.log(output);

  return output;
}

/** Creates a list of approved options and their pairs from a decider menu result
 * It's a bit too hacky for my taste right now. Needs to be made to support multiple dimensions, aka the recursive update
 * But it'll work for two dimensions for now so I won't rush it.
 * I left the approach I'll try afterwards commented within
 */
export function ExtractApprovedOptionsFromDeciderMenuResult(
  result: DeciderMenuResult,
  dimensionLabel: string // the label of the dimension we want to extract
): DeciderApprovedOptionInfo {
  // For all the dimensions, get only the ones which are approved
  // then structure them  into objects the FE can use to create forms

  // First we extract the approval decision
  const approvalsMenu = ExtractDecisionFromDeciderMenuResult(
    result,
    'approval'
  );

  const { dimensionDetail, dimensionLengths, values } = approvalsMenu;

  if (!Array.isArray(values)) {
    throw new Error('Values is not an array, single values not supported yet');
  }

  const approvedOptions: DeciderApprovedOptionInfo = {
    label: dimensionLabel,
  } as DeciderApprovedOptionInfo;

  const chosenDimensionPosition = dimensionDetail.findIndex(
    (dim) => dim.label === dimensionLabel
  );

  // Now we need to get all the approved options for the chosen dimension, i.e. those with at least one approved value

  const optionInfo: SingleApprovedOptionInfo[] =
    [] as SingleApprovedOptionInfo[];

  // For each option in the chosen dimension, generate the necessary info
  for (
    let chosenOptIndex = 0;
    chosenOptIndex < dimensionLengths[chosenDimensionPosition];
    chosenOptIndex++
  ) {
    const currentOptionInfo: SingleApprovedOptionInfo = {
      option: dimensionDetail[chosenDimensionPosition].indices[chosenOptIndex],
      availablePairs: [] as SingleApprovedOptionInfoPairs[],
    };

    // Find the approved pairs for this option
    const availablePair: SingleApprovedOptionInfoPairs = {
      label: '',
      indices: [],
    } as SingleApprovedOptionInfoPairs;

    dimensionLengths.forEach((dimLength, dimIndex) => {
      if (dimIndex === chosenDimensionPosition) {
        // We are only interested in the chosen dimension, ignore this
        return;
      }

      // For each option in the current dimension, see if the chosen option paired with it is approved

      // LAWL WHAT A HACK JOB
      if (chosenDimensionPosition === 0) {
        for (let currOptIndex = 0; currOptIndex < dimLength; currOptIndex++) {
          if (Array.isArray(values[chosenOptIndex])) {
            if ((values as any)[chosenOptIndex]?.[currOptIndex] === true) {
              availablePair.label = dimensionDetail[dimIndex].label;
              availablePair.indices.push(
                dimensionDetail[dimIndex].indices[currOptIndex]
              );
            }
          }
        }
      } else {
        for (let currOptIndex = 0; currOptIndex < dimLength; currOptIndex++) {
          if (Array.isArray(values[currOptIndex])) {
            if ((values as any)[currOptIndex]?.[chosenOptIndex] === true) {
              availablePair.label = dimensionDetail[dimIndex].label;
              availablePair.indices.push(
                dimensionDetail[dimIndex].indices[currOptIndex]
              );
            }
          }
        }
      }

      // only push it if there's at least one approved pair for this option
      if (availablePair.label)
        currentOptionInfo.availablePairs.push(availablePair);
    });

    if (currentOptionInfo.availablePairs.length > 0)
      optionInfo.push(currentOptionInfo);
  }

  approvedOptions.optionInfo = optionInfo;

  /* ----------------------------- Future version ----------------------------- */
  // TODO: Turn the below into a recursive function so it works with any number of dimensions
  // My implementation notes for the future:
  // It returns a multi d array of approved option combos and their indexes like [[D1, D2, D3]] e.g [[0, 1, 2], [0, 2, 1], [1, 0, 2] ...]
  // For the chosen dimension, loop from i = 0 to the dimension length.
  // In each loop go through the entire array above and get the connected options
  // so, check if in each innerArray[chosenDimensionIndex] === i then fetch the connected options for the other dimensions from dimensionDetail
  //  stash them as SingleApprovedOptionInfoPairs, push those into a SingleApprovedOptionInfo object, then push that into DeciderApprovedOptionInfo
  // and return it. Simple right? I'm sure it'll be a breeze to implement

  // //Get the approved indexes (will need to make this recursive later)
  // if (Array.isArray(values)) {
  //   const approvedIndexes: any = [];
  //   values.forEach((value, index) => {
  //     const currentIndex = index;
  //     if (Array.isArray(value)) {
  //       value.forEach((value, index) => {
  //         if (value === true) {
  //           approvedIndexes.push([currentIndex, index]);
  //         }
  //       });
  //     } else {
  //       if (value === true) {
  //         approvedIndexes.push([currentIndex]);
  //       }
  //     }
  //   });
  //   console.log(JSON.stringify(approvedIndexes), 'approvedIndexes');

  return approvedOptions;
}

export function CheckValueAgainstTyping(
  value: DT_AnyValue,
  typing: DeciderType
): string {
  switch (typing.type) {
    // separated the currency and number case bcz added boolean check in currency
    case 'number':
      if (typeof value !== 'number') return `Value (${value}) is not a number`;
      if (typing.min !== undefined && typing.max !== undefined) {
        if (value < typing.min)
          return `Value (${value}) is less than min (${typing.min})`;
        if (value > typing.max)
          return `Value (${value}) is greater than max (${typing.max})`;
      }
      return 'OK';
    case 'currency':
      if (typeof value === 'boolean') return 'OK'; // Return 'OK' if it's a boolean
      if (typeof value !== 'number') return 'Value is not a number or boolean';
      if (typing.min !== undefined && typing.max !== undefined) {
        if (value < typing.min)
          return `Value (${value}) is less than min (${typing.min})`;
        if (value > typing.max)
          return `Value (${value}) is greater than max (${typing.max})`;
      }
      return 'OK';
    case 'options': {
      const parsed = ParseToSingleOption(value);
      if (typeof parsed === 'string') return parsed;
      try {
        GetOptionIndexFromSingleOption(parsed, typing);
      } catch (e) {
        return `not a valid option`;
      }
      return 'OK';
    }
    case 'boolean':
      if (typeof value !== 'boolean') return 'Value is not a boolean';
      return 'OK';
    default:
      return `Unknown type: ${(typing as any).type}`;
  }
}

function ParseToSingleOption(
  what: DT_AnyValue
): string | DTOptionsSingleOption {
  try {
    const parsed = Z_DTOptionsSingleOption.parse(what);
    return parsed as DTOptionsSingleOption;
  } catch (e) {
    if ((e as any).issues) {
      const ze = e as ZodError;
      return (
        'Not-a-SingleOption - ' +
        ze.issues
          .map(
            (issue) =>
              `ZOD:${issue.code}//${issue.path.join('.')}//${issue.message}` ||
              'ZOD:UnknownZodError'
          )
          .join(' + ')
      );
    }
    return 'Not-a-SingleOption - ZOD:UnknownZodError';
  }
}
