import { calcWarrantyTimeLeft } from '@rabbit/bizproc/react';
import { AppContext } from '@rabbit/app-context';
import {
  CardWrapperWithHeader,
  formatUnixTime,
  getClaimLimit,
  getIncreasedLabourRate,
  getWarrantyTerm,
  LabelledItemList,
  LabelledItemShape,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import { renderWarrantyRemaining } from '@rabbit/sage/utils/helpers';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleInfo } from '@rabbit/data/types';
import { Config_ClaimDetailViewType } from '@rabbit/config/enums';

export default function ClaimWarrantyDetailSection() {
  const context = useContext(CaseflowContext);
  const { config, tenantInfo } = useContext(AppContext);
  const { holdingData } = context || {};
  const { t } = useTranslation();
  const tanantLink = t('tenantLink');
  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';

  const warrantyInfo = holdingData?.warranties?.[0];
  const remaining: { amount: number | null; unit: string } | undefined =
    warrantyInfo?.endDate && warrantyInfo?.startDate
      ? calcWarrantyTimeLeft(warrantyInfo?.endDate, warrantyInfo?.startDate)
      : undefined;
  // const showVAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_VAT', {
  //   defaultValue: false,
  // }) as unknown as boolean;
  const labourRateValue = getIncreasedLabourRate(
    warrantyInfo,
    tenantInfo?.currency ?? 'GBP'
  );

  const record: LabelledItemShape[] = [
    {
      label: t('general.warrantyId'),
      // label: t('general.warrantyId'),
      value: warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase() || '-',
    },
    {
      label: t('general.warrantyPlan'),
      value: warrantyInfo?.templateTitle || '-',
    },
    {
      label: t('general.registrationDate'),
      value:
        holdingData?.register_time || holdingData?.purchase_time
          ? formatUnixTime(
              holdingData?.register_time || holdingData?.purchase_time,
              'dd/MM/yyyy'
            )
          : '-',
    },
    {
      label: t('general.warrantyTerm'),
      value: holdingData ? `${getWarrantyTerm(holdingData)}` : '-',
    },
    {
      label: t('general.startDate'),
      value: warrantyInfo?.startDate
        ? formatUnixTime(warrantyInfo?.startDate, 'dd/MM/yyyy')
        : '-',
    },
    {
      label: t('general.expiryDate'),
      value: warrantyInfo?.endDate
        ? formatUnixTime(warrantyInfo?.endDate, 'dd/MM/yyyy')
        : '-',
    },
    {
      label: t('general.warrantyRemaining'),
      value: remaining
        ? renderWarrantyRemaining(holdingData as any, t, false)
        : '-',
    },
    {
      label: t('general.warrantyExpired'),
      value: warrantyInfo?.endDate
        ? warrantyInfo?.endDate < Date.now()
          ? 'Yes'
          : 'No'
        : '-',
    },
    {
      label: t('general.extendedWarranty'),
      value: warrantyInfo?.price
        ? !!warrantyInfo?.price?.amount
          ? 'Yes'
          : 'No'
        : '-',
    },
  ];

  // TODO: Implement car tenants fields
  if (tanantLink === 'PINNACLEWARRANTIES') {
    record.push(
      ...[
        {
          label: t('general.claimLimit'),
          value: warrantyInfo
            ? getClaimLimit(
                warrantyInfo,
                tenantInfo?.currency,
                CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT
              )
            : '-',
        },
        {
          label: t('general.labourRate'),
          value:
            Array.isArray(labourRateValue) && labourRateValue.length > 0
              ? (getIncreasedLabourRate(
                  warrantyInfo,
                  tenantInfo?.currency ?? 'GBP'
                ) as any)
              : '-',
        },
      ]
    );
  }

  if (
    config.CLAIMS.CLAIM_DETAIL_VIEW.TYPE ===
    Config_ClaimDetailViewType.CAR_DETAILS
  ) {
    const srvProductInfo = holdingData?.srvInfo?.productInfo as VehicleInfo;

    record.push({
      label: t('general.partners'),
      value: warrantyInfo?.retailerName || '-',
    });

    // Insert RegistrationNo after the 'ID', given its importance
    record.splice(1, 0, {
      label: t('general.registrationNo'),
      value: srvProductInfo?.registrationNo,
    });
  }

  return (
    <CardWrapperWithHeader title={t('general.warrantyDetails')}>
      <LabelledItemList
        items={record}
        listWrapperClasses="font-nunito grid grid-cols-3 gap-y-6 gap-x-4"
      />
    </CardWrapperWithHeader>
  );
}
